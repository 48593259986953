import React, {useState} from 'react';
import { useSelector } from 'react-redux';

import { makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Divider, IconButton, Paper, Grid, Icon, Button } from '@material-ui/core';

import CopyrightTwoToneIcon from '@material-ui/icons/CopyrightTwoTone';
import TelegramIcon from '@material-ui/icons/Telegram';
import TwitterIcon from '@material-ui/icons/Twitter';
import GitHubIcon from '@material-ui/icons/GitHub';
import MediumIcon from '../../../static/images/medium.svg'
import RiskDisclosureDialog from './RiskDisclosureDialog';
import ContactDialog from './ContactDialog';


const useStyles = makeStyles((theme) => ({
  appBar:{
    display:'flex',
    // background: 'none',
    [theme.breakpoints.up('sm')]:{

    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10)
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  appbarPaper:{
    flexGrow: 1,
    background: 'none',
    justifyContent: 'right',
    padding: theme.spacing(3),
    textAlign: 'right'
  },
  copyrightPaper:{
    display: 'flex',
    background: 'none',
    justifyContent: 'center'
  },
  tncPaper:{
    display: 'flex',
    alignItems: 'center',
    background: 'none',
    justifyContent: 'space-evenly'
  }
}))

const Navbar = (props) => {
  const classes = useStyles();
  const footerBackground = useSelector(state => state.theme.footerBackground);
  const [rdDialog, setrdDialog] = useState(false);
  const [ctDialog, setctDialog] = useState(false);
  console.log(process.env.REACT_APP_SOURCE_VERSION);
  return (
    <React.Fragment>
      <CssBaseline />

      <Divider/>

      <AppBar className={classes.appBar} style={{ background: footerBackground}}elevation={0} position='static'>
        <Toolbar />
        <Toolbar>
            <Grid container spacing={2} style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                <Grid item xs={12} md={4} align='center'>
                  <Paper className={classes.copyrightPaper} elevation={0}>
                    <CopyrightTwoToneIcon/>
                    <Typography><b>DestinyWorld</b> all Rights Reserved</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper className={classes.tncPaper} elevation={0}>
                    <Button onClick={() => setrdDialog(true)} style={{textTransform: 'none'}}>
						<Typography>Risk Disclosure</Typography>
					</Button>
		     <Button onClick={() => setctDialog(true)} style={{textTransform: 'none'}}>
						<Typography>Contact</Typography>
					</Button>
					<Button component="a" href="https://everipedia.org/wiki/lang_en/destiny-world" target="_blank" rel="noreferrer" style={{textTransform: 'none'}}>
						<Typography color="textPrimary">Whitepaper</Typography>
					</Button>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} align='center'>
                  {/* <ButtonGroup> */}
                  <a href="https://destinyworld.medium.com/" target="_blank" rel="noreferrer">
                    <IconButton aria-label='Medium'>
                      <Icon style={{textAlign: 'center', color:'#7E8BB6'}}>
                        <img src={MediumIcon} alt='Medium'/>
                      </Icon>
                    </IconButton>
                  </a>
                  <a href="https://t.me/Dstnyworld" target="_blank" rel="noreferrer">
                    <IconButton aria-label='Telegram'><TelegramIcon style={{color: '#7E8BB6'}}/></IconButton>
                  </a>
                  <a href="https://twitter.com/_Destiny_World_" target="_blank" rel="noreferrer">
                    <IconButton aria-label='Twitter'><TwitterIcon style={{color: '#7E8BB6'}}/></IconButton>
                  </a>
                    {/* <IconButton aria-label='LinkedIn'><LinkedInIcon style={{color: '#7E8BB6'}}/></IconButton> */}
                  <a href="https://github.com/destinydevteam" target="_blank" rel="noreferrer">
                    <IconButton aria-label='GitHub'><GitHubIcon style={{color: '#7E8BB6'}}/></IconButton>
                  </a>
                  {/* </ButtonGroup> */}
                </Grid>
                <Grid item xs={12} align="center">
                  <Typography variant="caption">Build: {process.env.REACT_APP_SOURCE_VERSION}</Typography>
                </Grid>
            </Grid>
        </Toolbar>
      </AppBar>
      <RiskDisclosureDialog open={rdDialog} onClose={() => setrdDialog(false)} />
      <ContactDialog open={ctDialog} onClose={() => setctDialog(false)} />
    </React.Fragment>
  );
}

export default Navbar;
