import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AuctionFAQs from '../Auction/faqs';
import MiningFAQs from '../Mine/faqs';
import { Hidden, Container, Divider } from '@material-ui/core';

const useStyles1 = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const FAQPanel = (props) => {
  const {faqs, prefix, background} = props;
  const cardBackground = useSelector(state => state.theme.cardBackground)
  const theme = useTheme();
  const classes = useStyles1(theme);
  return (
    <>
    {
        faqs.map((item, index)=>
        // <Grid item xs={12} sm={6} style={{padding: theme.spacing(2)}} key={index}>
        <>

            <Accordion style={background ? {background: cardBackground} : {background: "none"}} key={`${item.question}+${prefix}+${index}`}>
                <AccordionSummary
                expandIcon={<AddIcon style={{color: "#0059F7"}}/>}
                aria-controls="panel1a-content"
                id="panel1a-header"

                >
                    <Typography className={classes.heading}>{item.question}</Typography>
                </AccordionSummary>
                <AccordionDetails align='left'>
                    <Typography color='textSecondary'>
                        {item.answer}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
        // {/* </Grid> */}
        )
    }

    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



export default function FAQView() {

	const type = useSelector(state => state.theme.type);
	const cardBackground = useSelector(state => state.theme.cardBackground)
	const theme = useTheme();
	const useStyles2 = makeStyles((theme) => ({
		root: {
			flexGrow: 1,
			// backgroundColor: 'none',
			borderStyle: 'solid',
			borderColor: [type === 'dark' ? 'rgba(255, 255, 255, 0.12)' : '#000000'],
			borderRadius: theme.shape.borderRadius,
			marginDown: theme.spacing(2),
			padding: theme.spacing(1)
		},
		}));
	const classes = useStyles2();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
	setValue(newValue);
	};

	return (
		<Container className={classes.root}>
		{/* <AppBar position="static"> */}
			<Box align='center'>
				<Hidden smDown>
					<Typography variant='h3'> FAQs</Typography>
				</Hidden>
				<Hidden mdUp>
					<Typography variant='h5'> FAQs</Typography>
				</Hidden>
				<Divider/>
			</Box>
			<Tabs value={value} onChange={handleChange} aria-label="auctions and mining faq" indicatorColor='primary'>
			<Tab label="Auction" {...a11yProps(0)} />
			<Tab label="Mining" {...a11yProps(1)} />
			</Tabs>
		{/* </AppBar> */}
			<Container align='left' style={{padding: theme.spacing(1), background: cardBackground, borderRadius: '10px 60px', marginTop: theme.spacing(2)}}>
				<TabPanel value={value} index={0}>
					<FAQPanel faqs={AuctionFAQs.faqs} prefix="A" background={false}/>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<FAQPanel faqs={MiningFAQs.faqs} prefix="M" background={false}/>
				</TabPanel>
			</Container>
		</Container>

  );
}
