import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { useSelector } from 'react-redux';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


const useStyles2 = makeStyles({
  table: {
    minWidth: 0,
  },
});

const StakeList = (props) => {
    // const theme = useTheme();
    const {stakeList, loading} = props;
    const classes = useStyles2();

    const explorer = useSelector(state => state.chain.explorer);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, stakeList.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
    setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    };

    return (
        <TableContainer component={Paper} style={{background: 'none'}} elevation={0}>
            <Table className={classes.table} aria-label="custom pagination table">
                <TableHead style={{background: 'rgba(47, 122, 255, 0.1)'}}>
                    <TableRow>
                        <TableCell><Typography variant='body1'><b>Time</b></Typography></TableCell>

                        <TableCell><Typography variant='body1'><b>ID</b></Typography></TableCell>

                        <TableCell><Typography variant='body1'><b>Staker Name</b></Typography></TableCell>

                        <TableCell><Typography variant='body1'><b>Mined Token</b></Typography></TableCell>

                        <TableCell><Typography variant='body1' align='center'><b>Stake</b></Typography></TableCell>

                        <TableCell><Typography variant='body1' align='right'><b>Days Passed</b></Typography></TableCell>

                    </TableRow>
                </TableHead>
                {
                    stakeList.length === 0 && loading &&
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={6} align='center'>
                                <CircularProgress/>
                            </TableCell>
                        </TableRow>
                    </TableBody>

                }
                {
                    stakeList.length === 0 && !loading &&
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={6} align='center'>
                                <Typography>No Stakes placed yet! </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>

                }
                {
                    stakeList.length > 0 &&
                    <TableBody>

                        {(rowsPerPage > 0
                        ? stakeList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : stakeList
                        ).map((row, index) => (
                        <TableRow key={index}>

                            <TableCell component="th" scope="row">
                            <Typography variant='body2'>{row.stake_time.replace("T", " ")}</Typography>
                            </TableCell>

                            <TableCell><Typography variant='body2' color='textPrimary'>{row.stake_id}</Typography></TableCell>

                            <TableCell>
                            <Typography variant='body2'>{row.staker_name}</Typography>
                            </TableCell>

                            <TableCell><Typography variant='body2' color='textPrimary'>{row.mined_token_symbol.slice(2)}</Typography></TableCell>

                            <TableCell align='center'><Typography variant='body2' color='textPrimary'>{parseFloat(row.stake)} {row.stake.split(" ")[1]}</Typography>
                            </TableCell>

                            <TableCell align='right'>
                            <Typography variant='body2'>{row.days_passed}</Typography>
                            </TableCell>

                        </TableRow>
                        ))
                        }

                        {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                        )}
                    </TableBody>
                }


                <TableFooter>
                    <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={6}
                        count={stakeList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}
export default StakeList;
