/* global BigInt */

import React, {useState, useEffect} from 'react';
import {
    Card,
    CardContent,
    makeStyles,
    Typography,
    Grid,
    Box,
    TextField,
    useTheme,
    Button,
    ThemeProvider,
    createMuiTheme,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Paper,
    Container,
    Avatar,
    List,
    ListItem,
    Divider,
    CircularProgress,
    Hidden,
    ButtonBase
 } from '@material-ui/core';
import { Redirect, withRouter } from 'react-router-dom';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';
import { useSelector } from 'react-redux';

import mine_card_img from '../../../static/images/mine_card_img.png';
import mask_group from '../../../static/images/mask_group.svg';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { blue } from '@material-ui/core/colors';
import WithdrawDialog from './WithdrawDialog';
import MessageDialog from '../../ui/dialog/MessageDialog';
import { createInitialTypes, SerialBuffer } from 'eosjs/dist/eosjs-serialize';
import Long from 'long';

const { JsonRpc } = require("eosjs");

const useStyles = makeStyles((theme) => ({
    mine: {
        minHeight: '100vh',
        justifyContent:'center',
        alignItems: 'center',
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
        [theme.breakpoints.up('sm')]:{
            paddingLeft: theme.spacing(15),
            paddingRight: theme.spacing(15)
        },
        [theme.breakpoints.down('xs')]:{
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5)
        },
        textAlign: 'center'
    },
    card:{
        // width: '480px',
        height: '400px',
        background: "none",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    box: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: `${theme.spacing(2)}px ${theme.spacing(4)}px`
    }

}))

const MineStake = (props) => {
    const [withdrawDialog, setWithdrawDialog] = useState(false);
    const [withdrawLabel, setWithdrawLabel] = useState('');

    const [messageDialog, setMessageDialog] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(0); //0 -> sucess, 1 -> failure, 2 -> Information

    const [acceptedTokens, setAcceptedTokens] = useState([]);
    const [stakesTable, setStakesTable] = useState([]);
    const [boostAmt, setBoostAmt] = useState(0);
    const [baseTokenStakedAmt, setBaseTokenStakedAmt] = useState(0);
    const [stakeAmt, setstakeAmt] = useState(0);
    const [minedAmt, setMinedAmt] = useState(0);
    const [stakeCoin, setStakeCoin] = useState({});

    const miningContract = useSelector(state => state.chain.miningContract);
    const cardBackground = useSelector(state => state.theme.cardBackground);
    const type = useSelector(state => state.theme.type);

    const theme = useTheme();

    const classes = useStyles();
    const cardButtonTheme = createMuiTheme({
        palette: {
            type: type,
            primary: blue,
        },
    });

    const {host, port, protocol} = useSelector(state => state.chain);
    const rpc = new JsonRpc(
        `${protocol}://${host}:${port}`
    );


    useEffect(() => {
        async function fetchData(){
            if(props.ual.activeUser && props.location.state && props.location.state.mineData){
                const {ual: {activeUser}, location: {state: { mineData: {mine_id, stake_tkn}}}} = props;
                
                //Convert EOSIO name to i64
                function convertName(name){
                      const builtinTypes = createInitialTypes()
                      const typeUint64 = builtinTypes.get("uint64")
                      const typeName = builtinTypes.get("name")
                      var buffer = new SerialBuffer({ textDecoder: new TextDecoder(), textEncoder: new TextEncoder() });

                      typeName.serialize(buffer, name)
                      return typeUint64.deserialize(buffer)
                    }

                    //console.log("convertName: ", convertName(activeUser.accountName));
                
                //Define Little Endian Hexadecimal Representation for i128 composite key
                const szNamei64 = convertName(activeUser.accountName);
                const searchBound = "0x" + BigInt(szNamei64).toString(16) + "0000000000000000";
                
                console.log("searchBound: ", searchBound);

                var staketbl = await rpc.get_table_rows({
                    json: "true",
                    code: miningContract,
                    scope: miningContract,
                    table: "stakes",
                    index_position: "2",
                    key_type: "i128",
                    lower_bound: searchBound,
                    upper_bound: searchBound
                });
                
                //console.log("Active User: ", activeUser.accountName);
                //console.log("Mine ID: ", mine_id);
                
                if(staketbl.rows.length > 0) {
                    //stakes = stakes.rows.filter( stake => stake.mine_id === mine_id);
                    console.log("Stakes: ", staketbl);
                    setBaseTokenStakedAmt(parseFloat(staketbl.rows[0].stakes.split(" ")[0]));
                    console.log("baseTokenStakedAmt after Setter: ", baseTokenStakedAmt);
                    setStakesTable(staketbl);
                }
                /*
                stakes = stakes.rows.filter( stake => stake.mine_id === mine_id && activeUser.accountName === stake.account);
                console.log("STAKES", stakes);

                setStakesTable(stakes);
                if(stakes.filter(stake => stake.stakes.split(" ")[1] === stake_tkn.split(',')[1])[0]){
                    setBaseTokenStakedAmt(parseFloat(stakes.filter(stake => stake.stakes.split(" ")[1] === stake_tkn.split(',')[1]).map(({stakes}) => ({stakes})).reduce((prevStake, currentStake) => prevStake + currentStake).stakes));
                }
                */
                
                var stats = await rpc.get_table_rows({
                    json: "true",
                    code: miningContract,
                    scope: miningContract,
                    table: "stats",
                    reverse: false
                });

                
                console.log("stats table: ", stats);
                if(stats){
                    // console.log("Wallet Table", wallets);
                    setMinedAmt(parseFloat(stats.rows[0].mined.split(" ")[0]));
                    console.log("Total Mined", setMinedAmt);
                }

                /*
                stakes = stakes.rows.filter( stake => stake.stakes === mined_token_symbol && activeUser.accountName === stake.account);
                console.log("STAKES", stakes);

                setStakesTable(stakes);
                if(stakes.filter(stake => stake.stakes.split(" ")[1] === base_token_symbol.split(',')[1])[0]){
                    setBaseTokenStakedAmt(parseFloat(stakes.filter(stake => stake.stakes.split(" ")[1] === base_token_symbol.split(',')[1])[0].stake.split(" ")[0]));
                }
                
                if(stakes){
                    setBaseTokenStakedAmt(parseFloat(stakes.rows[0].split(" ")[1]));

                }
                
                var wallets = await rpc.get_table_rows({
                    json: "true",
                    code: miningContract,
                    scope: miningContract,
                    table: "wallets",
                    reverse: false
                });
                wallets = wallets.rows.filter( wallet => wallet.username === activeUser.accountName && wallet.balance.split(" ")[1] === mine_tkn.split(",")[1])[0];

                // console.log("Stakes Table", stakes);
                if(wallets){
                    // console.log("Wallet Table", wallets);
                    setMinedAmt(parseFloat(wallets.balance.split(" ")[0]));
                } */
            }
        }
        fetchData();
    }, [props, messageDialog])

    const {history, ual: {activeUser}, location: {state}} = props;
    if( !state || !state.mineData){
        return <Redirect to='/'/>
    }
    const {mineData} = state;
    const {stake_tkn, stake_token_icon_url, mine_tkn, mine_id, burn_rte, mine_owner, mine_cycles} = mineData;
    //console.log("Mine DATA", mineData);

    const handleDialogOpen = (token_symbol) => {
        setWithdrawLabel(token_symbol);
        setWithdrawDialog(true);
    }

    const handleDialogClose = () => {
        setWithdrawDialog(false)
    }

    // const handleBoost = async (e) => {
    //     e.preventDefault();
    //     console.log("BOOST");
    //     console.log("Amount", boostAmt);
    //     console.log("Selected Coin", stakeCoin);

    //     const contractAccount = miningContract;
    //     const name = 'stakeinmine';

    //     let { requestPermission, accountName } = activeUser
    //     if (!requestPermission && activeUser.scatter) {
    //       requestPermission = activeUser.scatter.identity.accounts[0].authority
    //     }

    //     const data = {
    //         username: accountName,
    //         mine_tkn,
    //         stake_asset: `${parseFloat(boostAmt).toFixed(stakeCoin.token_symbol.split(",")[0])} ${stakeCoin.token_symbol.split(",")[1]}`
    //     }

    //     const actions = [
    //         {
    //             account: contractAccount,
    //             name,
    //             authorization: [{actor: accountName, permission: requestPermission}],
    //             data
    //         }
    //     ];

    //     try{
    //         const result = await activeUser.signTransaction({actions}, {expiresSeconds: 120, blocksBehind: 3})
    //         // console.log("Transaction Result", result);
    //         if(result.status === 'executed'){
    //             setMessage('Your Boost has been placed for the Mine!')
    //             setMessageType(0);
    //             setMessageDialog(true);
    //         } else {
    //             setMessage('Something unexpected happened!');
    //             setMessageType(2);
    //             setMessageDialog(true)
    //         }

    //     } catch(e) {
    //         console.log("Error Occured", e);
    //         setMessage(e.message);
    //         setMessageType(1);
    //         setMessageDialog(true);
    //     }
    // }

    const handleStake = async (e) => {
        e.preventDefault();
        // console.log("Stake Amount", `${parseFloat(stakeAmt).toFixed(stake_tkn.split(",")[0])} ${stake_tkn.split(",")[1]}`);
        const contractAccount = miningContract;
        const name = 'stake';

        let { requestPermission, accountName } = activeUser
        if (!requestPermission && activeUser.scatter) {
          requestPermission = activeUser.scatter.identity.accounts[0].authority
        }

        const data = {
            account: accountName,
            mine_id,
            quantity: `${parseFloat(stakeAmt).toFixed(stake_tkn.split(",")[0])} ${stake_tkn.split(",")[1]}`
        }

        const actions = [
            {
                account: contractAccount,
                name,
                authorization: [{actor: accountName, permission: requestPermission}],
                data
            }
        ];
        try{
            const result = await activeUser.signTransaction({actions}, {expiresSeconds: 120, blocksBehind: 3})
            // console.log("Transaction Result", result);
            if(result.status === 'executed'){
                setMessage('Your Stake has been placed for the Mine!')
                setMessageType(0);
                setMessageDialog(true);
            } else {
                setMessage('Something unexpected happened!');
                setMessageType(2);
                setMessageDialog(true)
            }

        } catch(e) {
            console.log("Error Occured", e);
            setMessage(e.message);
            setMessageType(1);
            setMessageDialog(true);
        }

    }

    return (
        <div className={classes.mine} >
            <Grid container spacing={3} style={{ justifyContent: 'center'}}>
                <Grid item xs={12} align='left'>
                    <Button startIcon={<ArrowBackIosIcon/>} onClick={()=> history.push({pathname: '/mine'})} style={{marginBottom: theme.spacing(2)}}>Back to Mines</Button>
                </Grid>
                <Grid item xs={12} align='center'>
                    <Typography variant='h2'>{mine_tkn.split(",")[1]} Mine</Typography>
                </Grid>
                <Grid container item xs={12} align='center' style={{background: cardBackground, borderRadius: theme.shape.borderRadius}}>
                    <Grid item xs={12} md={6} align="center">
                    <Card className={classes.card} elevation={0}>
                            <CardContent style={{padding: theme.spacing(4)}}>
                                <Box  style={{textAlign:'left'}}>
                                    <Typography variant='h4'><b>Mine Information</b></Typography>
                                </Box>
                                <Box className={classes.box}>
                                    <Typography>Mine Owner:</Typography>
                                    <Typography variant="body2" color="textSecondary">{mine_owner}</Typography>
                                </Box>
                                <Divider />
                                <Box className={classes.box}>
                                    <Typography>Stake Token:</Typography>
                                    <Typography variant="body2" color="textSecondary">{stake_tkn.split(",")[1]}</Typography>
                                </Box>
                                <Divider />
                                <Box className={classes.box}>
                                    <Typography>Mined Token:</Typography>
                                    <Typography variant="body2" color="textSecondary">{mine_tkn.split(",")[1]}</Typography>
                                </Box>
                                <Divider />
                                <Box className={classes.box}>
                                    <Typography>Burn Rate:</Typography>
                                    <Typography variant="body2" color="textSecondary">{(mineData.burn_rte / 10000).toFixed(4)}</Typography>
                                </Box>
                                <Divider />
                                <Box className={classes.box}>
                                    <Typography>Mine Cycles:</Typography>
                                    <Typography variant="body2" color="textSecondary">{mine_cycles}</Typography>
                                </Box>
                                <Divider />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Hidden mdUp>
                        <Grid item xs={12}>
                            <Box style={{marginTop: theme.spacing(5)}}>
                                <ButtonBase>
                                    <img src={mine_card_img} alt='Mine!'/>
                                </ButtonBase>
                            </Box>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={6} align="center">
                        <Card className={classes.card} elevation={0}>
                            <CardContent style={{padding: theme.spacing(4)}}>
                                <Box  style={{textAlign:'left'}}>
                                    <Typography variant='h5'><b>Base Token</b></Typography>
                                </Box>
                                <form onSubmit={handleStake}>
                                    <Box style={{ display: 'flex', alignItems:'center', marginTop: theme.spacing(4), marginBottom: theme.spacing(4)}}>
                                        <TextField label={`Stake Amount (${stake_tkn.split(",")[1]})`} variant='outlined' type='number' fullWidth onChange={(e) => setstakeAmt(e.target.value)}/>
                                        <Avatar src={stake_token_icon_url} style={{margin: theme.spacing(1)}}/>
                                    </Box>
                                    <ThemeProvider theme={cardButtonTheme}>
                                        <Button color='primary' variant='contained' fullWidth style={{padding: theme.spacing(2)}} type="submit">
                                            Stake
                                        </Button>
                                    </ThemeProvider>
                                </form>

                                <Box style={{background: 'rgba(126, 139, 182,0.2)', marginTop:theme.spacing(5), padding: theme.spacing(1), display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '8px'}}>
                                    {/* <Paper> */}
                                        <Typography variant='h5'><b>Staked</b>: {baseTokenStakedAmt} {stake_tkn.split(',')[1]}</Typography>
                                    {/* </Paper> */}
                                    <Button style={{background: 'rgba(126, 139, 182, 0.2)', padding: theme.spacing(2)}} onClick={() => handleDialogOpen(stake_tkn)}><ArrowRightAltIcon/></Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Hidden smDown>
                        <Grid item xs={12}>
                            <Box style={{marginTop: theme.spacing(5)}}>
                                <img src={mine_card_img} alt='Mine!'/>
                            </Box>
                        </Grid>
                    </Hidden>
                </Grid>

                {/* <Grid item xs={12} md={6} align='center'>
                    <Card className={classes.card} style={{background: cardBackground}}>
                        <CardContent style={{padding: theme.spacing(4)}}>
                            <Box  style={{textAlign:'left'}}>
                            <Typography variant='h5'><b>Boost</b></Typography>
                            </Box>
                            <Box style={{marginTop: theme.spacing(4), marginBottom: theme.spacing(1)}}>
                                <InputLabel align='left'><b>Total Boost</b></InputLabel>
                            </Box>

                            <form onSubmit={handleBoost}>
                                <Box style={{display: 'flex', alignItems: 'center', marginTop: theme.spacing(2), marginBottom: theme.spacing(2)}}>
                                    <TextField variant='outlined' label="Boost Amount" type='number' fullWidth onChange={e => setBoostAmt(e.target.value)} required/>
                                    {
                                        acceptedTokens.length>0 &&
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <InputLabel>Select Coin</InputLabel>
                                            <Select
                                            label="Select Coin"
                                            displayEmpty
                                            onChange={e => setStakeCoin(e.target.value)}
                                            defaultValue={acceptedTokens[0]}
                                            >
                                                {
                                                    acceptedTokens.map((token, index) =>
                                                    <MenuItem key={index} value={token}>{token.token_symbol.slice(2)}</MenuItem>
                                                    )
                                                }
                                            <MenuItem value={20}>BCOIN</MenuItem>
                                            <MenuItem value={30}>COIN</MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                    {
                                        acceptedTokens.length === 0 &&
                                        <CircularProgress style={{margin: theme.spacing(1)}}/>
                                    }
                                </Box>
                                <ThemeProvider theme={cardButtonTheme}>
                                    <Button color='primary' variant='contained' type='submit' fullWidth style={{padding: theme.spacing(2), marginBottom: theme.spacing(2)}}>
                                        Boost
                                    </Button>
                                </ThemeProvider>
                            </form>

                            <List style={{maxHeight: '300px', overflowY: 'auto', padding: theme.spacing(2)}}>
                                    {
                                        stakesTable
                                        .filter(stake => stake.stakes.split(" ")[1] !== mineData.stake_tkn.split(",")[1])
                                        .map((stake, index) =>
                                        <>
                                        <ListItem key={index} style={{background: 'rgba(126, 139, 182,0.2)', marginTop:theme.spacing(1), padding: theme.spacing(1), display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '8px'}}>


                                            <Typography variant='h5'><b>Staked</b>: {stake.stakes}</Typography>

                                                    
                                            <Typography variant='h5'><b>Staked</b>: {stakes.stakes}</Typography>

                                            <Button style={{background: 'rgba(126, 139, 182, 0.2)', padding: theme.spacing(2)}} onClick={handleDialogOpen}><ArrowRightAltIcon/></Button>
                                        </ListItem>
                                        </>,
                                        )
                                    }
                                </List>


                        </CardContent>
                    </Card>
                </Grid>  */}

                <Grid item xs={12} style={{background: '#0059F7', backgroundImage: `url(${mask_group})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: 'linear-gradient(180deg, #7AB6FC 0%, #276DF7 100%)', padding: theme.spacing(0), borderRadius: theme.shape.borderRadius, marginTop: theme.spacing(2)}}>
                    <Container style={{ minHeight: '328px', padding: theme.spacing(4)}}>
                        <Box style={{display: 'flex', justifyContent: 'flex-start', marginTop: theme.spacing(5)}}>
                            <Typography variant='h4'>Total Mining</Typography>
                        </Box>

                        <Box style={{display: 'flex', justifyContent: 'flex-start'}}>
                            <Typography variant='h2'><b>{minedAmt} {mine_tkn.split(",")[1]}</b></Typography>
                        </Box>
                    </Container>
                </Grid>
            </Grid>
            <MessageDialog open={messageDialog} onClose={() => setMessageDialog(false)} message={message} messageType={messageType}/>
            <WithdrawDialog open={withdrawDialog} onClose={handleDialogClose} label={withdrawLabel} mineData={mineData} setMessage={(m) => setMessage(m)} setMessageDialog={e => setMessageDialog(e)} setMessageType={t => setMessageType(t)}/>
        </div>
    )
}

export default withRouter( withUAL(MineStake));
