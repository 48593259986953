import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, useTheme } from '@material-ui/core';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import { useSelector } from 'react-redux';

const StakeActionDialog = (props) => {
    const {open, onClose, message} = props;
    const theme = useTheme();
    const cardBackground = useSelector(state => state.theme.cardBackground);

    return (
        <Dialog open={open} onClose={onClose}
        PaperProps={{
            style: {
                background: cardBackground
            }
            
        }}>
            <DialogTitle>Message</DialogTitle>
            <DialogContent >                
                <DialogContentText style={{display: 'flex', alignItems: 'center'}}>
                 <CheckCircleTwoToneIcon style={{color: '#15d44b', margin: theme.spacing(1)}}/>Your stake was {message}!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={() => onClose()}>
                    <Typography color='textPrimary'>
                        Close
                    </Typography>
                </Button>
            </DialogActions>

        </Dialog>
    )
}

export default StakeActionDialog;
