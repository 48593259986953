import React, {useEffect, useState} from 'react';
import { useSelector} from 'react-redux';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';
import {Redirect, withRouter} from 'react-router-dom';

import { createMuiTheme, makeStyles, useTheme} from '@material-ui/core/styles';
import {
    ThemeProvider,
    Avatar,
    Typography,
    Container,
    Box,
    Card,
    CardHeader,
    CardContent,
    Divider,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    CircularProgress
} from '@material-ui/core';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { blue } from '@material-ui/core/colors';

import StakeActionDialog from './StakeActionDialog';


import DetailsPanel from './DetailsPanel';
import MessageDialog from '../../ui/dialog/MessageDialog';
import ConfirmStakeCancelDialog from './ConfirmStakeCancelDialog';

// const fetch = require("node-fetch");
const { JsonRpc } = require("eosjs");

const useStyles = makeStyles((theme) => ({
    aucStake: {
        display:'flex',
        minHeight: '100vh',
        justifyContent:'center',
        // alignItems: 'center',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(5)
    },
    card:{
        [theme.breakpoints.up('sm')]:{
            width: '370px',
        },
        height: '460px',
        background: theme.palette.background.card,
    },
    cardContent:{
        marginTop: theme.spacing(1),
        [theme.breakpoints.up('sm')]:{
            width: '370px',
        },
        height: '380px'
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    yourStakeBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: theme.spacing(2),
    }
}));

const AuctionStake = (props) => {

    const {history, location: {state}} = props;
    const {ual: {activeUser}} = props;

    const cardBackground = useSelector(state => state.theme.cardBackground);
    const theme = useTheme();
    theme.palette.background.card = cardBackground;
    const classes = useStyles(theme);
    const type = useSelector(state => state.theme.type);
    const {host, port, protocol} = useSelector(state => state.chain);
    const rpc = new JsonRpc(
        `${protocol}://${host}:${port}`
    );
    const auctionContract = useSelector(state => state.chain.auctionContract)
    const [stakeToken, setstakeToken] = useState({});
    const [numDays, setnumDays] = useState(1);
    const [amount, setamount] = useState(0);

    // const [stakeHistory, setstakeHistory] = useState([]);
    const [stakes, setstakes] = useState([]);
    const [totalStaked, setTotalStaked] = useState(0);

    //used for controlling the cancel and withdraw stake options
    const [action, setAction] = useState('');
    const [actionDialog, setActionDialog] = useState(false);

    //Passed to DetailsPanel > StakeList for handling cancellation of Stake.
    const [stakeCancelConfirm, setStakeCancelConfirm] = useState(false);
	const [cancelStakeId, setCancelStakeId] = useState(0);

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(0);
    const [messageDialog, setMessageDialog] = useState(false);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData(){
            if(state.auctionData && activeUser){
                console.log("AUCTION DATA:", state.auctionData, state.configData);
                const tokens = await rpc.get_table_rows({
                    json: "true",
                    code: auctionContract,
                    scope: auctionContract,
                    table: "tokens",
                    limit: 1,
                    lower_bound: state.auctionData.pool_asset.split(" ")[1],
                    upper_bound: state.auctionData.pool_asset.split(" ")[1],
                    reverse: false
                });
                setstakeToken(tokens.rows[0]);
                //setting complete stake history to be displayed in details panel
                // const history = await rpc.history_get_actions(activeUser.accountName, 0, 100);

                // const stakeHistory = history.actions
                // .filter(action => action.action_trace.act.name === 'staketoken' && action.action_trace.act.account == auctionContract &&parseInt(action.action_trace.act.data.auction_id) === parseInt(state.auctionData.auction_id))
                // .map(({action_trace, block_num, block_time}) => ({act: action_trace.act, block_num, block_time, trx_id: action_trace.trx_id}))
                // .map(({act, block_num, block_time, trx_id}) => ({block_num, block_time, amt: act.data.amt, num_of_days: act.data.num_of_days, trx_id}))

                // setstakeHistory(stakeHistory);
                // console.log('history', history);

                // console.log('stake history', stakeHistory);

                //setting stake list to show active stakes in details panel

                const stakesTableOptions = {
                    json: "true",
                    code: auctionContract,
                    scope: auctionContract,
                    table: "stakes",
                    limit: 9999,
                    lower_bound: 0,
                    upper_bound: undefined
                };
                var rows = [];
                var lower_bound = 0;
                // fetches upto 20 pagiation iterations
                for(var i=0; i < 20; i += 1){
                    const result = await rpc.get_table_rows({
                        ...stakesTableOptions,
                        lower_bound: lower_bound,
                    });
                    rows = rows.concat(result.rows);
                    if (!result.more || result.rows.length === 0)
                        break;
                    // EOS 2.0 api
                    if (typeof result.next_key !== `undefined`) {
                        lower_bound = result.next_key;
                    }
                    else {
                        lower_bound =
                            Number.parseInt(`${result.rows[result.rows.length - 1]["stake_id"]}`, 10) + 1;
                    }
                }

                rows = rows
                .filter(row => row.auction_id === auctionData.auction_id && row.staker_name === activeUser.accountName);
                console.log("Inside UseEffect", rows);


                setstakes(rows);
                let sum = 0;
                rows.forEach(x => {sum += x.amt})
                sum = sum / 10 ** parseInt(auctionData.token_symbol.split(",")[0]);
                setTotalStaked(sum)
                setLoading(false);
            }
        }
        fetchData()
    }, [state, actionDialog, messageDialog,]);

    const cardButtonTheme = createMuiTheme({
        palette: {
            type: type,
            primary: blue,
        },
    });

    const handleStake = async (e) => {
        e.preventDefault();

        const staker_name = activeUser.accountName;
        const {auction_id} = auctionData;
        const precision = parseInt(stakeToken.token_symbol.split(",")[0]);
        const token_symbol = stakeToken.token_symbol;
        // const precision = 4;
        const amt = amount * 10**precision;
        const num_of_days = parseInt(numDays);

        const {rows} = await rpc.get_table_rows({
            json: "true",
            code: auctionContract,
            scope: auctionContract,
            table: "users",
            limit: 1,
            upper_bound: staker_name,
            lower_bound: staker_name,
            reverse: false
        });

        if( rows.filter(row => row.username === staker_name).length > 0){
            const contractAccount = auctionContract;
            const name = 'staketoken';

            let { requestPermission, accountName } = activeUser
            if (!requestPermission && activeUser.scatter) {
              requestPermission = activeUser.scatter.identity.accounts[0].authority
            }

            const data = {
                staker_name,
                auction_id,
                amt,
                num_of_days,
                token_symbol
            }

            const actions = [
                {
                    account: contractAccount,
                    name,
                    authorization: [{actor: accountName, permission: requestPermission}],
                    data
                }
            ];
            // console.log('Actions', actions);

            try{
                const result = await activeUser.signTransaction({actions}, {expiresSeconds: 120, blocksBehind: 3})
                // console.log("Transaction Result", result);
                if(result.status === "executed"){
                    setMessageType(0);
                    setMessage(`Stake Placed Successfully!`);
                    setMessageDialog(true);
                }
            } catch(e) {
                console.log("Error Occured on Submit-", e.message);
                setMessageType(1);
                setMessage(`Error Occured: ${e.message}`);
                setMessageDialog(true);
            }

        } else {
            setMessageType(2);
            setMessage(`User is not Registered!`);
            setMessageDialog(true);
        }
    };

    if(!state || !props.ual.activeUser){
        return <Redirect to='/auction'/>
    } else {
        var {auctionData, configData} = state;

        return (

            <Container className={classes.aucStake}>

                <Grid container spacing={3} justify='space-evenly'>

                    <Grid item xs={12} align='left'>
                        <Button startIcon={<ArrowBackIosIcon/>} onClick={()=> history.push({pathname: '/auction'})} style={{marginBottom: theme.spacing(2)}}>Back to Auctions</Button>
                    </Grid>

                    <Grid item xs={12} md={5} lg={4} align='center'>

                        <Card className={classes.card}>
                            {
                                stakeToken.token_symbol !== undefined &&
                                <>
                                <CardHeader disableTypography={true}
                                title={<Typography variant='h5' align='left'><b> Your Stake</b></Typography>}/>
                                <Divider/>

                                <CardContent className={classes.cardContent}>
                                    <Box className={classes.yourStakeBox}>
                                        <Typography>Staked Currently:</Typography>
                                        <Typography>
                                            {
                                               totalStaked
                                            }
                                        </Typography>
                                    </Box>

                                    <Divider />

                                    <Box className={classes.yourStakeBox}>
                                        <Typography>Min Stake Amount:</Typography>
                                        <Typography>{(configData.min_stake_amt / 10000).toFixed(0)}</Typography>
                                    </Box>

                                    <Divider />

                                    <Box className={classes.yourStakeBox}>
                                        <Typography>Max Stake Amount:</Typography>
                                        <Typography>{(configData.max_stake_amt / 10000).toFixed(0)}</Typography>
                                    </Box>

                                    <Divider />

                                    <Box className={classes.yourStakeBox}>
                                        <Typography>Min Stake Days:</Typography>
                                        <Typography>{configData.min_stake_days}</Typography>
                                    </Box>

                                    <Divider />

                                    <Box className={classes.yourStakeBox}>
                                        <Typography>Max Stake Days:</Typography>
                                        <Typography>{configData.max_stake_days}</Typography>
                                    </Box>

                                </CardContent>
                                </>
                            }
                            {
                                loading && stakeToken.token_symbol === undefined &&
                                <CardContent className={classes.cardContent}>
                                    <CircularProgress />
                                </CardContent>
                            }
                        </Card>

                    </Grid>

                    <Grid item xs={12} md={5} lg={4} align='center'>

                        <Card className={classes.card}>
                            {
                                stakeToken.token_symbol !== undefined &&
                                <>
                                <CardHeader disableTypography={true}
                                avatar={<Avatar alt="Coin" src={stakeToken.token_icon_url}/>}
                                title={<Typography variant='h5' align='left'><b> Stake {stakeToken.token_symbol.split(",")[1]}</b></Typography>}/>
                                <Divider/>

                                <CardContent className={classes.cardContent}>
                                    <Typography variant='h5'><b>Stake Information</b></Typography>
                                    <Box p={2}>
                                    {/* <Typography>Token Contract Name: <b>{stakeToken.contract_name}</b></Typography> */}
                                    </Box>
                                    <form onSubmit={handleStake}>
                                        <Box margin={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="demo-simple-select-outlined-label">Select Coin</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                defaultValue={stakeToken}
                                                label="Select Coin"
                                                >
                                                    <MenuItem value={stakeToken} key={1}>{stakeToken.token_symbol.split(",")[1]}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box margin={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
                                            <TextField label="Amount" variant="outlined" type="number" required onChange={e=> setamount(e.target.value)}
                                            fullWidth
                                            />
                                            <Avatar src={stakeToken.token_icon_url} style={{margin: theme.spacing(1)}} />
                                        </Box>

                                        <Box margin={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginBottom: theme.spacing(1)}}>
                                            <TextField label="Number of Days" variant="outlined" type="number" required onChange={e => setnumDays(e.target.value)} fullWidth/>
                                        </Box>

                                        <Box margin={4}>
                                            <ThemeProvider theme={cardButtonTheme}>
                                                <Button
                                                variant="contained"
                                                fullWidth
                                                color="primary"
                                                type='submit'
                                                title="Unstaking may have a Penalty and Impermanent Loss"
                                                >
                                                    Stake
                                                </Button>
                                            </ThemeProvider>
                                        </Box>
                                    </form>
                                </CardContent>
                                </>
                            }
                            {
                                loading &&
                                <CardContent className={classes.cardContent}>
                                    <CircularProgress />
                                </CardContent>
                            }
                        </Card>

                    </Grid>

                    <Grid item xs={12} md={5} lg={4} align='center'>
                        <Card className={classes.card}>
                            <CardHeader disableTypography={true}
                            title={<Typography variant='h5' align='left'><b>Auction Information</b></Typography>}/>
                            <Divider/>

                            <CardContent className={classes.cardContent}>
                                <Divider/>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: theme.spacing(1), marginBottom: theme.spacing(1)}}>
                                    <Typography color='textSecondary' variant='body1'>Auction Owner</Typography>
                                    <Typography color='textSecondary' variant='body1'>{configData.auctionowner}</Typography>
                                </Box>
                                <Divider/>
                                {/* <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: theme.spacing(1), marginBottom: theme.spacing(1)}}>
                                    <Typography color='textSecondary' variant='body1'>Pool Amount</Typography>
                                    <Typography color='textSecondary' variant='body1'>{auctionData.pool_amt}</Typography>
                                </Box> */}
                                <Divider/>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: theme.spacing(1), marginBottom: theme.spacing(1)}}>
                                    <Typography color='textSecondary' variant='body1'>Ref. Bonus from Parent</Typography>
                                    <Typography color='textSecondary' variant='body1'> {configData.referral_percentage} %</Typography>
                                </Box>
                                <Divider/>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: theme.spacing(1), marginBottom: theme.spacing(1)}}>
                                    <Typography color='textSecondary' variant='body1'>Ref. Bonus from Children</Typography>
                                    <Typography color='textSecondary' variant='body1'> {configData.having_a_referral_percentage} %</Typography>
                                </Box>
                                <Divider/>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: theme.spacing(1), marginBottom: theme.spacing(1)}}>
                                    <Typography color='textSecondary' variant='body1'><b>Early Withdraw Penalty</b></Typography>
                                    <Typography color='textSecondary' variant='body1'> {configData.early_withdraw_penalty} %</Typography>
                                </Box>
                                <Divider/>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: theme.spacing(1), marginBottom: theme.spacing(1)}}>
                                    <Typography color='textSecondary' variant='body1'>APR</Typography>
                                    <Typography color='textSecondary' variant='body1'> {configData.apy} %</Typography>
                                </Box>
                                <Divider/>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: theme.spacing(1), marginBottom: theme.spacing(1)}}>
                                    <Typography color='textSecondary' variant='body1'>Freeze Level</Typography>
                                    <Typography color='textSecondary' variant='body1'> {configData.freeze_level}</Typography>
                                </Box>
                                <Divider/>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: theme.spacing(1), marginBottom: theme.spacing(1)}}>
                                    <Typography color='textSecondary' variant='body1'>Double Reward Time</Typography>
                                    <Typography color='textSecondary' variant='body1'> {configData.double_reward_time} d</Typography>
                                </Box>
                                <Divider/>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <DetailsPanel stakeToken={stakeToken} auctionData={auctionData} stakes={stakes} stakeListActions={{
                            action,
                            setAction,
                            actionDialog,
                            setActionDialog,
                            setCancelStakeId: (id) => setCancelStakeId(id),
                            setStakeCancelConfirm: (val) => setStakeCancelConfirm(val)
                        }}
                        loading={loading}
                        />
                    </Grid>
                </Grid>

                <StakeActionDialog open={actionDialog} onClose={() => setActionDialog(false)} message={action} />

                <ConfirmStakeCancelDialog open={stakeCancelConfirm} onClose={() => setStakeCancelConfirm(false)} messageDialogOpen={() => setMessageDialog(true)} setMessage={(m) => setMessage(m)} setMessageType={(t) => setMessageType(t)} id={cancelStakeId} ewp={configData.early_withdraw_penalty || 0}/>

                <MessageDialog open={messageDialog} onClose={() => setMessageDialog(false)} message={message} messageType={messageType} />
            </Container>
        )
    }
}

export default withRouter(withUAL(AuctionStake));
