import { configureStore } from '@reduxjs/toolkit'
import loginReducer from './loginSlice';
import loginDialogReducer from './loginDialogSlice';
import themeReducer from './themeSlice';
import bidSlice from './bidSlice';
import chainSlice from './chainSlice';

export default configureStore({
  reducer: {
      login: loginReducer,
      loginDialog: loginDialogReducer,
      theme: themeReducer,
      bid: bidSlice,
      chain: chainSlice
  },
})