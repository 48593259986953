import React from 'react';
import { useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
    Button,
    Box,
    useTheme
} from '@material-ui/core';

import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';

import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';

const ConfirmStakeCancelDialog = (props) => {
	const {open, onClose, id, setMessage, messageDialogOpen, setMessageType, ewp, ual:{ activeUser}} = props;
	const theme = useTheme();
	const cardBackground = useSelector(state => state.theme.cardBackground);
	const auctionContract = useSelector(state => state.chain.auctionContract);

	const cancelStake = async (e) => {
		e.preventDefault();
        console.log("Submitted!");
		const contractAccount = auctionContract;
        const name = 'cancelstake';

        let { requestPermission, accountName } = activeUser
        if (!requestPermission && activeUser.scatter) {
			requestPermission = activeUser.scatter.identity.accounts[0].authority;
        }

        const data = {
            stake_id: id
        }

        const actions = [
            {
                account: contractAccount,
                name,
                authorization: [{actor: accountName, permission: requestPermission}],
                data
            }
        ];
        // console.log("Action cancel", actions);
        try{
            const result = await activeUser.signTransaction({actions}, {expiresSeconds: 120, blocksBehind: 3})
            // console.log(result);
            if(result.status === 'executed'){
                setMessage("Stake Cancelled Successfully!");
                setMessageType(0);
                messageDialogOpen();
            }
        } catch(e) {
            console.log("Error Occured", e);
                setMessage(`Error Occured: ${e.message}`);
                setMessageType(1);
                messageDialogOpen();
        }
        onClose();
	}

	return (
		<Dialog open={open} onClose={onClose}
		PaperProps={{
			style: {
				background: cardBackground
			}

		}}>
			<DialogTitle>Cancel Stake?</DialogTitle>
			<form onSubmit={cancelStake}>
			<DialogContent >
				<Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

					<InfoTwoToneIcon style={{color: '#f54b42', margin: theme.spacing(1)}}/>
					<Typography variant="h6">Are you sure you want to cancel this stake?</Typography>
				</Box>
				<DialogContentText>
					The stake with Stake ID {id} hasn't matured yet. If you cancel it now, <b>Early Withdraw Penalty </b> of {ewp} % will be levied on you! Click on <b>Confirm</b> if you want to cancel the stake.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={onClose}>Cancel</Button>
				<Button variant="contained" type="submit">Confirm</Button>
			</DialogActions>
			</form>
		</Dialog>
	)
}

export default withUAL(ConfirmStakeCancelDialog);