import { createSlice } from '@reduxjs/toolkit';

export const loginDialogSlice = createSlice({
    name: 'loginDialog',
    initialState: {
        show: false,
    },
    reducers: {
        toggleDialog: (state) => {
            state.show = !state.show;
        },
    }
})

export const {toggleDialog} = loginDialogSlice.actions;

export default loginDialogSlice.reducer;