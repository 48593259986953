import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';
import { useSelector } from 'react-redux';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


const useStyles2 = makeStyles({
  table: {
    minWidth: 0,
  },
});


const RecentActivity = (props) => {
    const {history} = props;
    // const theme = useTheme();
    const classes = useStyles2();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, history.length - page * rowsPerPage);
    const explorer = useSelector(state => state.chain.explorer);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer component={Paper} style={{background: 'none'}} elevation={0}>
            <Table className={classes.table} aria-label="custom pagination table">
                <TableHead style={{background: 'rgba(47, 122, 255, 0.1)'}}>
                    <TableRow>
                        <TableCell><Typography variant='body1'><b>Time</b></Typography></TableCell>
                        <TableCell><Typography variant='body1'><b>Block No.</b></Typography></TableCell>
                        <TableCell><Typography variant='body1'><b>Action</b></Typography></TableCell>
                        <TableCell><Typography variant='body1' align='center'><b>Amount</b></Typography></TableCell>
                        <TableCell><Typography variant='body1' align='center'><b>User</b></Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    
                    {(rowsPerPage > 0
                    ? history.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : history
                    ).map((row, index) => (
                    <TableRow key={index}>
                        <TableCell component="th" scope="row">
                        <Typography variant='body2'>{row.block_time.replace("T", "  ")}</Typography>
                        </TableCell>
                        <TableCell>
                        <a href={`${explorer}/transaction/${row.trx_id}`} target='_blank' rel='noreferrer'><Typography variant='body2' color='textPrimary'>{row.block_num}</Typography></a>
                        </TableCell>
                        <TableCell>
                        <Typography variant='body2'>
                            {row.action}
                        </Typography>
                        </TableCell>
                        <TableCell align='center'>                        
                            {
                                (row.action === 'makebid' || row.action === 'staketoken') && 
                                <Typography variant='body2'>{row.data.amt} {row.token_name}</Typography>
                            }
                            {
                                row.action === 'transfer' &&
                                <Typography variant='body2'>{parseFloat(row.data.quantity)} {row.data.quantity.slice(row.data.quantity.indexOf(' '))}</Typography>
                            }
                            {
                                row.action === 'registeruser' &&
                                <Typography variant='body2'>-</Typography>
                            }
                            {
                                row.action !== 'makebid' && row.action !== 'staketoken' && row.action !== 'transfer' && row.action !== 'registeruser' &&
                                <Typography variant='body2'>-</Typography>
                            }
                        
                        </TableCell>
                        <TableCell align='center'>
                        <Typography variant='body2'>{row.user}</Typography>
                        </TableCell>
                    </TableRow>
                    ))}

                    {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                    </TableRow>
                    )}
                </TableBody>

                <TableFooter>
                    <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={6}
                        count={history.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}
export default withUAL(RecentActivity);
