import React, {useState} from 'react';
import { 
    Dialog, 
    DialogTitle,
    DialogContent, 
    DialogActions, 
    Button, 
    TextField, 
    Box, 
    makeStyles, 
    createMuiTheme, 
    ThemeProvider, 
    useTheme, 
    Typography, 
    IconButton, 
    Paper
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { blue } from '@material-ui/core/colors';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';

const useStyles = makeStyles((theme)=>({
    box:{
        margin: theme.spacing(2)
    }
}))


const ChangeConfigDialog = (props) => {
    const {open, onClose, auctionConfig, activeUser, setMessage, setMessageType, toggleMessageDialog} = props;
    const {config_id, apy, min_stake_days, max_stake_days, min_stake_amt, max_stake_amt, max_unwithdrawn_time, percentage_share_to_distribute, double_reward_time, early_withdraw_penalty, referral_percentage, having_a_referral_percentage, freeze_level} = auctionConfig;

    // console.log("Auction Config", auctionConfig);
    const classes = useStyles();
    const cardBackground = useSelector(state => state.theme.cardBackground);
    const type = useSelector(state => state.theme.type);
    const auctionContract = useSelector(state => state.chain.auctionContract);

    const [configId, setconfigId] = useState(config_id);
    const [apyVal, setapyVal] = useState(apy);
    const [minSD, setminSD] = useState(min_stake_days);
    const [maxSD, setmaxSD] = useState(max_stake_days);
    const [minSA, setminSA] = useState(min_stake_amt);
    const [maxSA, setmaxSA] = useState(max_stake_amt);
    const [maxWT, setmaxWT] = useState(max_unwithdrawn_time);
    const [psd, setpsd] = useState(percentage_share_to_distribute);
    const [drt, setdrt] = useState(double_reward_time);
    const [ewp, setewp] = useState(early_withdraw_penalty);
    const [rp, setrp] = useState(referral_percentage);
    const [hrp, sethrp] = useState(having_a_referral_percentage);
    const [fl, setfl] = useState(freeze_level);

    const theme = useTheme()
    
    const cardButtonTheme = createMuiTheme({
        palette: {
            type: type,
            primary: blue,
        },
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const contractAccount = auctionContract;
        const name = 'changeconfig';

        let { requestPermission, accountName } = activeUser
        if (!requestPermission && activeUser.scatter) {
          requestPermission = activeUser.scatter.identity.accounts[0].authority
        }
        
        const data = {
            config_id: configId,
            apy: apyVal,
            min_stake_days: minSD,
            max_stake_days: maxSD,
            min_stake_amt: minSA,
            max_stake_amt: maxSA,
            max_unwithdrawn_time: maxWT,
            percentage_share_to_distribute: psd,
            double_reward_time: drt,
            early_withdraw_penalty: ewp,
            referral_percentage: rp,
            having_a_referral_percentage: hrp,
            freeze_level: fl
        }

        const actions = [
            {
                account: contractAccount,
                name,
                authorization: [{actor: accountName, permission: requestPermission}],
                data
            }
        ];
        console.log(actions);
        try{
            const result = await activeUser.signTransaction({actions}, {expiresSeconds: 120, blocksBehind: 3})
            // console.log(result);
            if(result.status === 'executed') {
                setMessage('Auction Config Changed')
                setMessageType(0);
                toggleMessageDialog();
            } else {
                setMessage('Something unexpected happened!');
                setMessageType(2);
                toggleMessageDialog();
            }
            onClose();
        } catch(e) {
            console.log("Error Occured", e)
            setMessage(e.message)
            setMessageType(1);
            toggleMessageDialog();
            onClose();
        }
    }

    return (
        <Dialog open={open} onClose={onClose} 
        maxWidth="sm"
        fullWidth={true}
        PaperProps={{
            style: {
                background: cardBackground
            }
            
        }}
        
        >
            <DialogTitle style={{padding: theme.spacing(0)}}>
                <Paper style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: 'none'}}>
                <Typography variant='h5' style={{padding: theme.spacing(2)}}>
                    <b>Change Config</b>
                </Typography>
                <IconButton onClick={onClose}>
                    <CloseTwoToneIcon/>
                </IconButton>
                </Paper>
                
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Box className={classes.box}>
                        <TextField label='config_id ( uint64 ) ' type='number' variant='outlined' fullWidth defaultValue={configId} disabled/>
                    </Box>
                    <Box className={classes.box}>
                        <TextField label='apy ( uint64 ) ' type='number' variant='outlined' fullWidth defaultValue={apyVal} onChange={(e) => setapyVal(e.target.value)}/>
                    </Box>
                    <Box className={classes.box}>
                        <TextField label='min_stake_days ( int32 ) ' type='number' variant='outlined' fullWidth defaultValue={minSD} onChange={(e) => setminSD(e.target.value)}/>
                    </Box>
                    <Box className={classes.box}>
                        <TextField label='max_stake_days ( int32 ) ' type='number' variant='outlined' fullWidth defaultValue={maxSD} onChange={(e) => setmaxSD(e.target.value)}/>
                    </Box>
                    <Box className={classes.box}>
                        <TextField label='min_stake_amt ( int32 ) ' type='number' variant='outlined' fullWidth defaultValue={minSA} onChange={(e) => setminSA(e.target.value)}/>
                    </Box>
                    <Box className={classes.box}>
                        <TextField label='max_stake_amt ( int32 ) ' type='number' variant='outlined' fullWidth defaultValue={maxSA} onChange={(e) => setmaxSA(e.target.value)}/>
                    </Box>
                    <Box className={classes.box}>
                        <TextField label='max_unwithdrawn_time ( int32 ) ' type='number' variant='outlined' fullWidth defaultValue={maxWT} onChange={(e) => setmaxWT(e.target.value)}/>
                    </Box>
                    <Box className={classes.box}>
                        <TextField label='percentage_share_to_distribute ( int32 ) ' type='number' variant='outlined' fullWidth defaultValue={psd} onChange={(e) => setpsd(e.target.value)}/>
                    </Box>
                    <Box className={classes.box}>
                        <TextField label='double_reward_time ( int32 ) ' type='number' variant='outlined' fullWidth defaultValue={drt} onChange={(e) => setdrt(e.target.value)}/>
                    </Box>
                    <Box className={classes.box}>
                        <TextField label='early_withdraw_penalty ( int32 ) ' type='number' variant='outlined' fullWidth defaultValue={ewp} onChange={(e) => setewp(e.target.value)}/>
                    </Box>
                    <Box className={classes.box}>
                        <TextField label='referral_percentage ( int32 ) ' type='number' variant='outlined' fullWidth defaultValue={rp} onChange={(e) => setrp(e.target.value)}/>
                    </Box>
                    <Box className={classes.box}>
                        <TextField label='having_a_referral_percentage ( int32 ) ' type='number' variant='outlined' fullWidth defaultValue={hrp} onChange={(e) => sethrp(e.target.value)}/>
                    </Box>
                    <Box className={classes.box}>
                        <TextField label='freeze_level ( int32 ) ' type='number' variant='outlined' fullWidth defaultValue={fl} onChange={(e) => setfl(e.target.value)}/>
                    </Box>                

                </DialogContent>
                <DialogActions style={{padding: theme.spacing(4)}}>
                    <Button onClick={onClose}>Cancel</Button>
                    <ThemeProvider theme={cardButtonTheme}>
                        <Button variant='contained' color='primary' type='submit'>Submit Transaction</Button>
                    </ThemeProvider>
                </DialogActions>
            </form>

        </Dialog>
    )
}

export default ChangeConfigDialog
