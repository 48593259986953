import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';
import { useSelector } from 'react-redux';
import { CircularProgress, Box, Hidden } from '@material-ui/core';
import { green, blue } from '@material-ui/core/colors';


// const { JsonRpc } = require("eosjs");
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
	const classes = useStyles1();
	const theme = useTheme();
	const { count, page, rowsPerPage, onChangePage } = props;

	const handleFirstPageButtonClick = (event) => {
	onChangePage(event, 0);
	};

	const handleBackButtonClick = (event) => {
	onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
	onChangePage(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
	onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
	<div className={classes.root}>
		<IconButton
		onClick={handleFirstPageButtonClick}
		disabled={page === 0}
		aria-label="first page"
		>
			{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
			{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
			onClick={handleNextButtonClick}
			disabled={page >= Math.ceil(count / rowsPerPage) - 1}
			aria-label="next page"
			>
			{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
			onClick={handleLastPageButtonClick}
			disabled={page >= Math.ceil(count / rowsPerPage) - 1}
			aria-label="last page"
			>
			{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
	</div>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onChangePage: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
	table: {
		minWidth: 500,
	},
});



const StakeList = (props) => {
    const {ual:{activeUser}, stakeToken, stakes, stakeListActions, loading} = props;
    const {setAction, setActionDialog, setStakeCancelConfirm, setCancelStakeId} = stakeListActions;

    const theme = useTheme();
    const classes = useStyles2();
    const buttonTheme = createMuiTheme({
        palette: {
            primary: blue,
            secondary: green
        }
    })

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const auctionContract = useSelector(state => state.chain.auctionContract);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, stakes.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleWithdrawStake = async (id) => {
        const contractAccount = auctionContract;
        const name = 'withdraw';

        let { requestPermission, accountName } = activeUser
        if (!requestPermission && activeUser.scatter) {
          requestPermission = activeUser.scatter.identity.accounts[0].authority
        }

        const data = {
            stake_id: id
        }

        const actions = [
            {
                account: contractAccount,
                name,
                authorization: [{actor: accountName, permission: requestPermission}],
                data
            }
        ];
        // console.log("Action Withdraw", actions);
        try{
            const result = await activeUser.signTransaction({actions}, {expiresSeconds: 120, blocksBehind: 3})
            console.log(result);

            setAction('withdrawn');
            setActionDialog(true);
        } catch(e) {
            console.log("Error Occured", e)
        }
    };

    const handleCancelStake = async (id) => {
		setCancelStakeId(id);
		setStakeCancelConfirm(true);
    };

    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            {
            stakes.length > 0 &&

                <TableContainer  style={{background: 'none', padding: theme.spacing(1)}} elevation={0}>
                    <Table className={classes.table} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography variant='body1'><b>Amount</b></Typography></TableCell>
                                <TableCell><Typography variant='body1'><b>Stake Duration</b></Typography></TableCell>
                                <TableCell><Typography variant='body1'><b>Elapsed</b></Typography></TableCell>
                                <TableCell><Typography variant='body1' align='center'><b>Action</b></Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                            ? stakes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : stakes
                            ).map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    <Typography variant='body2'>{row.amt / 10**parseInt(stakeToken.token_symbol.slice(0,1))} {stakeToken.token_symbol.slice(2)}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='body2'>{row.num_of_days}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='body2'>{row.days_passed}</Typography>
                                </TableCell>
                                <TableCell style={{display: 'flex', justifyContent: 'space-evenly'}}>
                                    <ThemeProvider theme={buttonTheme}>
                                        <Button variant='contained' color={row.num_of_days > row.days_passed ? "primary" : "secondary"} onClick={() => handleWithdrawStake(row.stake_id)} style={{...theme.shape}}>
                                            Claim
                                        </Button>
                                    </ThemeProvider>
                                    <Button variant='contained' color="secondary" onClick={() => handleCancelStake(row.stake_id)} disabled={row.num_of_days > row.days_passed ? false : true}>
                                        Cancel
                                    </Button>

                                </TableCell>
                            </TableRow>
                            ))}

                            {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                            )}
                        </TableBody>

                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={6}
                                    count={stakes.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            }
            {
                loading &&
                <CircularProgress />
            }
            {
                stakes.length == 0 && !loading &&
                <Typography>
                    You haven't staked yet!
                </Typography>
            }

        </div>
    );
}
export default withUAL(StakeList);
