import React from "react";
import { useTheme } from "@material-ui/core";

const ProgressBar = (props) => {
    const { bgcolor, completed } = props;
    const theme = useTheme();
  
    const containerStyles = {
      height: 20,
      width: '100%',
      backgroundColor: "#e0e0de",
      borderRadius: 50,
    //   marginBottom: theme.spacing(2),
      borderStyle: 'solid'
    }
  
    const fillerStyles = {
      height: '100%',
      width: `${completed > 5 ? completed : 5}%`,
      backgroundColor: bgcolor,
      borderRadius: 'inherit',
      textAlign: 'right',
      transition: 'width 1s ease-in-out',
    }
  
    const labelStyles = {
      padding: 5,
      color: 'white',
      fontWeight: 'bold'
    }
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
          {/* <span style={labelStyles}>{`${completed}%`}</span> */}
          <span style={labelStyles}/>
        </div>
      </div>
    );
  };
  
  export default ProgressBar;