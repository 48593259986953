import React from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';

import { makeStyles, useTheme, withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Button, Divider, Hidden, IconButton, Paper, List, ButtonGroup, Box } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone';
import MonetizationOnTwoToneIcon from '@material-ui/icons/MonetizationOnTwoTone';
import ComputerTwoToneIcon from '@material-ui/icons/ComputerTwoTone';
// import BallotTwoToneIcon from '@material-ui/icons/BallotTwoTone';
// import LiveHelpTwoToneIcon from '@material-ui/icons/LiveHelpTwoTone';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NightsStayOutlinedIcon from '@material-ui/icons/NightsStayOutlined';
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined';

import deco_icon from '../../../static/images/logo.svg'
// import ChangeChain from './ChangeChain';
import { toggleTheme } from '../../../store/themeSlice';
import RegisterDialog from './RegisterDialog';


const drawerWidth = 240;

const DRAWER_MENU = {
  notLoggedIn: [
    {
      name: 'Home',
      path: '/',
      icon: <HomeTwoToneIcon/>
    },
    {
      name: 'Auction',
      path: '/auction',
      icon: <MonetizationOnTwoToneIcon/>
    },
    {
      name: 'Mining',
      path: '/mine',
      icon: <ComputerTwoToneIcon/>
    },
    // {
    //   name: 'Farming',
    //   path: '/farm',
    //   icon: <BallotTwoToneIcon/>
    // },
    // {
    //   name: 'FAQ',
    //   path: '/faq',
    //   icon: <LiveHelpTwoToneIcon/>
    // },
  ]
}

const useStyles = makeStyles((theme) => ({
  appBar:{
    display:'flex',
    background: 'none',
    [theme.breakpoints.up('sm')]:{

    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10)
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  appbarPaper:{
    display: 'flex',
    flexGrow: 1,
    background: 'none',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(3),
    textAlign: 'right'
  },
  button:{
    // border: 'none',
    // background: '#27358a',
    textTransform: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.drawerColor,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
}))

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF"
  }
})(Typography);

const Navbar = (props) => {
  // console.log("NAVBAR Props", props);
  const dispatch = useDispatch();
  const type = useSelector(state => state.theme.type);
  const loginButton = useSelector(state => state.theme.loginButton);
  const drawerColor = useSelector(state => state.theme.drawerColor)

  const {ual, history} = props;
  const {showModal, activeUser, logout} = ual;

  const theme = useTheme();
  theme.palette.drawerColor = drawerColor;
  const classes = useStyles(theme);

  const [open, setOpen] = React.useState(false);
  const [registerOpen, setRegisterOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleTheme = () => {
    dispatch(toggleTheme());
  }

  const handleRegisterDialogOpen = () => {
    setRegisterOpen(true)
  }
  const handleRegisterDialogClose = () => {
    setRegisterOpen(false)
  }
  return (
    <React.Fragment>
      <CssBaseline />
      {/* <Toolbar /> */}
        <AppBar className={classes.appBar} elevation={0} position='static'>
          <Toolbar>
            <IconButton onClick={()=> history.push({pathname: '/'})}>
              <Hidden xsDown>
                <img src={deco_icon} alt='DECO'/>
              </Hidden>
              <Hidden smUp>
                <img src={deco_icon} alt='DECO' height='25px'/>
              </Hidden>
            </IconButton>
            {
              !activeUser &&
              <>
              <Paper className={classes.appbarPaper} elevation={0}>
                <IconButton onClick={handleTheme}>
                  {
                    (type === 'dark')? <WbSunnyOutlinedIcon/> : <NightsStayOutlinedIcon/>
                  }
                </IconButton>
                {/* <Hidden smDown>
                  <ChangeChain/>
                </Hidden> */}
              </Paper>
              <Button className={classes.button} onClick={showModal} style={{ background: loginButton, marginRight: theme.spacing(2)}}><WhiteTextTypography>Login</WhiteTextTypography></Button>
              </>
            }
            {
              activeUser &&
              <Paper className={classes.appbarPaper} elevation={0}>
              <IconButton onClick={handleTheme}>
                {
                  (type==='dark')? <NightsStayOutlinedIcon/> : <WbSunnyOutlinedIcon/>
                }
              </IconButton>
              <ButtonGroup style={{ marginRight: theme.spacing(2)}}>
                <Button startIcon={<AccountCircleTwoToneIcon style={{color: '#ffffff'}}/>} className={clsx(open && classes.hide, classes.button)}
                style={{background: loginButton}} onClick={()=>{history.push({pathname: '/dashboard'})}}>
                  <Hidden xsDown >
                    <WhiteTextTypography >{activeUser.accountName}</WhiteTextTypography>
                  </Hidden>
                </Button>

                <Button
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  className={clsx(open && classes.hide, classes.button)}
                  style={{background: loginButton}}
                >
                  <MenuIcon style={{color: '#ffffff'}}/>
                </Button>
              </ButtonGroup>
              </Paper>
            }
          </Toolbar>
        </AppBar>
        <Divider/>

        <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Box p={1}>
          <Button onClick={() => {logout(); handleDrawerClose()}} style={{textTransform: 'none'}} startIcon={<ExitToAppIcon/>} variant='outlined' fullWidth>Logout</Button>
        </Box>
        {/* <Box p={1}>
          <Button onClick={handleRegisterDialogOpen} style={{textTransform: 'none'}} startIcon={<ExitToAppIcon/>} variant='outlined' fullWidth>Register</Button>
        </Box> */}

        <Toolbar/>
        <Divider />
        <List>
          {DRAWER_MENU.notLoggedIn.map((menu, index) => (
            <>
            <ListItem button key={`${menu.name}-nav-${index}`} onClick={()=>props.history.push({pathname: menu.path})}>
              <ListItemIcon>{menu.icon}</ListItemIcon>
              <ListItemText primary={menu.name} />
            </ListItem>
            <Divider/>
            </>
          ))}
        </List>
      </Drawer>
      <RegisterDialog open={registerOpen} onClose={handleRegisterDialogClose}/>
    </React.Fragment>
  );
}

export default withRouter(withUAL(Navbar));