import { createSlice } from '@reduxjs/toolkit';

export const bidSlice = createSlice({
    name: 'bid',
    initialState: {
        coin: undefined,
    },
    reducers: {
        selectCoin: (state, {payload:{coin}}) => {
            state.coin = coin;
        },
    }
})

export const {selectCoin} = bidSlice.actions;

export default bidSlice.reducer;