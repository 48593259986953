import React, {useState} from 'react';
// import { useSelector} from 'react-redux';
import PropTypes from 'prop-types';
// import {  makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Divider} from '@material-ui/core';
import AuctionWallet from './AuctionWallet';
import MiningWallet from './MiningWallet';
import { withStyles } from '@material-ui/core/styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
        >
            {value === index && (
                <Box p={3} component="div">
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//         width: '100%',
//         // backgroundColor: theme.palette.background.paper,
//         padding: theme.spacing(2)
//     },
// }));

const CustomTab = withStyles({
  root: {
    backgroundColor: 'rgba(144, 238, 144, 0.1)',
    borderRadius: 10,
    overflow: 'hidden'
  },
  selected: {
    backgroundColor: 'rgba(144, 238, 144, 0.8)',
    borderRadius: 10,
    overflow: 'hidden'
  },
})(Tab);

const WalletsPanel = (props) => {
    const {
        auctionWallet, walletDialogOnOpen, walletDialogOnClose,
        miningWallet
    } = props;
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
    <>
        <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        >
            <CustomTab label="Auction" {...a11yProps(0)} />
            <CustomTab label="Mining" {...a11yProps(1)} />
        </Tabs>

        <Divider/>
        <TabPanel value={value} index={0}>
            <AuctionWallet auctionWallet={auctionWallet} withdrawDialogOpen={walletDialogOnOpen} withdrawDialogClose={walletDialogOnClose}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
            <MiningWallet miningWallet={miningWallet} withdrawDialogOpen={walletDialogOnOpen} withdrawDialogClose={walletDialogOnClose}/>
            {/* <Typography>This space is for Mining Wallet (Under Development).</Typography> */}
        </TabPanel>
    </>
    );
}

export default WalletsPanel;
