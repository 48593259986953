import React, {useEffect, useState} from 'react';
import { useSelector} from 'react-redux';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';
import {Redirect, withRouter} from 'react-router-dom';

import { createMuiTheme, makeStyles, useTheme} from '@material-ui/core/styles';
import {
    ThemeProvider,
    Avatar,
    Typography,
    Container,
    Box,
    Card,
    CardHeader,
    CardContent,
    Divider,
    Grid,
    CircularProgress,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle
} from '@material-ui/core';


import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';

import { blue } from '@material-ui/core/colors';


import {
    Button,
    // LinearProgress,
    MenuItem,
  } from '@material-ui/core';

import DetailsPanel from './DetailsPanel';

import Countdown from "react-countdown";
import { Chart } from "react-google-charts";

// const fetch = require("node-fetch");
const { JsonRpc } = require("eosjs");

const useStyles = makeStyles((theme) => ({
    bid: {
        display:'flex',
        minHeight: '100vh',
        justifyContent:'center',
        // alignItems: 'center',
        marginTop: theme.spacing(10)
    },
    card:{
        width: '370px',
        height: '442px',
    },
    cardContent:{
        // marginTop: theme.spacing(1),
        width: '370px',
        height: '330px'
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));

const TransactionSuccessDialog = (props) => {
    const {open, onClose} = props;
    const cardBackground = useSelector(state => state.theme.cardBackground);

    return (
        <Dialog open={open} onClose={onClose}
        PaperProps={{
            style: {
                background: cardBackground
            }

        }}>
            <DialogTitle>DestinyWorld</DialogTitle>
            <DialogContent style={{display: 'flex', alignItems:'center'}}>
                    <CheckCircleTwoToneIcon style={{color: '#15d44b'}}/> Your Bid was placed Successfully!
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

const Bid = (props) => {

    const {history, location: {state}} = props;
    const {ual: { activeUser }} = props;

    const theme = useTheme();
    const classes = useStyles(theme);
    const cardBackground = useSelector(state => state.theme.cardBackground);
    const type = useSelector(state => state.theme.type);
    const auctionContract = useSelector(state => state.chain.auctionContract);
    const {host, port, protocol} = useSelector(state => state.chain);
    const rpc = new JsonRpc(
        `${protocol}://${host}:${port}`
    );
    const [acceptedTkns, setacceptedTkns] = useState([]);
    const [tokenTable, settokenTable] = useState([]);
    const [bidCoin, setBidCoin] = useState([]);
    const [aucTokens, setaucTokens] = useState([])

    const [amount, setAmount] = useState(0)

    const [unregUser, setunregUser] = useState(false);
    const [noBal, setnoBal] = useState(false);
    const [deadlineExceed, setdeadlineExceed] = useState(false);
    const [txSuccess, settxSuccess] = useState(false);

    // const [bids, setBids] = useState([]);
    const [auctionBids, setAuctionBids] = useState([]);
    const [bidArray, setBidArray] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData(){
            const at = await rpc.get_table_rows({
                json: "true",
                code: auctionContract,
                scope: auctionContract,
                table: "acceptedtkns",
                reverse: false
            })
            const tokens = await rpc.get_table_rows({
                json: "true",
                code: auctionContract,
                scope: auctionContract,
                table: "tokens",
                reverse: false
            });
            setacceptedTkns(at.rows);
            settokenTable(tokens.rows);

            if(props.location.state && props.location.state.auctionData ){
                const {location: {state: {auctionData}}} = props;
                var atids = at.rows
                .filter( token => token.auction_id === auctionData.auction_id)
                .map( ({token_symbol}) => (token_symbol));
                console.log("ATID", atids, at);

                setaucTokens(tokens.rows.filter(token => atids.includes(token.token_symbol)) )
                setBidCoin(tokens.rows.filter(token => atids.includes(token.token_symbol))[0])
            }
            if(activeUser){
                /* FPOV Remove History on Auctions Page due to undefined token_symbol error line 166 position .token_symbol.split(",")
                //setting the history table to be shown in details panel
                const history = await rpc.history_get_actions(activeUser.accountName, 0, 100);
                console.log(history.actions.filter(action => action.action_trace.act.name === 'makebid' && action.action_trace.act.account === auctionContract));

                const bids = history.actions
                .filter(action => action.action_trace.act.name === 'makebid' && action.action_trace.act.account === auctionContract && parseInt(action.action_trace.act.data.auction_id) === auctionData.auction_id)
                .map(({action_trace, block_num, block_time}) => ({act: action_trace.act, block_num, block_time, trx_id: action_trace.trx_id}))
                .map(({act, block_num, block_time, trx_id}) => ({block_num, block_time, amt: act.data.amt, token_symbol: act.data.token_symbol, trx_id}))
                .map(({block_num, block_time, amt, token_symbol, trx_id}) => ({block_num, block_time, token: tokens.rows.filter(row => row.token_symbol === token_symbol)[0].token_symbol.split(",")[1], amt: amt / 10**parseInt(tokens.rows.filter(row => row.token_symbol === token_symbol)[0].token_symbol.split(",")[0]), trx_id } ));
                */

                // console.log("Inside UseEffect", bids);
                // setBids(bids);

                //setting the user bids to be shown under bid list in details panel
                //FPOV: QuickFix showing all bids in PieChart 1000 - Need to be Changed to Iterative
                var {rows} = await rpc.get_table_rows({
                    json: "true",
                    code: auctionContract,
                    scope: auctionContract,
                    table: "bids",
                    limit: 1000,
                    reverse: true
                });
                rows = rows
                .filter(row => row.auction_id === auctionData.auction_id);

                rows
                .forEach( row => {
                    const {token_symbol, token_icon_url, price} = tokens.rows.filter( token => row.token_symbol === token.token_symbol)[0];
                    row.token_symbol = token_symbol.split(",")[1];

                    row.token_icon_url = token_icon_url;
                    row.raw_amt = row.amt;
                    row.amt = row.amt / 10**parseInt(token_symbol.split(",")[0])
                    row.token_price = price;
                    //console.log("Token Iteration Amt:", row.amt, row.token_symbol, row.bid_id);
                });

                var bidMap = {};
                rows.forEach( row => {
                    //FPOV Arithmetic error in bidMap raw_amt to amt and remove incorrect denominator for price, only show tokens.
                    if(row.token_symbol in bidMap) bidMap[row.token_symbol] += row.amt;// * row.token_price; //token_prive need to be divided by 10000
                    else bidMap[row.token_symbol] = row.amt;// * row.token_price; //token_price need to be divided by 10000
                });

                var bidArray = [['Token', 'Amount']];
                for(var token_symbol in bidMap) bidArray.push([token_symbol, bidMap[token_symbol]]);
                console.log("BIDS placed in this auction", rows, bidArray);
                setBidArray(bidArray);
                setAuctionBids(rows);
            }
            setLoading(false);
        }
        fetchData()
    }, [])

    const cardButtonTheme = createMuiTheme({
        palette: {
            type: type,
            primary: blue,
        },
    });

    const handleDialogClose = () => {
        settxSuccess(false);
    }
    const handleDialogOpen = () => {
        settxSuccess(true);
    }

    const handleBid = async (e) => {
        e.preventDefault();
        const bidder_name = activeUser.accountName;
        const {auction_id} = auctionData;
        const {token_symbol} = bidCoin;
        const precision = parseInt(bidCoin.token_symbol.slice(0,1))
        const amt = amount * 10**precision;

        // console.log("Submitted - ")
        // console.log("Bidder Name -",bidder_name)
        // console.log("Auction ID -", auction_id)
        // console.log("Token symbol -", token_symbol)
        // console.log("Precision -", precision)
        // console.log("Amt -", amt);

        const {rows} = await rpc.get_table_rows({
            json: "true",
            code: auctionContract,
            scope: auctionContract,
            table: "users",
            lower_bound: bidder_name,
            upper_bound: bidder_name,
            limit: 1,
            reverse: false
        });

        if( rows.filter(row => row.username === bidder_name).length > 0){
            const contractAccount = auctionContract;
            const name = 'makebid';

            let { requestPermission, accountName} = activeUser
            if (!requestPermission && activeUser.scatter) {
              requestPermission = activeUser.scatter.identity.accounts[0].authority
            }

            const data = {
                bidder_name,
                auction_id,
                token_symbol,
                amt
            }

            const actions = [
                {
                    account: contractAccount,
                    name,
                    authorization: [{actor: accountName, permission: requestPermission}],
                    data
                }
            ];
            try{
                const result = await activeUser.signTransaction({actions}, {expiresSeconds: 120, blocksBehind: 3})
                // console.log("Transaction Result", result);
                if(result.status === "executed"){
                    handleDialogOpen()
                }
            } catch(e) {
                console.log("Error Occured on Submit-", e.message)
                if(e.message === 'assertion failure with message: Wallet dont have this specific token'){
                    setnoBal(true);
                }
                if(e.message.includes('deadline exceeded')){
                    setdeadlineExceed(true);
                }
            }

        } else {
            setunregUser(true);
        }

    }


    if(!state || !props.ual.activeUser){
        return <Redirect to='/auction'/>
    } else {
        var {auctionData, configData} = state;
        // console.log("Auction Tokens", aucTokens)

        return (
            <Container className={classes.bid}>
                <Grid container spacing={3} justify='space-evenly'>
                    <Grid item xs={12} align='left'>
                        <Button startIcon={<ArrowBackIosIcon/>} onClick={()=> history.push({pathname: '/auction'})}>Back to Auctions</Button>
                    </Grid>
                    <Grid container item xs={12} md={5} align='center' style={{justifyContent: 'center'}}>

                        <Card className={classes.card} style={{background: cardBackground}}>
                            {
                                tokenTable.length > 0 &&
                                <CardHeader disableTypography={true}
                                avatar={<Avatar alt="Coin" src={auctionData.token_icon_url}/>}
                                title={<Typography variant='h5' align='left'><b>{auctionData.token_symbol.split(",")[1]} Bid</b></Typography>}/>
                            }

                            <Divider/>

                            <CardContent className={classes.cardContent}>
                                {
                                    !loading && aucTokens.length>0 &&
                                    <form onSubmit={handleBid}>

                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="demo-simple-select-outlined-label">Select Coin</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            defaultValue={aucTokens[0]}
                                            onChange={(e) => setBidCoin(e.target.value)}
                                            label="Select Coin"
                                            >
                                                {aucTokens.map( (token, index) =>
                                                    (
                                                        <MenuItem value={token} key={index}>{token.token_symbol.slice(2)}</MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>


                                        <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: theme.spacing(4)}}>
                                            <TextField
                                                name="amount"
                                                type="number"
                                                label="Amount"
                                                onChange={(e) => setAmount(e.target.value)}
                                                fullWidth
                                                />
                                        </Box>

                                        <Box margin={2} marginTop={8}>
                                            <ThemeProvider theme={cardButtonTheme}>
                                                <Button
                                                variant="contained"
                                                fullWidth
                                                color="primary"
                                                type="submit"
                                                title="Your Bid is not reversible and Price is not Final until Auction Ends."
                                                >
                                                    Bid
                                                </Button>
                                            </ThemeProvider>
                                        </Box>

                                    </form>
                                }

                                {
                                    !loading && aucTokens.length === 0 &&
                                    <Typography>No Tokens specified for this Auction. Please contact Auction Owner!</Typography>
                                }
                                {
                                    loading &&
                                    <CircularProgress />
                                }
                                {
                                    unregUser &&
                                    <Typography variant='caption' color='secondary'><b>You need to register before you can Bid!</b></Typography>
                                }
                                {
                                    noBal &&
                                    <Typography variant='caption' color='secondary'><b>You need to add the Coin to your wallet from User Dashboard first!</b></Typography>
                                }
                                {
                                    deadlineExceed &&
                                    <Typography variant='caption' color='secondary'><b>Deadline Exceeded! Please wait for some time and try again.</b></Typography>
                                }

                            </CardContent>
                        </Card>

                    </Grid>
                    <Grid item xs={12} md={5} align='center'>
                        <Card className={classes.card} style={{background: cardBackground}}>
                            <CardHeader disableTypography={true}
                            title={<Typography variant='h5' align='left'><b>Auction Information</b></Typography>}/>
                            <Divider/>

                            <CardContent className={classes.cardContent}>
                                <Divider/>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: theme.spacing(1), marginBottom: theme.spacing(1)}}>
                                    <Typography color='textSecondary' variant='body1'>Auction Owner</Typography>
                                    <Typography color='textSecondary' variant='body1'>{configData.auctionowner}</Typography>
                                </Box>
                                <Divider/>

                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: theme.spacing(1), marginBottom: theme.spacing(1)}}>
                                    <Typography color='textSecondary' variant='body1'>Pool Amount</Typography>
                                    <Typography color='textSecondary' variant='body1'>{auctionData.pool_amt}</Typography>
                                </Box>
                                <Divider/>

                                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: theme.spacing(1), marginBottom: theme.spacing(1)}}>
                                    {
                                        bidArray.length === 0 && loading &&
                                        <CircularProgress/>
                                    }
                                    {
                                        bidArray.length > 0 &&
                                        <Chart
                                        width={'100%'}
                                        height={'180px'}
                                        chartType="PieChart"
                                        loader={<div>Loading Chart</div>}
                                        data={bidArray}
                                        options={{
                                            title: 'Total tokens in Auction', //FPOv Added new title to reflect changes in bidMap
                                            // Just add this option
                                            // FPOV Added Values instead of percentage
                                            is3D: true,
                                            pieSliceText:'value',
                                            backgroundColor: cardBackground,
                                            chartArea: {
                                                left: '20%',
                                                top: '10%',
                                                width:'100%',
                                                height:'100%'
                                            },
                                            titleTextStyle: {
                                                color: theme.palette.text.primary
                                            },
                                            legend: {
                                                textStyle: {
                                                    color: theme.palette.text.primary
                                                }
                                            }
                                        }}
                                        rootProps={{ 'data-testid': '2' }}
                                        />
                                    }
                                </Box>
                                <Divider/>

                                {/* <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: theme.spacing(1), marginBottom: theme.spacing(1)}}>
                                    <Typography color='textSecondary' variant='body1'>APY</Typography>
                                    <Typography color='textSecondary' variant='body1'> {configData.apy}</Typography>
                                </Box>
                                <Divider/>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: theme.spacing(1), marginBottom: theme.spacing(1)}}>
                                    <Typography color='textSecondary' variant='body1'>Freeze Level</Typography>
                                    <Typography color='textSecondary' variant='body1'> {configData.freeze_level}</Typography>
                                </Box>
                                <Divider/>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: theme.spacing(1), marginBottom: theme.spacing(1)}}>
                                    <Typography color='textSecondary' variant='body1'>Double Reward Time</Typography>
                                    <Typography color='textSecondary' variant='body1'> {configData.double_reward_time}</Typography>
                                </Box>
                                <Divider/> */}

                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: theme.spacing(1), marginBottom: theme.spacing(1)}}>
                                    <Typography color='textSecondary' variant='body1'>Cycle Ends in </Typography>
                                    <Countdown date={new Date(process.env.REACT_APP_CYCLE_TIMESTAMP * 1000)}/>
                                </Box>
                                <Divider/>

                            </CardContent>
                        </Card>

                    </Grid>
                    <Grid item xs={12}>
                        <DetailsPanel
                        auction={auctionData}
                        // bidHistory={bids}
                        auctionBids={auctionBids}
                        loading={loading}
                        />
                    </Grid>

                </Grid>
                <TransactionSuccessDialog open={txSuccess} onClose={handleDialogClose}/>
            </Container>
        )
    }
}

export default withRouter(withUAL(Bid));
