import React, {useState} from 'react';
import { 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    Button, 
    TextField, 
    ThemeProvider, 
    createMuiTheme, 
    useTheme,
    Box
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { blue } from '@material-ui/core/colors';
import mine_card_img from '../../../static/images/mine_card_img@2x.png';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';

const WithdrawDialog = (props) => {
    const {open, onClose, label, ual: {activeUser}, mineData: {mined_token_symbol}, setMessage, setMessageType, setMessageDialog} = props;
    const [withdrawAmt, setWithdrawAmt] = useState(0);
    const cardBackground = useSelector(state => state.theme.cardBackground);
    const miningContract = useSelector(state => state.chain.miningContract);
    const type = useSelector(state => state.theme.type);
    const cardButtonTheme = createMuiTheme({
        palette: {
            type: type,
            primary: blue,
        },
    });
    const theme = useTheme();
    const handleWithdraw = async (e) => {
        e.preventDefault();
        if(activeUser){
            const contractAccount = miningContract;
            const name = 'removestake';

            let { requestPermission, accountName } = activeUser;
            if (!requestPermission && activeUser.scatter) {
            requestPermission = activeUser.scatter.identity.accounts[0].authority
            }

            const data = {
                username: accountName,
                mined_token_symbol,
                stake_asset: `${parseFloat(withdrawAmt).toFixed(label.split(",")[0])} ${label.split(",")[1]}`
            }

            const actions = [
                {
                    account: contractAccount,
                    name,
                    authorization: [{actor: accountName, permission: requestPermission}],
                    data
                }
            ];
            console.log("Action",actions);
            try{
                const result = await activeUser.signTransaction({actions}, {expiresSeconds: 120, blocksBehind: 3})
                // console.log("Transaction Result", result);
                if(result.status === 'executed'){
                    setMessage(`${parseFloat(withdrawAmt).toFixed(label.split(",")[0])} ${label.split(",")[1]} withdrawn to Wallet`)
                    setMessageType(0);
                    setMessageDialog(true);
                } else {
                    setMessage('Something unexpected happened!');
                    setMessageType(2);
                    setMessageDialog(true)
                }
                onClose();
    
            } catch(e) {
                console.log("Error Occured", e);
                onClose();
                setMessage(e.message);
                setMessageType(1);
                setMessageDialog(true);
            }
        }
    };

    return (
        <Dialog open={open} onClose={onClose}       

        PaperProps={{
            style: {
                background: cardBackground
            }
            
        }}>
            <DialogTitle><b>WithDraw</b></DialogTitle>
            <DialogContent style={{
                padding: theme.spacing(4),
                height: '530px',
                width: '500px'

            }}>
                <form onSubmit={handleWithdraw}>
                    <Box>
                        <TextField variant='outlined' label={label.split(",")[1]} fullWidth defaultValue={withdrawAmt} onChange={e => setWithdrawAmt(e.target.value)}/>
                    </Box>
                    <Box style={{marginTop: theme.spacing(4)}}>
                        <ThemeProvider theme={cardButtonTheme}>
                            <Button variant='contained' color='primary' fullWidth type='submit'>Withdraw</Button>
                        </ThemeProvider>
                    </Box>
                </form>
                <Box style={{display: 'flex', justifyContent: 'center'}}>
                    <img src={mine_card_img} alt="withdraw" style={{paddingLeft: theme.spacing(4)}}/>
                </Box>
                
                
            </DialogContent>
        </Dialog>
    )
}

export default withUAL(WithdrawDialog);
