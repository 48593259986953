import React from 'react';
import { useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {  makeStyles } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Divider, useTheme} from '@material-ui/core';
import History from './History';
import StakeList from './StakeList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
        >
            {value === index && (
                <Box p={3} component="div">
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        // backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        background: theme.palette.card,
        ...theme.shape
    },
}));

const DetailsPanel = (props) => {
    const {stakeToken, auctionData, stakes, stakeListActions, loading} = props;
    const cardBackground = useSelector(state => state.theme.cardBackground);
    const theme = useTheme();
    theme.palette.card = cardBackground;
    const classes = useStyles(theme);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
    setValue(newValue);
    };

    return (
    <div className={classes.root}>
        {/* <AppBar position="static" color="default"> */}
        <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        >
            {/* <Tab label="History" {...a11yProps(1)} /> */}
            <Tab label="Stake List" {...a11yProps(0)} />
            {/* <Tab label="Graph" {...a11yProps(2)} /> */}
        </Tabs>

        {/* </AppBar> */}
        <Divider/>
        {/* <TabPanel value={value} index={0}>
            <History stakeToken={stakeToken} history={stakeHistory}/>
        </TabPanel> */}
        <TabPanel value={value} index={0}>
            <StakeList auction={auctionData} stakeToken={stakeToken} stakes={stakes} stakeListActions={stakeListActions} loading={loading}/>
        </TabPanel>
    </div>
    );
}

export default DetailsPanel;