import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';
import {withRouter} from 'react-router-dom';

import { createMuiTheme, makeStyles, useTheme, withStyles} from '@material-ui/core/styles';
import {
    Button,
    Card,
    CardContent,
    Grid,
    Avatar,
    ThemeProvider,
    Typography,
    ButtonBase,
    Box,
    Divider,
    CircularProgress,
    Container,
    IconButton
} from '@material-ui/core';

import { blue } from '@material-ui/core/colors';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import { faqs } from './faqs';
import { FAQPanel } from '../Home/FAQView';

// const fetch = require("node-fetch");
const { JsonRpc } = require("eosjs");

const FREEZE_LEVEL = {
    0: "Open",
    1: "Mine Closed",
    2: "Mine Closed",
    3: "Mine Closed"
}

const useStyles = makeStyles((theme) => ({
    auction: {
        // overflowY: 'hidden',
        // display:'flex',
        // flexGrow: 1,
        minHeight: '100vh',
        justifyContent:'center',
        alignItems: 'center',
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
        textAlign: 'center'
    },
    card:{
        width: '270px',
        height: '360px',
    },
    cardContent:{
        width: '270px',
        height: '360px'
    },
    avatar: {
        height: theme.spacing(8),
        width: theme.spacing(8)
    }
}));



const Mine = (props) => {
    // console.log('Props in Auctions', props)
    const miningContract = useSelector(state => state.chain.miningContract);
    const [minesTable, setMinesTable] = useState([]);
    const [tokenTable, setTokenTable] = useState([]);
    const {host, port, protocol} = useSelector(state => state.chain);
    const rpc = new JsonRpc(
        `${protocol}://${host}:${port}`
    );

    useEffect(() => {
        async function fetchData(){
            var mines = await rpc.get_table_rows({
                json: "true",
                code: miningContract,
                scope: miningContract,
                table: "mines",
                reverse: false
            });
            mines = mines.rows;

            var tokens = await rpc.get_table_rows({
                json: "true",
                code: miningContract,
                scope: miningContract,
                table: "tokens",
                reverse: false
            });
            tokens = tokens.rows;


            console.log("mines", mines);
            console.log("tokens", tokens);

            mines.forEach(mine => {
                mine.mined_token_icon_url = tokens.filter( token => token.tkn_symb === mine.mine_tkn)[0].tkn_url;
                mine.stake_token_icon_url = tokens.filter( token => token.tkn_symb === mine.stake_tkn)[0].tkn_url;
            })

            setMinesTable(mines);
            setTokenTable(tokens);
        }
        fetchData();

    }, [setMinesTable, setTokenTable]);

    const theme = useTheme();
    const classes = useStyles(theme);
    const cardBackground = useSelector(state => state.theme.cardBackground);
    const type = useSelector(state => state.theme.type);

    const {ual, history} = props;

    if(!ual || (ual && !ual.activeUser)){
        history.push({pathname: '/'})
    }

    const cardButtonTheme = createMuiTheme({
        palette: {
            type: type,
            primary: blue,
        },
    });

    const GreenGreyTextTypography = withStyles({
        root: {
            color: "#75937e"
        }
    })(Typography);

    const RedTextTypography = withStyles({
        root: {
            color: "#eb4034"
        }
    })(Typography);

    const handleStakeButton = (mineData) => {
        history.push({
            pathname: 'mine/stake',
            state: {mineData}
        })
    }

    const handleSettingsClick = (mineData) => {
        history.push({
            pathname: 'mine/settings',
            state: {
                mineData
            }
        })
    }


    return (
        <div className={classes.auction}>
            <Grid container spacing={1} justify='space-evenly'>
                <Grid item xs={12}>
                    <Typography variant='h2'>Mining</Typography>
                </Grid>

                {
                    (!minesTable || !tokenTable || minesTable.length === 0 || tokenTable.length === 0) &&
                    <Grid item xs={12} align='center'>
                        <CircularProgress/>
                    </Grid>
                }
                {
                    minesTable && tokenTable && minesTable.length>0 && tokenTable.length>0 &&
                    minesTable.map((mine, index)=>
                    <Grid item xs={12} sm={6} md={4} lg={3} key={mine.mined_token_symbol} align='center'>
                        <Card className={classes.card} style={{background: cardBackground}}>
                            <CardContent className={classes.cardContent}>
                                    <Box m={2}>
                                        <ButtonBase>
                                            <Avatar src={mine.mined_token_icon_url} className={classes.avatar}/>
                                        </ButtonBase>
                                    </Box>

                                    <Box>
                                        <Typography variant='h4'>{mine.mine_tkn.split(",")[1]}</Typography>
                                    </Box>

                                    <Box m={1}>
                                        <Box m={1} >
                                            <Typography variant='caption'>Owner: {mine.mine_owner}</Typography>
                                        </Box>
                                    </Box>

                                    <Box m={2}>
                                        <ThemeProvider theme={cardButtonTheme}>

                                            <Button fullWidth variant='contained' color='primary' onClick={(!ual.activeUser) ? ual.showModal : () => handleStakeButton(mine)}><Typography>Stake</Typography></Button>
                                        </ThemeProvider>

                                    </Box>

                                    <Box m={1} style={{ display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography color='textSecondary' variant='caption'>Base Burn Rate</Typography>
                                        <Typography color='textSecondary' variant='caption'>{(mine.burn_rte / 10000).toFixed(4)}</Typography>

                                    </Box>
                                    <Divider/>

                                    <Box m={1} style={{ display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography color='textSecondary' variant='caption'>Freeze Level</Typography>
                                        {
                                            (mine.freeze_level > 0)? <RedTextTypography variant='caption'>{FREEZE_LEVEL[mine.freeze_lvl]}</RedTextTypography> : <GreenGreyTextTypography variant='caption'>{FREEZE_LEVEL[mine.freeze_lvl]}</GreenGreyTextTypography>
                                        }
                                    </Box>

                                    {/* <Box>
                                        {
                                            ual.activeUser.accountName === mine.mine_owner &&
                                            <IconButton size="small" onClick={()=> handleSettingsClick(mine)}>
                                                <SettingsRoundedIcon/>
                                            </IconButton>
                                        }
                                    </Box> */}

                            </CardContent>

                        </Card>

                    </Grid>
                    )
                }

                <Grid item xs={12} align='center'>
                    <Container style={{
                        borderStyle: 'solid',
                        borderColor: [type === 'dark' ? 'rgba(255, 255, 255, 0.12)' : '#000000'],
                        borderRadius: theme.shape.borderRadius,
                        marginDown: theme.spacing(2),
                        padding: theme.spacing(1)
                    }}>
                        <Typography variant='h3'>FAQs</Typography>
                        <Container align='left' style={{padding: theme.spacing(2), background: cardBackground, marginBottom: theme.spacing(1), borderRadius: '10px 60px'}}>
                            {/* {
                                faqs.map((faq, index) =>
                                <Box key={index} m={1} mb={2}>
                                    <Typography><b>Q{index+1}. {faq.question}</b></Typography>
                                    <Typography><b>A:</b> {faq.answer}</Typography>
                                </Box>
                                )
                            } */}
                            <FAQPanel faqs={faqs} prefix="-M" background={false} />
                        </Container>

                    </Container>

                </Grid>

            </Grid>
        </div>
    )
}

export default withRouter(withUAL(Mine));
