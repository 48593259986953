/* global BigInt */

import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import {
    Container,
    Card,
    Avatar,
    makeStyles,
    useTheme,
    CardContent,
    Typography,
    Box,
    // CardActionArea,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Grid,
    Divider,
    Button,
    CircularProgress,
    // ButtonBase,
    IconButton,
    Hidden,
    createMuiTheme
 } from '@material-ui/core';

import { blue } from '@material-ui/core/colors';
import anchor from '../../../static/images/anchor_wallet.svg';
import scatter from '../../../static/images/scatter_wallet.svg';
import sqrl from '../../../static/images/sqrl_wallet.png';
import wombat from '../../../static/images/wombat_wallet.png';

import RecentActivity from './RecentActivity';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';
import { Redirect, withRouter } from 'react-router-dom';
import WalletsPanel from './WalletsPanel';
import AddToWalletDialog from './AddToWalletDialog';
import WalletWithdrawDialog from './WalletWithdrawDialog';
import MessageDialog from '../../ui/dialog/MessageDialog';
import ProgressBar from './ProgressBar';
import RegisterDialog from '../../ui/navbar/RegisterDialog';
import { createInitialTypes, SerialBuffer } from 'eosjs/dist/eosjs-serialize';
import Long from 'long';

const useStyles = makeStyles((theme) => ({
    aucSettings:{
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(10),
            paddingLeft: theme.spacing(14),
            paddingRight: theme.spacing(14),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(4),
        },
        display: 'flex',
        justifyContent: 'space-evenly'
    },
    card:{
        background: theme.palette.card,
        height: '416px',
        [theme.breakpoints.up('sm')]:{
            width: '350px'
        }
    },
    avatar:{
        height: theme.spacing(15),
        width: theme.spacing(15)
    },
    customHoverFocus: {
        "&:hover, &.Mui-focusVisible": { backgroundColor: "rgba(0, 89, 247, 0.2)" }
    },
    userInfoGrid:{
        [theme.breakpoints.down('sm')]:{
            justifyContent: 'center'
        },
        [theme.breakpoints.up('md')]:{
            justifyContent: 'space-evenly'
        },
        background: theme.palette.card,
        margin: theme.spacing(1),
        ...theme.shape
    },
    walletContainer: {
        background: theme.palette.card,
        minWidth: '100%',
        padding: theme.spacing(4),
        marginTop: theme.spacing(2),
        ...theme.shape
    },
    recentActivityContainer: {
        background: theme.palette.card,
        minWidth: '100%',
        padding: theme.spacing(4),
        marginTop: theme.spacing(2),
        ...theme.shape
    }
}));

const { JsonRpc } = require("eosjs");

const UserDashboard = (props) => {
    const {ual:{activeAuthenticator, activeUser}} = props;
    const cardBackground = useSelector(state => state.theme.cardBackground);
    const theme = useTheme();
    theme.palette.card = cardBackground;
    const classes = useStyles(theme);
    const {auctionContract, miningContract, profilesContract} = useSelector(state => state.chain);
    const {host, port, protocol} = useSelector(state => state.chain);
    const rpc = new JsonRpc(
        `${protocol}://${host}:${port}`
    );
    const [loading, setloading] = useState(true);
    const [recentActivity, setrecentActivity] = useState([]);
    const [auctionTokens, setAuctionTokens] = useState([]);
    const [miningTokens, setMiningTokens] = useState([]);
    const [bids, setBids] = useState([]);
    const [auctionStakes, setAuctionStakes] = useState([]);
    const [miningStakes, setMiningStakes] = useState([]);
    const [walletAdd, setWalletAdd] = useState(false);
    const [auctionWallet, setAuctionWallet] = useState([]);
    const [miningWallet, setMiningWallet] = useState([]);
    const [cpu, setCpu] = useState(0);
    const [net, setNet] = useState(0);
    const [ram, setRam] = useState(0);

    const [withdrawingWallet, setWithdrawingWallet] = useState(
        {
            type: 0,
            row: {
                amt: 0,
                token_symbol: 'NONE',
                wallet_id: 'NONE',
                token_symbol: 'NONE'
            }
        });
    const [walletWithdraw, setWalletWithdraw] = useState(false);


    const [messageDialog, setMessageDialog] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(0); //0 -> sucess, 1 -> failure, 2 -> Information
    const [userProfile, setuserProfile] = useState({});
    const [registerDialog, setregisterDialog] = useState(false);
    const [renderRegister, setrenderRegister] = useState(false);


    // console.log("USER DASHBOARD PROPS", props)
    const type = useSelector(state => state.theme.type);

    const cardButtonTheme = createMuiTheme({
        palette: {
            type: type,
            primary: blue,
        },
    });
    useEffect(() => {
        async function fetchData(){
            if(activeUser){
                var {actions} = await rpc.history_get_actions(activeUser.accountName, 0, 100);

                //setting Up auction contract Details


                const auctionTokenTable = await rpc.get_table_rows({
                    json: "true",
                    code: auctionContract,
                    scope: auctionContract,
                    table: "tokens",
                    reverse: false
                });

                const auctionTable = await rpc.get_table_rows({
                    json: "true",
                    code: auctionContract,
                    scope: auctionContract,
                    table: "allauctions",
                    reverse: false
                });

                const bidsTable = await rpc.get_table_rows({
                    json: "true",
                    code: auctionContract,
                    scope: auctionContract,
                    table: "bids",
                    limit: 500,
                    reverse: false
                });

                var tempStake = await rpc.get_table_rows({
                    json: "true",
                    code: auctionContract,
                    scope: auctionContract,
                    table: "stakes",
                    limit: 2000,
                    reverse: false
                });
                console.log("TempStake", tempStake);
                var stakesTable = tempStake.rows;

                /*
                while(tempStake.more) {
                    console.log(" looping TempStake", tempStake);
                    tempStake = await rpc.get_table_rows({
                        json: "true",
                        code: auctionContract,
                        scope: auctionContract,
                        table: "stakes",
                        limit: 1,
                        reverse: true,

                    });
                    stakesTable = stakesTable.concat(tempStake.rows);
                }*/

                const auctionWalletTable = await rpc.get_table_rows({
                    json: "true",
                    code: auctionContract,
                    scope: auctionContract,
                    table: "wallet",
                    limit: 9999,
                    index_position: 3,
                    key_type: 'name',
                    upper_bound: activeUser.accountName,
                    lower_bound: activeUser.accountName,
                    reverse: false
                })
                // console.log("AUCTION WALLETS and TOKENS - ", auctionWalletTable, auctionTokenTable);

                var walletAuction = auctionWalletTable.rows;
                walletAuction.forEach( entry => {
                    var {token_symbol, token_icon_url} = auctionTokenTable.rows.filter( row => row.token_symbol === entry.token_symbol)[0];
                    entry.token_symbol = token_symbol;
                    entry.token_icon_url = token_icon_url;
                });
                // console.log('Wallet for auction', walletAuction);
                setAuctionWallet(walletAuction);





                var bids = bidsTable.rows.filter( bid => bid.bidder_name === activeUser.accountName);
                bids.forEach( bid => {
                    const {token_symbol, token_icon_url} = auctionTokenTable.rows.filter( row => row.token_symbol === bid.token_symbol)[0];
                    bid.token_symbol = token_symbol;
                    bid.token_icon_url = token_icon_url;
                })
                // console.log("Auction Bids", bids);
                setBids(bids);

                var stakes = stakesTable.filter( stake => stake.staker_name === activeUser.accountName);
                stakes.forEach( stake => {
                    const {token_symbol, token_icon_url} = auctionTokenTable.rows.filter( row => row.token_symbol === stake.token_symbol)[0];
                    stake.token_symbol = token_symbol;
                    stake.token_icon_url = token_icon_url;
                });

                // console.log("Auction Stakes", stakes);
                setAuctionStakes(stakes);

                // console.log("History before", actions);
                actions = actions
                .map(({block_num, block_time, action_trace}) => ({block_num, block_time, act: action_trace.act, trx_id: action_trace.trx_id}))
                .map(({block_num, block_time, act, trx_id}) => ({block_num, block_time, action: act.name, data: act.data, user: act.authorization[0].actor, trx_id, act}))
                .filter(action => action.action ==='transfer');

                actions.forEach(action => {
                    if (auctionTokenTable.rows.filter( row => row.token_symbol === action.data.token_symbol)[0]) {
                        if( action.action === 'makebid' ){
                            const {token_symbol} = auctionTokenTable.rows.filter( row => row.token_symbol === action.data.token_symbol)[0];
                            action.data.amt = parseFloat(action.data.amt) / 10**parseInt(token_symbol.slice(0,1));
                            action.token_name = token_symbol.slice(2);
                        } else if( action.action === 'staketoken'){
                            const {token_symbol} = auctionTokenTable.rows.filter( row => row.token_symbol === action.data.token_symbol)[0];
                            action.data.amt = parseFloat(action.data.amt) / 10**parseInt(token_symbol.slice(0,1));
                            action.token_name = token_symbol.slice(2);
                        }
                    }
                })

                setrecentActivity(actions);
                setAuctionTokens(auctionTokenTable.rows);

                //Setting Up Mining Contract Details
                
                //Convert EOSIO name to i64
                function convertName(name){
                      const builtinTypes = createInitialTypes()
                      const typeUint64 = builtinTypes.get("uint64")
                      const typeName = builtinTypes.get("name")
                      var buffer = new SerialBuffer({ textDecoder: new TextDecoder(), textEncoder: new TextEncoder() });

                      typeName.serialize(buffer, name)
                      return typeUint64.deserialize(buffer)
                    }

                    //console.log("convertName: ", convertName(activeUser.accountName));
                
                //Define Little Endian Hexadecimal Representation for i128 composite key
                const szNamei64 = convertName(activeUser.accountName);
                const searchBound = "0x" + BigInt(szNamei64).toString(16) + "0000000000000000";
                
                const miningTokenTable = await rpc.get_table_rows({
                    json: "true",
                    code: miningContract,
                    scope: miningContract,
                    table: "tokens",
                    reverse: false
                });

                var miningStakesTable = await rpc.get_table_rows({
                    json: "true",
                    code: miningContract,
                    scope: miningContract,
                    table: "stakes",
                    index_position: "2",
                    key_type: "i128",
                    lower_bound: searchBound,
                    upper_bound: searchBound
                });

                var miningMinesTable = await rpc.get_table_rows({
                    json: "true",
                    code: miningContract,
                    scope: miningContract,
                    table: "mines",
                    reverse: false
                });

                miningStakesTable = miningStakesTable.rows.filter(stake => stake.account === activeUser.accountName);

                console.log("Mining Stakes Table:-", miningStakesTable, miningTokenTable);
                miningStakesTable.forEach(stake => {
                    stake.stake_token_icon_url = miningTokenTable.rows.filter(token => token.tkn_symb.split(",")[1] === stake.stakes.split(" ")[1])[0].tkn_url;
                    stake.mine = miningMinesTable.rows.filter(mine => mine.mine_id === stake.mine_id)[0];
                    stake.mine.mined_token_icon_url = miningTokenTable.rows.filter(token => token.tkn_symb === stake.mine.mine_tkn)[0].tkn_url;
                })



                const miningWalletTable = await rpc.get_table_rows({
                    json: "true",
                    code: miningContract,
                    scope: miningContract,
                    table: "wallets",
                    reverse: false,
                    limit: 9999
                })

                var walletMining = miningWalletTable.rows.filter( row => row.account === activeUser.accountName);
                walletMining.forEach( entry => {
                    var {tkn_symb, tkn_url} = miningTokenTable.rows.filter( row => row.tkn_symb.split(",")[1] === entry.balance.split(" ")[1])[0];
                    entry.token = tkn_symb;
                    entry.token_icon_url = tkn_url;
                });
                setMiningWallet(walletMining);
                setMiningStakes(miningStakesTable);
                setMiningTokens(miningTokenTable.rows);


                //getting profile data.
                var profilesTable = await rpc.get_table_rows({
                    json: "true",
                    code: profilesContract,
                    scope: profilesContract,
                    table: "profiles",
                    lower_bound: activeUser.accountName,
                    upper_bound: activeUser.accountName,
                    limit: 1,
                    reverse: false
                });

                profilesTable = profilesTable.rows.filter( profile => profile.account_name === activeUser.accountName)[0];
                var registeredUser = await rpc.get_table_rows({
                    json: "true",
                    code: auctionContract,
                    scope: auctionContract,
                    table: "users",
                    lower_bound: activeUser.accountName,
                    upper_bound: activeUser.accountName,
                    limit: 1,
                    reverse: false
                });
                registeredUser = registeredUser.rows.filter( user => user.username === activeUser.accountName)[0];
                // console.log("Registered User", registeredUser);
                if(!registeredUser) setrenderRegister(true);

                const accountInfo = await rpc.get_account(activeUser.accountName);

                setuserProfile({profile: profilesTable, user: registeredUser, accountInfo});
                const { cpu_limit, net_limit, ram_quota, ram_usage} = accountInfo;
                setCpu(((cpu_limit.used/cpu_limit.available)*100).toFixed(2));
                setRam(((ram_usage / ram_quota)*100).toFixed(2));
                setNet(((net_limit.used / net_limit.available)*100).toFixed(2))
                setloading(false);
            }
        };
        fetchData();

    }, [messageDialog])

    const handleWalletDialogOpen = () => {
        setWalletAdd(true);
    }

    const handleWalletDialogClose = () => {
        setWalletAdd(false);
    }

    const handleWithdrawDialogOpen = (type, row) => {
        setWithdrawingWallet({type, row});
        setWalletWithdraw(true)
    }

    if(!props.ual.activeUser){
        return <Redirect to='/'/>
    } else {

        const activeAuth = activeAuthenticator.getName();
        var avatar_src;
        if(activeAuth === 'anchor'){
            avatar_src = anchor
        } else if(activeAuth === 'scatter'){
            avatar_src = scatter;
        } else if(activeAuth === 'wombat'){
            avatar_src = wombat;
        } else if(activeAuth === 'sqrl'){
            avatar_src = sqrl;
        }
        // console.log('bids', bids);
        // console.log('stakes', stakes);
        // console.log("Profiles Details", userProfile, !userProfile.profile);
        // if(userProfile.accountInfo){
        //     const {account_name, core_liquid_balance, cpu_limit, net_limit, ram_quota, ram_usage} = userProfile.accountInfo;
        //     setCpu((cpu_limit.used/cpu_limit.available).toFixed(2));
        //     setRam((ram_usage / ram_quota).toFixed(2));
        //     setNet((net_limit.used / net_limit.available).toFixed(2))

        // }

        return (
            <Container className={classes.aucSettings}>
                <Grid container spacing={2} style={{justifyContent: 'center'}}>
                    <Grid container item xs={12}  spacing={2} className={classes.userInfoGrid}>
                        <Grid item xs={12} md={4} align='center' style={{background: cardBackground, height: '416px'}}>
                            <Box style={{display: 'flex', justifyContent: 'left'}}>
                                <Typography variant='h5'><b>User Information</b></Typography>
                            </Box>
                            <IconButton>
                                {
                                    userProfile.profile &&
                                    <Avatar src={userProfile.profile.avatar} className={classes.avatar}/>
                                }
                                {
                                    !userProfile.profile &&
                                    <Avatar src={avatar_src} className={classes.avatar}/>
                                }
                            </IconButton>
                            <Box p={1} style={{display: 'flex', justifyContent: 'flex-start'}}>
                                {!userProfile.profile && <Typography><b>Username:</b> {activeUser.accountName}</Typography>}
                            </Box>
                            <Box p={1} style={{display: 'flex', justifyContent: 'flex-start'}}>
                                <Typography><b>Active Wallet:</b> {activeAuthenticator.getName()}</Typography>
                            </Box>
                            {
                                userProfile.user &&
                                <>
                                <Box p={1} style={{display: 'flex', justifyContent: 'flex-start'}}>
                                    <Typography><b>Referred By:</b> {userProfile.user.referred_username && userProfile.user.referred_username} {!userProfile.user.referred_username && "None"}</Typography>
                                </Box>

                                <Box p={1} style={{display: 'flex', justifyContent: 'flex-start'}}>
                                    <Typography><b>Your Referral Code:</b> {userProfile.user.referral_id}</Typography>
                                </Box>
                                </>
                            }
                            {
                                renderRegister &&
                                <>
                                <Box p={1} style={{display: 'flex', justifyContent: 'flex-start'}}>
                                    <Button variant='contained' color='primary' onClick={() => setregisterDialog(true)}>Register</Button>
                                </Box>
                                </>
                            }
                        </Grid>
                        {
                            userProfile.profile &&
                            <Grid item xs={12} md={4} align='center' style={{background: cardBackground, height: '416px'}}>
                                <Box  p={1} style={{display: 'flex', justifyContent: 'flex-start', marginTop: theme.spacing(2)}}>
                                    <Typography><b>Status:</b> {userProfile.profile.status}</Typography>
                                </Box>
                                <Divider/>
                                <Box  p={1} style={{display: 'flex', justifyContent: 'flex-start', marginTop: theme.spacing(4)}}>
                                    <Typography><b>Bio:</b> {userProfile.profile.bio}</Typography>
                                </Box>

                            </Grid>
                        }


                        <Grid item xs={12} md={4} style={{background: cardBackground, height: '416px'}}>

                            <Box m={1} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Typography><b>Core Liquid Balance:</b></Typography>
                                {
                                    !userProfile.accountInfo && loading &&
                                    <CircularProgress/>
                                }

                                {
                                    userProfile.accountInfo &&
                                    <Typography>{userProfile.accountInfo.core_liquid_balance}</Typography>
                                }

                            </Box>
                            <Divider/>
                            <Box m={2} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Typography><b>RAM:</b></Typography>
                                {
                                    !userProfile.accountInfo && loading &&
                                    <CircularProgress/>
                                }

                                {
                                    userProfile.accountInfo &&
                                    <Typography>{(userProfile.accountInfo.ram_usage / 1024).toFixed(2)} / {(userProfile.accountInfo.ram_quota / 1024).toFixed(2)} kB ( {ram}% )</Typography>
                                }

                            </Box>

                            <Box m={2}>
                                <ProgressBar bgcolor="#6a1b9a" completed={ram} />
                            </Box>

                            <Divider/>


                            <Box m={2} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Typography><b>CPU:</b></Typography>
                                {
                                    !userProfile.accountInfo && loading &&
                                    <CircularProgress/>
                                }

                                {
                                    userProfile.accountInfo &&
                                    <Typography>{(userProfile.accountInfo.cpu_limit.used / 1000000).toFixed(2)} / {(userProfile.accountInfo.cpu_limit.available / 1000000).toFixed(2)} s ( {cpu}% )</Typography>
                                }

                            </Box>

                            <Box m={2}>
                                <ProgressBar bgcolor="#00695c" completed={cpu} />

                            </Box>

                            <Divider/>


                            <Box m={2} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Typography><b>Net:</b></Typography>
                                {
                                    !userProfile.accountInfo && loading &&
                                    <CircularProgress/>
                                }

                                {
                                    userProfile.accountInfo &&
                                    <Typography>{(userProfile.accountInfo.net_limit.used / 1024).toFixed(2)} / {(userProfile.accountInfo.net_limit.available / 1024).toFixed(2)} kB ( {net}% )</Typography>
                                }

                            </Box>

                            <Box m={2}>
                                <ProgressBar bgcolor="#ef6c00" completed={net} />
                            </Box>

                            <Divider/>


                        </Grid>

                    </Grid>
                    <Grid item xs={12} md={6} lg={4} align='left'>
                        <Card className={classes.card}>
                            <CardContent>
                                <Box style={{display: 'flex', justifyContent: 'flex-start'}}>
                                    <Typography variant='h5'><b>My Bids</b></Typography>
                                </Box>
                                <Box style={{display: 'flex', justifyContent: 'space-between', padding: theme.spacing(1), paddingLeft: theme.spacing(3)}}>
                                    <Typography variant='h6'><b>Token</b></Typography>
                                    <Typography variant='h6'><b>Amount</b></Typography>
                                </Box>
                                <Divider/>
                                {
                                    bids.length === 0 &&
                                    <Box mt={2}>
                                        {
                                            loading &&
                                            <CircularProgress/>
                                        }
                                        {
                                            !loading &&
                                            <Typography>No Bids Found!</Typography>
                                        }
                                    </Box>
                                }

                                <List style={{maxHeight: '340px', overflowY: 'auto', margin: theme.spacing(1)}}>
                                    {
                                        bids.length > 0 &&
                                        bids
                                        .map((bid, index) =>
                                        <>
                                        <ListItem key={index}>
                                            <Box m={1}>
                                                {index+1}.
                                            </Box>
                                            <ListItemIcon>
                                                <Avatar src={bid.token_icon_url}/>
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Box style={{display: 'flex', justifyContent: 'space-between', paddingRight: theme.spacing(5)}}>
                                                    <Typography>{bid.token_symbol.slice(2)}</Typography>
                                                    <Typography color='textSecondary'>{bid.amt / 10**parseInt(bid.token_symbol.slice(0,1))}</Typography>
                                                </Box>
                                            </ListItemText>
                                        </ListItem>
                                        <Divider/>
                                        </>,
                                        )
                                    }
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} align='left'>
                        <Card className={classes.card}>
                            <CardContent>
                                <Box style={{display: 'flex', justifyContent: 'flex-start'}}>
                                    <Typography variant='h5'><b>My Stakes</b></Typography>
                                </Box>
                                <Box style={{display: 'flex', justifyContent: 'space-between', padding: theme.spacing(1), paddingLeft: theme.spacing(3)}}>
                                    <Typography variant='h6'><b>Amount</b></Typography>
                                    <Typography variant='h6'><b>Days</b></Typography>
                                </Box>
                                <Divider/>
                                {
                                    auctionStakes.length === 0 &&
                                    <Box mt={2}>
                                        {
                                            loading &&
                                            <CircularProgress/>
                                        }
                                        {
                                            !loading &&
                                            <Typography>No Stakes Found!!</Typography>
                                        }
                                    </Box>
                                }
                                <List style={{maxHeight: '340px', overflowY: 'auto', margin: theme.spacing(1)}}>
                                    {
                                        auctionStakes.length > 0 &&
                                        auctionStakes
                                        .map((stake, index) =>
                                        <>
                                        <ListItem key={index} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'left'}}>


                                            <Box style={{display: "flex", alignItems: "space-between"}}>
                                                <Avatar src={stake.token_icon_url} style={{marginRight: theme.spacing(1)}}/>
                                                {stake.amt / 10**parseInt(stake.token_symbol.slice(0,1))}
                                            </Box>


                                            <Typography>{stake.days_passed} / {stake.num_of_days}</Typography>
                                        </ListItem>
                                        <Divider/>
                                        </>,
                                        )
                                    }
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4} align='left'>
                        <Card className={classes.card}>
                            <CardContent>
                                <Box style={{display: 'flex', justifyContent: 'flex-start',}}>
                                    <Typography variant='h5'><b>Mining Positions</b></Typography>
                                </Box>
                                <Box style={{display: 'flex', justifyContent: 'space-between', padding: theme.spacing(1), paddingLeft: theme.spacing(3)}}>
                                    <Typography variant='h6'><b>Amount</b></Typography>
                                    <Typography variant='h6'><b>Pool</b></Typography>
                                </Box>
                                <Divider/>
                                {
                                    miningStakes.length === 0 &&
                                    <Box mt={2}>
                                        {
                                            loading &&
                                            <CircularProgress/>
                                        }
                                        {
                                            !loading &&
                                            <Typography>No Stakes Found!!</Typography>
                                        }
                                    </Box>
                                }
                                <List style={{maxHeight: '340px', overflowY: 'auto', margin: theme.spacing(1)}}>
                                    {
                                        miningStakes.length > 0 &&
                                        miningStakes
                                        .map((stake, index) =>
                                        <>
                                        <ListItem key={index} style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center'}}>
                                            {/* {index+1}. */}
                                            <Box style={{display: "flex", alignItems: "center"}}>
                                                <Avatar src={stake.stake_token_icon_url} style={{marginRight: theme.spacing(1)}}/>
                                                <Typography>
                                                    {parseFloat(stake.stakes)}
                                                    {/* {stake.stake.split(" ")[1]} */}
                                                </Typography>
                                            </Box>
                                            <Box style={{display: "flex", alignItems: "space-between"}}>
                                                <Avatar src={stake.mine.mined_token_icon_url} style={{marginRight: theme.spacing(1)}}/>
                                                <Typography variant="subtitle2">{stake.mine.mine_tkn.split(",")[1]}</Typography>
                                            </Box>
                                        </ListItem>
                                        <Divider/>
                                        </>,
                                        )
                                    }
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} align='center'>
                        <Container className={classes.walletContainer}>
                            <Box style={{display: 'flex', justifyContent: 'space-between', marginBottom: theme.spacing(1)}}>
                                <Typography variant='h5'><b>Wallets</b></Typography>
                                <Button variant='contained' color='primary' onClick={handleWalletDialogOpen}>Deposit</Button>
                            </Box>
                            <Hidden mdUp>
                                {
                                    !loading &&
                                    <Typography> Please slide horizontally to view all options.</Typography>
                                }
                            </Hidden>
                            <WalletsPanel auctionWallet={auctionWallet} walletDialogOnClose={()=>setWalletWithdraw(false)} walletDialogOnOpen={handleWithdrawDialogOpen} miningWallet={miningWallet}/>
                        </Container>
                    </Grid>

                    <Grid item xs={12} align='center'>
                        <Container className={classes.recentActivityContainer}>
                            <Box style={{display: 'flex', justifyContent: 'left', marginBottom: theme.spacing(1)}}>
                                <Typography variant='h5'><b>Recent Activity</b></Typography>
                            </Box>
                            <RecentActivity history={recentActivity}/>
                        </Container>

                    </Grid>
                </Grid>

                <AddToWalletDialog open={walletAdd} onClose={handleWalletDialogClose} auctionTokens={auctionTokens} miningTokens={miningTokens} setMessage={(message) => setMessage(message)} toggleMessageDialog={() => setMessageDialog(true)} setMessageType={(t) => setMessageType(t)}/>

                <WalletWithdrawDialog open={walletWithdraw} onClose={()=>setWalletWithdraw(false)} walletConfig={withdrawingWallet} setMessage={(message) => setMessage(message)} toggleMessageDialog={() => setMessageDialog(true)} setMessageType={(t) => setMessageType(t)}/>

                <MessageDialog open={messageDialog} onClose={() => setMessageDialog(false)} message={message} messageType={messageType}/>

                <RegisterDialog open={registerDialog} onClose={() => setregisterDialog(false)} />
            </Container>
        )
    }
}

export default withRouter(withUAL(UserDashboard));
