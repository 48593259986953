import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        login: false,
        wallet: null,
    },
    reducers: {
        walletLogin: (state, action) => {
            const {wallet} = action.payload;
            state.login = true;
            state.wallet = wallet;
            console.log("Wallet", wallet);
        },

        walletLogout: (state) => {
            console.log("Wallet", state.wallet);
            state.login = false;
            state.wallet = null;
        },
    }
})

export const {walletLogin, walletLogout} = loginSlice.actions;

export default loginSlice.reducer;