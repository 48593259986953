import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import {
    Container,
    Card,
    Avatar,
    makeStyles,
    useTheme,
    CardContent,
    Typography,
    Box,
    IconButton,
    Paper,
    Button,
    CardActionArea,
    // createMuiTheme,
    // ThemeProvider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Grid,
    Divider,
    CircularProgress
 } from '@material-ui/core';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// import { blue } from '@material-ui/core/colors';
// import tether from '../../../static/images/tether.svg';

import RecentActivity from './RecentActivity';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';
import { Redirect, withRouter } from 'react-router-dom';
import ChangeConfigDialog from './ChangeConfigDialog';
import MessageDialog from '../../ui/dialog/MessageDialog';


const useStyles = makeStyles((theme) => ({
    aucSettings:{
        padding: theme.spacing(10),
        paddingLeft: theme.spacing(14),
        paddingRight: theme.spacing(14),
        minHeight: '100vh',
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    card:{
        height: '416px',
        width: '350px'
    },
    avatar:{
        height: theme.spacing(5),
        width: theme.spacing(5)
    },
    customHoverFocus: {
        "&:hover, &.Mui-focusVisible": { backgroundColor: "rgba(0, 89, 247, 0.2)" }
    }

}))

const { JsonRpc } = require("eosjs");

const AuctionSettings = (props) => {

    // console.log('props in Auction Settings', props)
    const {location:{state}, ual:{activeUser}}  = props;
    const theme = useTheme();
    const classes = useStyles(theme);
    const cardBackground = useSelector(state => state.theme.cardBackground);
    // const type = useSelector(state => state.theme.type);
    const [open, setOpen] = useState(false);

    const [tokenTable, settokenTable] = useState([]);
    const [acceptedTokens, setacceptedTokens] = useState([]);
    const [recentActivity, setrecentActivity] = useState([]);
    const [bidList, setbidList] = useState([]);
    const [stakeList, setstakeList] = useState([]);


    const [messageDialog, setMessageDialog] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(0); //0 -> sucess, 1 -> failure, 2 -> Information

    const auctionContract = useSelector(state => state.chain.auctionContract);
    const {host, port, protocol} = useSelector(state => state.chain);
    const rpc = new JsonRpc(
        `${protocol}://${host}:${port}`
    );
    const [loading, setLoading] = useState(true);
    // console.log("HOST, PORT, Protocol", host, port, protocol);

    const handleDialogClose = () => {
        setOpen(false);
    }
    const handleDialogOpen = () => {
        setOpen(true);
    }

    // const cardButtonTheme = createMuiTheme({
    //     palette: {
    //         type: type,
    //         primary: blue,
    //     },
    // });

    useEffect(() => {
        async function fetchData(){
            if(activeUser && state.auctionData){

                const {auctionData} = state;

                var {actions} = await rpc.history_get_actions(auctionContract, 0, 100);
                actions = actions.filter( action => parseInt(action.action_trace.act.data.auction_id) === auctionData.auction_id ||
                (
                    action.action_trace.act.name === 'newauction' &&
                    action.action_trace.act.data.auctionowner === activeUser.accountName &&
                    parseInt(action.action_trace.act.data.pool_token_id) === auctionData.pool_token_id &&
                    parseInt(action.action_trace.act.data.stake_token_id) === auctionData.stake_token_id &&
                    action.action_trace.act.data.proceeds === auctionData.proceeds
                ))
                .map(({action_trace, block_num, block_time}) => ({block_time, block_num, trx_id: action_trace.trx_id, act: action_trace.act}))
                .map(({block_num, block_time, trx_id, act}) => ({block_num, block_time, trx_id, act_name: act.name, account: act.authorization[0].actor, data: act.data}));


                var tokenTable = await rpc.get_table_rows({
                    json: "true",
                    code: auctionContract,
                    scope: auctionContract,
                    table: "tokens",
                    reverse: false
                });
                tokenTable = tokenTable.rows;

                var acceptedTokens = await rpc.get_table_rows({
                    json: "true",
                    code: auctionContract,
                    scope: auctionContract,
                    table: "acceptedtkns",
                    reverse: false
                });
                acceptedTokens = acceptedTokens.rows.filter(token => token.auction_id === auctionData.auction_id);
                acceptedTokens.forEach( each => {
                    const {token_symbol, token_icon_url} = tokenTable.filter(token => parseInt(token.token_id) === parseInt(each.token_id))[0];
                    each.token_symbol = token_symbol;
                    each.token_icon_url = token_icon_url;
                })

                var bids = await rpc.get_table_rows({
                    json: "true",
                    code: auctionContract,
                    scope: auctionContract,
                    table: "bids",
                    reverse: false
                });
                bids = bids.rows.filter(bid => bid.auction_id === auctionData.auction_id);
                bids.forEach(bid => {
                    const {token_symbol, token_icon_url} = tokenTable.filter(token => token.token_id === bid.token_id)[0];
                    bid.token_symbol = token_symbol;
                    bid.token_icon_url = token_icon_url;
                })

                var stakes = await rpc.get_table_rows({
                    json: "true",
                    code: auctionContract,
                    scope: auctionContract,
                    table: "stakes",
                    reverse: false
                });
                stakes = stakes.rows.filter(stake => stake.auction_id === auctionData.auction_id);
                stakes.forEach(stake => {
                    stake.token_id = auctionData.stake_token_id;
                    const {token_symbol, token_icon_url} = tokenTable.filter(token => token.token_id === stake.token_id)[0];
                    stake.token_symbol = token_symbol;
                    stake.token_icon_url = token_icon_url;
                });

                // console.log("History", actions);
                // console.log('Stake List', stakes);
                // console.log("Accepted Tokens", acceptedTokens);
                // console.log("Token Table", tokenTable);
                // console.log("Bid List", bids);


                settokenTable(tokenTable);
                setacceptedTokens(acceptedTokens);
                setbidList(bids);
                setstakeList(stakes);
                setrecentActivity(actions);
            }

        };
        fetchData();
        setLoading(false);
    }, [auctionContract, activeUser, state])

    if(!state || !state.auctionConfig || !activeUser || (state.auctionConfig.auctionowner !== activeUser.accountName)){
        console.log("Config Authority:false", state.auctionConfig.auctionowner, activeUser.accountName);

        return <Redirect to='/'/>
    } else {
        const {location:{state:{auctionConfig, auctionData}}} = props;

        // console.log("Auction Config", auctionConfig);
        // console.log("Config Data", auctionData);
        console.log("Config Authority:true", state.auctionConfig.auctionowner, activeUser.accountName);

        return (
            <div className={classes.aucSettings}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Button startIcon={<ArrowBackIosIcon/>} onClick={()=> props.history.push({pathname: '/auction'})}>Back to Auctions</Button>
                    </Grid>
                    <Grid container item spacing={2} xs={12} align='center' style={{background: cardBackground, backgroundColor: 'rgba(0,0,0,0.1)'}}>
                        <Grid item xs={12} >
                            <Box style={{display: 'flex', justifyContent: 'left', marginBottom: theme.spacing(1)}}>
                                <Typography variant='h5'><b>Auction Configurations</b></Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Card className={classes.card} style={{background: cardBackground}}>
                                <CardContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <CardActionArea >
                                        <Box style={{margin: theme.spacing(2), display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                            <Typography variant='h6'><b>Pool Token:</b></Typography>
                                        </Box>

                                            <Box style={{margin: theme.spacing(2), display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                                                {
                                                    tokenTable.length === 0 &&
                                                    <CircularProgress/>
                                                }
                                                {
                                                    tokenTable.length > 0 &&
                                                    <>
                                                    <Avatar src={tokenTable.filter(token => token.token_id === auctionData.pool_token_id)[0].token_icon_url} className={classes.avatar}/>
                                                    <Typography variant='h6'>{tokenTable.filter(token => token.token_id === auctionData.pool_token_id)[0].token_symbol.slice(2)}</Typography>
                                                    </>
                                                }
                                            </Box>

                                        <Divider/>

                                        <Box style={{margin: theme.spacing(2), display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                            <Typography variant='h6'><b>Stake Token:</b></Typography>
                                        </Box>

                                            <Box style={{margin: theme.spacing(2), display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                                                {
                                                    tokenTable.length === 0 &&
                                                    <CircularProgress/>
                                                }

                                                {
                                                    tokenTable.length > 0 &&
                                                    <>
                                                    <Avatar src={tokenTable.filter(token => token.token_id === auctionData.stake_token_id)[0].token_icon_url} className={classes.avatar}/>
                                                    <Typography variant='h6'>{tokenTable.filter(token => token.token_id === auctionData.stake_token_id)[0].token_symbol.slice(2)}</Typography>
                                                    </>
                                                }
                                            </Box>
                                    </CardActionArea>
                                    <Paper style={{display: 'flex', alignItems: 'center' ,background: cardBackground, border: '1px solid #000000', borderRadius: '10px', padding: theme.spacing(2), marginTop: theme.spacing(1)}}>
                                        <Box pr={3} >
                                            <Typography variant='h6'>Change Config</Typography>
                                        </Box>
                                        <IconButton className={classes.customHoverFocus} onClick={handleDialogOpen}><ArrowRightAltIcon style={{color: '#0059F7'}}/></IconButton>
                                    </Paper>
                                </CardContent>
                            </Card>

                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <Card className={classes.card} style={{background: cardBackground, alignItems: 'center'}}>
                                <CardContent>
                                    <Box p={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant='body1'> Auction Owner:</Typography>
                                        <Typography variant='body1'> {auctionConfig.auctionowner}</Typography>
                                    </Box>
                                    <Divider/>

                                    <Box p={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant='body1'> Double Reward Time:</Typography>
                                        <Typography variant='body1'> {auctionConfig.double_reward_time}</Typography>
                                    </Box>
                                    <Divider/>

                                    <Box p={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant='body1'> Early Withdraw Penalty:</Typography>
                                        <Typography variant='body1'> {auctionConfig.early_withdraw_penalty}</Typography>
                                    </Box>
                                    <Divider/>

                                    <Box p={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant='body1'> Freeze Level:</Typography>
                                        <Typography variant='body1'> {auctionConfig.freeze_level}</Typography>
                                    </Box>
                                    <Divider/>

                                    <Box p={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant='body1'> Referral Percentage (Receiver):</Typography>
                                        <Typography variant='body1'> {auctionConfig.having_a_referral_percentage} %</Typography>
                                    </Box>
                                    <Divider/>

                                    <Box p={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant='body1'> Referral Percentage (Referrer):</Typography>
                                        <Typography variant='body1'> {auctionConfig.referral_percentage} %</Typography>
                                    </Box>
                                    <Divider/>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <Card className={classes.card} style={{background: cardBackground, alignItems: 'center'}}>
                                <CardContent>

                                    <Box p={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant='body1'> Min Stake Amount:</Typography>
                                        <Typography variant='body1'> {auctionConfig.min_stake_amt}</Typography>
                                    </Box>
                                    <Divider/>

                                    <Box p={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant='body1'> Min Stake Days:</Typography>
                                        <Typography variant='body1'> {auctionConfig.min_stake_days}</Typography>
                                    </Box>
                                    <Divider/>

                                    <Box p={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant='body1'> Max Stake Amount:</Typography>
                                        <Typography variant='body1'> {auctionConfig.max_stake_amt}</Typography>
                                    </Box>
                                    <Divider/>


                                    <Box p={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant='body1'> Max Stake Days:</Typography>
                                        <Typography variant='body1'> {auctionConfig.max_stake_days}</Typography>
                                    </Box>
                                    <Divider/>


                                    <Box p={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant='body1'> Max Unwithdrawn Time:</Typography>
                                        <Typography variant='body1'> {auctionConfig.max_unwithdrawn_time}</Typography>
                                    </Box>
                                    <Divider/>

                                    <Box p={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant='body1'> % Share to Distribute:</Typography>
                                        <Typography variant='body1'> {auctionConfig.percentage_share_to_distribute} %</Typography>
                                    </Box>
                                    <Divider/>


                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} style={{justifyContent: 'center'}} spacing={2}>
                        <Grid item xs={12} md={6} lg={4} align='center'>
                            <Card className={classes.card} style={{background: cardBackground}}>
                                <CardContent>
                                    <Box m={2} style={{display: 'flex', justifyContent: 'flex-start'}}>
                                        <Typography variant='h5'><b>Crypto Allow</b></Typography>
                                        {/* <ThemeProvider theme={cardButtonTheme}>
                                            <Button variant='contained' color='primary'>Add</Button>
                                        </ThemeProvider> */}
                                    </Box>
                                    <Divider/>
                                    <Box style={{display: 'flex', justifyContent: 'space-between', padding: theme.spacing(1), paddingLeft: theme.spacing(5)}}>
                                        <Typography variant='h6'><b>Token</b></Typography>
                                        <Typography variant='h6'><b>Weight</b></Typography>
                                        {/* <ThemeProvider theme={cardButtonTheme}>
                                            <Button variant='contained' color='primary'>Add</Button>
                                        </ThemeProvider> */}
                                    </Box>
                                    {
                                        acceptedTokens.length === 0 && loading &&
                                        <CircularProgress/>
                                    }

                                    <List style={{maxHeight: '340px', overflowY: 'auto', margin: theme.spacing(1)}}>
                                        {
                                            acceptedTokens.length > 0 &&
                                            // [...new Array(5)]
                                            acceptedTokens
                                            .map((token, index) =>
                                            <>
                                            <ListItem key={index} >
                                                <ListItemIcon>
                                                    <Avatar src={token.token_icon_url}/>
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                                                        <Typography>{token.token_symbol.slice(2)}</Typography>
                                                        <Typography color='textSecondary'>{token.weight}</Typography>
                                                    </Box>
                                                </ListItemText>
                                            </ListItem>
                                            {/* <Divider/> */}
                                            </>,
                                            )
                                        }
                                        {
                                            acceptedTokens.length === 0 && !loading &&
                                            <ListItem>
                                                <ListItemText align="center">No Crypto Allowed</ListItemText>
                                            </ListItem>
                                        }
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} align='center'>
                            <Card className={classes.card} style={{background: cardBackground}}>
                                <CardContent>
                                    <Box m={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant='h5'><b>Bid List</b></Typography>
                                        {/* <ThemeProvider theme={cardButtonTheme}>
                                            <Button variant='contained' color='primary'>Bid</Button>
                                        </ThemeProvider> */}
                                    </Box>
                                    <Divider/>

                                    <Box style={{display: 'flex', justifyContent: 'space-between', padding: theme.spacing(1), paddingLeft: theme.spacing(5)}}>
                                        <Typography variant='h6'><b>Token</b></Typography>
                                        <Typography variant='h6'><b>Amount</b></Typography>
                                        {/* <ThemeProvider theme={cardButtonTheme}>
                                            <Button variant='contained' color='primary'>Add</Button>
                                        </ThemeProvider> */}
                                    </Box>

                                    {
                                        bidList.length === 0 && loading &&
                                        <CircularProgress/>
                                    }

                                    <List style={{maxHeight: '340px', overflowY: 'auto', margin: theme.spacing(1)}}>
                                        {
                                            // [...new Array(5)]
                                            bidList
                                            .map((bid, index) =>
                                            <>
                                            <ListItem key={index}>
                                                <ListItemIcon>
                                                    <Avatar src={bid.token_icon_url}/>
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                                                        <Typography><b>{bid.token_symbol.slice(2)}</b></Typography>
                                                        <Typography color='textSecondary'>{bid.amt / 10**parseInt(bid.token_symbol.slice(0,1))}</Typography>
                                                    </Box>
                                                </ListItemText>
                                            </ListItem>
                                            {/* <Divider/> */}
                                            </>,
                                            )
                                        }
                                        {
                                           bidList.length === 0 && !loading &&

                                            <ListItem>
                                                <ListItemText align="center">No Bids placed yet!</ListItemText>
                                            </ListItem>
                                        }
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4} align='center'>
                            <Card className={classes.card} style={{background: cardBackground}}>
                                <CardContent>
                                    <Box m={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant='h5'><b>Stake List</b></Typography>
                                        {/* <ThemeProvider theme={cardButtonTheme}>
                                            <Button variant='contained' color='primary'>Bid</Button>
                                        </ThemeProvider> */}
                                    </Box>
                                    <Divider/>

                                    <Box style={{display: 'flex', justifyContent: 'space-between', padding: theme.spacing(1), paddingRight: theme.spacing(4)}}>
                                        <Typography variant='h6'><b>Stake ID</b></Typography>
                                        <Typography variant='h6'><b>Amount</b></Typography>
                                        {/* <ThemeProvider theme={cardButtonTheme}>
                                            <Button variant='contained' color='primary'>Add</Button>
                                        </ThemeProvider> */}
                                    </Box>

                                    {
                                        stakeList.length === 0 && loading &&
                                        <CircularProgress/>
                                    }

                                    <List style={{maxHeight: '340px', overflowY: 'auto', margin: theme.spacing(1)}}>
                                        {
                                            // [...new Array(5)]
                                            stakeList
                                            .map((stake, index) =>
                                            <>
                                            <ListItem key={index}>
                                                {/* <ListItemIcon>
                                                    <Avatar src={token.token_icon_url}/>
                                                </ListItemIcon> */}
                                                <ListItemText>
                                                    <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                                                        <Typography>{stake.stake_id}</Typography>
                                                        <Typography>{stake.amt / 10**parseInt(stake.token_symbol.slice(0,1))} {stake.token_symbol.slice(2)} </Typography>
                                                    </Box>
                                                </ListItemText>
                                            </ListItem>
                                            {/* <Divider/> */}
                                            </>,
                                            )
                                        }
                                        {
                                            stakeList.length === 0 && !loading &&
                                            <ListItem>
                                                <ListItemText align="center">
                                                    No Stakes placed yet!
                                                </ListItemText>
                                            </ListItem>
                                        }
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>



                    {/* <Grid item xs={12} align='center'>
                        <Container style={{background: cardBackground, minWidth: '100%', height: '416px', padding: theme.spacing(4)}}>
                            <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Typography variant='h5'><b>Borrow Rates</b></Typography>
                                <ThemeProvider theme={cardButtonTheme}>
                                    <Button variant='contained' color='primary'>Bid</Button>
                                </ThemeProvider>
                            </Box>

                        </Container>

                    </Grid> */}

                    <Grid item xs={12} align='center'>
                        <Container style={{background: cardBackground, minWidth: '100%', padding: theme.spacing(4)}}>
                            <Box style={{display: 'flex', justifyContent: 'left', marginBottom: theme.spacing(1)}}>
                                <Typography variant='h5'><b>Recent Activity</b></Typography>
                            </Box>
                            <RecentActivity recentActivity={recentActivity} loading={loading}/>
                        </Container>

                    </Grid>


                </Grid>
                <ChangeConfigDialog open={open} onClose={handleDialogClose} auctionConfig={auctionConfig} activeUser={activeUser} rpc={rpc} setMessage={(message) => setMessage(message)} toggleMessageDialog={() => setMessageDialog(true)} setMessageType={(t) => setMessageType(t)}/>
                <MessageDialog open={messageDialog} onClose={() => setMessageDialog(false)} message={message} messageType={messageType}/>
            </div>
        )
    }
}

export default withRouter(withUAL(AuctionSettings));
