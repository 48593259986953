import React from 'react';
import { withRouter } from 'react-router';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme, withStyles, createMuiTheme} from '@material-ui/core/styles';
import { Card, CardContent, Typography, Grid, Button, CardActions, ThemeProvider, Paper } from '@material-ui/core';

import { blue } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
    card:{
        backgroundColor: theme.palette.background.card,
        [theme.breakpoints.up('sm')]:{
            width: '370px',
        },
        height: '417px',
    },
    cardContent:{
        width: '290px',
        height: '350px',
        paddingTop: theme.spacing(5)
    },
    titlePaper:{
        background: 'none',
        display: 'inline-block',
        padding: theme.spacing(2)
    }
}));
const cardButtonTheme = createMuiTheme({
    palette: {
        primary: blue,
    },
  });

const WhiteTextTypography = withStyles({
    root: {
      color: "#FFFFFF"
    }
  })(Typography);

const HomeCard = (props) => {

    const cardBackground = useSelector(state => state.theme.cardBackground)
    const theme = useTheme();
    theme.palette.background.card = cardBackground;
    const classes = useStyles(theme);
    const {title, children, icon, ual, history, pathname} = props;


    return (
        <Card className={classes.card} elevation={4} >

                <CardContent align='center' className={classes.cardContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>

                            <img src={icon} alt={title}/>
                            <Paper className={classes.titlePaper} elevation={0}>
                                <Typography variant='h5'><b>{title}</b></Typography>
                            </Paper>

                        </Grid>
                        <Grid item xs={12} align='left'>
                            {children}
                        </Grid>
                    </Grid>
                </CardContent>
            <CardActions style={{justifyContent:'center', paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3)}}>
                <ThemeProvider theme={cardButtonTheme}>
                    <Button fullWidth variant='contained' color='primary' onClick={(!ual.activeUser) ? () => ual.showModal() : () => history.push({pathname})}><WhiteTextTypography variant='button'>Launch App</WhiteTextTypography></Button>
                </ThemeProvider>
            </CardActions>
        </Card>
    )
}

export default withRouter(withUAL(HomeCard));
