import { createSlice } from '@reduxjs/toolkit';

// TESTNET
// const chainId = '1eaa0824707c8c16bd25145493bf062aecddfeb56c736f6ba6397f3195f33c9f';
// const host = 'testnet.telos.net';
// const port = '443';
// const protocol = 'https';
// const explorer = 'https://telos-test.bloks.io';
// const auctionContract = 'betubetubetu';
// const miningContract = 'destinyminer';
// const profilesContract = 'profiles';


// // MAINNET
// const chainId = '4667b205c6838ef70ff7988f6e8257e8be0e1284a2f59699054a018f743b1d11';
// const host = 'telos.caleos.io';
// const port = '443';
// const protocol = 'https';
// const explorer = 'https://telos.bloks.io';
// const auctionContract = 'auctns.dstny';
// const miningContract = 'mine.dstny';
// const profilesContract = 'profiles';


const chainId = process.env.REACT_APP_CHAINID;
const host = process.env.REACT_APP_HOST;
const port = process.env.REACT_APP_PORT;
const protocol = process.env.REACT_APP_PROTOCOL;
const explorer = process.env.REACT_APP_EXPLORER;
const auctionContract = process.env.REACT_APP_AUCTION_CONTRACT;
const miningContract = process.env.REACT_APP_MINING_CONTRACT;
const profilesContract = process.env.REACT_APP_PROFILES_CONTRACT;

console.log("Env Variables:", process.env);

export const chainSlice = createSlice({
    name: 'chain',
    initialState: {
        chainId,
        host,
        port,
        protocol,
        explorer,
        auctionContract,
        miningContract,
        profilesContract
    },
    reducers: {
    }
})

// export const {} = chainSlice.actions;

export default chainSlice.reducer;
