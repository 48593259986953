exports.faqs = [
    {
        question: 'Which token am I going to mine?',
        answer: 'Depending on the mine you choose, you will be mining the token that is specified by the concerned mine owner. Parameters like rate of mining and amount of token to be staked are also specified by the owner.'
    },
    {
        question: 'What is the stake mechanism',
        answer: 'Each mine have a specific token which will be mined each day. There is a base token which SHOULD be staked to mine. Additional tokens can be staked to boost the mining rate. Each day a certain fraction of staked tokens are consumed to mine the new token.'
    },
    {
        question: 'Is there any fixed time I have to stake the token for',
        answer: 'No, there is no fixed staking time. The staked tokens are slowly converted to the mined token. You can withdraw your token whenever wanted. The mining process will stop once you dont have any more staked base token.'
    },
    {
        question: 'Does having the amount in wallet count as staking',
        answer: 'No, having tokens in the wallet is not considered as staking.'
    }
]