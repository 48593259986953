import React, {useState, useEffect} from 'react';
// import { withRouter } from 'react-router';
// import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';
import { makeStyles, useTheme} from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { Grid, Typography, Box, Hidden, CircularProgress} from '@material-ui/core';
import auctions_img from '../../../static/images/auctions.svg';
// import farming_img from '../../../static/images/farming.svg';
import mining_img from '../../../static/images/mining.svg';

import FAQView from './FAQView';
import ChangeChain from '../../ui/navbar/ChangeChain';
import HomeCard from './HomeCard';
import { withRouter } from 'react-router-dom';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';

const { JsonRpc } = require("eosjs");

// const useStyles = makeStyles((theme) => ({
//     faqGrid:{
//         display: 'flex',
//         background: 'none',
//         [theme.breakpoints.up('sm')]: {
//             paddingLeft: theme.spacing(10),
//             paddingRight: theme.spacing(10)
//         },
//         [theme.breakpoints.down('xs')]: {
//             paddingLeft:theme.spacing(5),
//             paddingRight: theme.spacing(5)
//         },
//     },
//     faqPaper: {
//         display: 'flex',
//         justifyContent: 'left',
//         background:'none',
//         marginTop: theme.spacing(10),
//     }
// }));

const DefaultView = (props) => {
    // const classes = useStyles();
    const theme = useTheme();
    const [atvl, setaTvl] = useState(0);
    //const [mtvl, setmTvl] = useState(0);
    const [loading, setLoading] = useState(true);

    const auctionContract = useSelector(state => state.chain.auctionContract);
    //const miningContract = useSelector(state => state.chain.miningContract);
    const {host, port, protocol} = useSelector(state => state.chain);
    const rpc = new JsonRpc(
        `${protocol}://${host}:${port}`
    );

    useEffect(() => {
        fetch(`https://${host}/v2/state/get_tokens?account=${auctionContract}`)
        .then( response => response.json())
        .then( async (atokens) => {
            const atokensTable = await rpc.get_table_rows({
                json: "true",
                code: auctionContract,
                scope: auctionContract,
                table: "tokens",
                limit: 100,
                reverse: false
            });
          /*  
          fetch(`https://${host}/v2/state/get_tokens?account=${miningContract}`)
            .then( response => response.json())
            .then( async (mtokens) => {
                const mtokensTable = await rpc.get_table_rows({
                    json: "true",
                    code: miningContract,
                    scope: miningContract,
                    table: "tokens",
                    limit: 100,
                    reverse: false
                });*/

            //console.log("FETCHED:", atokens);
            //console.log("FROM Contract:", tokensTable);
            var aamount = 0;
            atokens.tokens.forEach(token => {
                const eachToken = atokensTable.rows.filter(each => each.token_symbol.split(",")[1] === token.symbol)[0];
                aamount += eachToken ? (eachToken.price/10000) * token.amount : 0;
            }); 
            //console.log("AMOUNT", amount);
            setaTvl(aamount);
              
            //console.log("FETCHED:", atokens);
            //console.log("FROM Contract:", tokensTable);
            /*
            var mamount = 0;
            mtokens.tokens.forEach(token => {
                const eachToken = mtokensTable.rows.filter(each => each.token_symbol.split(",")[1] === token.symbol)[0];
                mamount += eachToken ? (eachToken.price/1000000) * token.amount : 0;
            }); 
            //console.log("AMOUNT", amount);
            setmTvl(mamount);*/
            
            setLoading(false);
        });
    }, [])
    // console.log("Default View Props", props);
    return (
        <>
        <Grid container justify='space-evenly' align='center' spacing={2}>
            <Grid item xs={12} style={{paddingBottom: theme.spacing(5)}}>
                <Hidden xsDown>
                    <Typography variant='h1'><b>Destiny World</b></Typography>
                </Hidden>
                <Hidden smUp>
                    <Typography variant='h2'> Destiny World</Typography>
                </Hidden>
                <br/>
                
                <Typography color='textSecondary'>Connecting Technology and People</Typography>
                <br/>
                
                <a href="https://crytter.net/?p=13"><img src="https://crytter.net/_files/partner/81418.jpeg" alt="" /></a>
                <br />
                
                <Box style={{display: "inline-flex", alignItems: "center", border: "solid 1px", padding: theme.spacing(3), borderRadius: theme.shape.borderRadius}}>
                    <Typography> TVL Auction:
                        {
                            !loading &&
                            ` $ ${atvl}`
                        }
                    </Typography>
                 
                    {
                        loading &&
                        <CircularProgress style={{marginLeft: theme.spacing(1)}}/>
                    }
                </Box>
                
                <br/>
            </Grid>
                                
            <Grid item xs={12} lg={3}>
                <HomeCard title='Auctions' icon={auctions_img} pathname='/auction'>
                    <Typography color='textSecondary'>
                        Bid and Stake on tokens connected to our deconomy
                    </Typography>
                    <br/><br/>
                    <Typography color='textSecondary'> Use Destiny Coin on various platforms or Stake them to earn dividends and APR</Typography>
                </HomeCard>
            </Grid>

            <Grid item xs={12} lg={3}>
                <HomeCard title='Mining' icon={mining_img} pathname='/mine'>
                    <Typography color='textSecondary'>
                        Mine our REES token and get access to real life use cases such as credit cards, stable coins and financial instruments
                    </Typography>
                </HomeCard>
            </Grid>
            {/* <Paper className={classes.faqGrid} elevation={0}> */}
            <Grid container item xs={12} spacing={2} align="center">
                <FAQView/>
            </Grid>
            {/* </Paper> */}

        </Grid>
        </>
    )
}

export default withRouter(withUAL(DefaultView));
