import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    // AppBar,
    FormControl,
    // FormControlLabel,
    // FormLabel,
    // RadioGroup,
    // Radio,
    InputLabel,
    Select,
    MenuItem,
    Box,
    TextField,
    Tabs,
    Tab,
    CircularProgress
} from '@material-ui/core';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';
import { withStyles } from '@material-ui/core/styles';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index &&
          <Box p={3}>
              {children}
          </Box>
        }
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


// const { JsonRpc } = require("eosjs");

const AddToWalletDialog = (props) => {
    const {open, onClose, auctionTokens, miningTokens, ual:{ activeUser}, setMessage, toggleMessageDialog, setMessageType} = props;
    const cardBackground = useSelector(state => state.theme.cardBackground);
    const {auctionContract, miningContract} = useSelector(state => state.chain);
    // console.log("Contract Names", auctionContract, miningContract);
    console.log("Mining Tokens", miningTokens);

    const [selectedAuctionToken, setSelectedAuctionToken] = useState(auctionTokens[0]);
    const [selectedMiningToken, setSelectedMiningToken] = useState(miningTokens[0]);
    const [contractName, setContractName] = useState(0);
    const [amount, setAmount] = useState(0);
    const [memo, setMemo] = useState('');

    const handleFormSubmit = async (e) => {
        e.preventDefault();


        const contractAccount = contractName? miningContract: auctionContract;

        console.log("FORM SUBMITTED");
        console.log("Selected Token", contractName ? selectedMiningToken : selectedAuctionToken);
        console.log("Selected Contract", contractName);
        console.log("Amount", parseFloat(amount));
        console.log("Contract Account", contractAccount);

        const name = 'transfer';

        let { requestPermission, accountName } = activeUser
        if (!requestPermission && activeUser.scatter) {
          requestPermission = activeUser.scatter.identity.accounts[0].authority
        }

        const data = {
            from: accountName,
            to: contractAccount,
            quantity: `${parseFloat(amount).toFixed(parseInt(contractName ? selectedMiningToken.tkn_symb.split(",")[0] : selectedAuctionToken.token_symbol.split(",")[0]))} ${contractName ? selectedMiningToken.tkn_symb.split(",")[1] :  selectedAuctionToken.token_symbol.split(",")[1]}`,
            memo
        }

        const actions = [
            {
                account: contractName ? selectedMiningToken.tkn_cntrct : selectedAuctionToken.contract_name,
                name,
                authorization: [{actor: accountName, permission: requestPermission}],
                data
            }
        ];
        console.log("Add action", actions);
        try{
            const result = await activeUser.signTransaction({actions}, {expiresSeconds: 120, blocksBehind: 3})
            // console.log(result);
            if(result.status === 'executed') {
                setMessage('Token added to Wallet')
                setMessageType(0);
                toggleMessageDialog();
            } else {
                setMessage(result.status);
                setMessageType(2);
                toggleMessageDialog();
            }
            onClose();
        } catch(e) {
            console.log("Error Occured", e)
            setMessage(e.message)
            setMessageType(1);
            toggleMessageDialog();
            onClose();
        }
    };
    
    const CustomTab = withStyles({
      root: {
        backgroundColor: 'rgba(144, 238, 144, 0.1)',
        borderRadius: 10,
        overflow: 'hidden'
      },
      selected: {
        backgroundColor: 'rgba(144, 238, 144, 0.8)',
        borderRadius: 10,
        overflow: 'hidden'
      },
    })(Tab);

    return (
        <Dialog open={open} onClose={onClose}
        PaperProps={{
            style: {
                background: cardBackground
            }

        }}
        fullWidth={true}
        >
            <DialogTitle>Add to Wallet</DialogTitle>
            <form onSubmit={handleFormSubmit}>
                <DialogContent>
                    <Tabs value={contractName} onChange={(e, newValue) => setContractName(newValue)} aria-label="wallet option tabs" centered>
                        <CustomTab label="Auction" {...a11yProps(0)}/>
                        <CustomTab label="Mining" {...a11yProps(1)}/>
                    </Tabs>
                    <TabPanel value={contractName} index={0}>
                        {
                            auctionTokens.length>0 &&
                            <>
                            <Box m={2}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="demo-simple-select-outlined-label">Select Coin</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    defaultValue={auctionTokens[0]}
                                    onChange={(e) => setSelectedAuctionToken(e.target.value)}
                                    label="Select Coin"
                                    >
                                        {auctionTokens.map( (token, index) =>
                                            (
                                                <MenuItem value={token} key={index}>{token.token_symbol.split(",")[1]}</MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box m={2}>
                                <TextField variant="outlined" fullWidth label="Amount" required onChange={(e) => setAmount(e.target.value)}/>
                            </Box>

                            <Box m={2}>
                                <TextField variant="outlined" fullWidth label="Memo" defaultValue={memo} onChange={(e) => setMemo(e.target.value)}/>
                            </Box>
                            </>

                        }
                        {
                            auctionTokens.length === 0 &&
                            <CircularProgress />
                        }
                    </TabPanel>
                    <TabPanel value={contractName} index={1}>
                        {
                            miningTokens.length>0 &&
                            <>
                            <Box m={2}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="demo-simple-select-outlined-label">Select Coin</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    defaultValue={miningTokens[0]}
                                    onChange={(e) =>{console.log("Target", e.target.value); setSelectedMiningToken(e.target.value);}}
                                    label="Select Coin"
                                    >
                                        {miningTokens.map( (token, index) =>
                                            (
                                                <MenuItem value={token} key={index}>{token.tkn_symb.split(",")[1]}</MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box m={2}>
                                <TextField variant="outlined" fullWidth label="Amount" required onChange={(e) => setAmount(e.target.value)}/>
                            </Box>

                            <Box m={2}>
                                <TextField variant="outlined" fullWidth label="Memo" defaultValue={memo} onChange={(e) => setMemo(e.target.value)}/>
                            </Box>
                            </>

                        }
                        {
                            miningTokens.length === 0 &&
                            <CircularProgress />
                        }
                    </TabPanel>


                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' type='submit'>
                        <Typography color='textPrimary'>Deposit</Typography>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default withUAL(AddToWalletDialog);
