exports.faqs = [
    {
        question: 'The Auctions',
        answer: 'Each auction has a primary token which will be distributed among all the bidders at the end of the day based on the bid amount'
    },
    {
        question: 'Staking',
        answer: 'Each auction has a associated staking part where you can stake the distributed token for a fix number of days. This will earn you an interest along with a share of daily tokens received as bid.'
    },
    {
        question: 'Contract Wallet',
        answer: 'Before you bid or stake a token, it is necessary to transfer your token to the wallet in smart contract. Tokens can be added and removed from the wallet any time. The gained tokens will be added in the wallet as well which can be withdrawn at convenience.'
    },
    {
        question: 'Do I earn interest by storing in contract wallet ?',
        answer: 'No. The contract only maintains a record of tokens you have send to the contract. You will not earn any interest on this token. You have to separately stake tokens for a specified number of days for earning.'
    },
    {
        question: 'Who can create/modify the various auctions?',
        answer: 'Auctions can be explicitly created ONLY BY DestinyWorld Team. However each auction will have an auction owner. The auction owner and the DestinyWorld Team have authorization to make any modification to auctions.'
    },
    {
        question: 'Can I withdraw stake at any time?',
        answer: 'No. Stakes can be withdrawn only after maturity. Premature withdrawal can be subjected to penalty depending on the configuration of the considered auction.'
    }
]