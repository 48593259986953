import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    Box,
    TextField
} from '@material-ui/core';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';

// const { JsonRpc } = require("eosjs");
const WalletWithdrawDialog = (props) => {
    const {open, onClose, walletConfig: {type, row}, ual: {activeUser}, setMessage, toggleMessageDialog, setMessageType} = props;
    const cardBackground = useSelector(state => state.theme.cardBackground);
    const {auctionContract, miningContract} = useSelector(state => state.chain);
    const [amount, setAmount] = useState(0);
    var wallet_id;

    // console.log("Type", type);
    // console.log("Row", row);

    if(type){
        var {token} = row;
        wallet_id = row.wallet_id;
    } else {
        var {token_symbol} = row;
        wallet_id = row.wallet_id;
    }



    const handleSubmit = async (e) => {
        e.preventDefault();
        if(activeUser){
            const contractAccount = type ? miningContract : auctionContract;
            const name = type ? 'withdraw' : 'subtowallet';

            let { requestPermission, accountName } = activeUser
            if (!requestPermission && activeUser.scatter) {
            requestPermission = activeUser.scatter.identity.accounts[0].authority
            }

            const data = type ?
            {
                account: accountName,
                quantity: `${parseFloat(amount).toFixed(token.split(",")[0])} ${token.split(",")[1]}`
            }
            :
            {
                wallet_id,
                token_symbol,
                amt: parseFloat(amount) * 10**parseInt(token_symbol.split(",")[0])
            }

            const actions = [
                {
                    account: contractAccount,
                    name,
                    authorization: [{actor: accountName, permission: requestPermission}],
                    data
                }
            ];
            // console.log(actions);
            try{
                const result = await activeUser.signTransaction({actions}, {expiresSeconds: 120, blocksBehind: 3})
                // console.log(result);
                if(result.status === 'executed') {
                    setMessage(`${amount} ${type ? token.slice(2) : token_symbol.slice(2)} deducted from wallet ${wallet_id}`)
                    setMessageType(0);
                    toggleMessageDialog();
                } else {
                    setMessage(result.status);
                    setMessageType(2);
                    toggleMessageDialog();
                }
                onClose();
            } catch(e) {
                console.log("Error Occured", e)
                setMessage(e.message)
                setMessageType(1);
                toggleMessageDialog();
                onClose();
            }

        }
    };

    return (
        <Dialog open={open} onClose={onClose}
        PaperProps={{
            style: {
                background: cardBackground
            }

        }}>
            <DialogTitle>Withdraw</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Box m={2}>
                        <TextField variant="outlined" label="Wallet ID" fullWidth defaultValue={wallet_id} disabled/>
                    </Box>

                    <Box m={2}>
                        <TextField variant="outlined" label="Token" fullWidth defaultValue={type ? token.slice(2) : token_symbol.slice(2)} disabled/>
                    </Box>

                    <Box m={2}>
                        <TextField variant="outlined" label="Withdraw Amount" fullWidth defaultValue={amount} onChange={(e) => setAmount(e.target.value)} required/>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' type="submit">
                        <Typography color='textPrimary'>Withdraw</Typography>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default withUAL(WalletWithdrawDialog);
