import React from 'react';
import { useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
    Button,
    useTheme
} from '@material-ui/core';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';


const MessageDialog = (props) => {
    const {open, onClose, message, messageType} = props;
    const cardBackground = useSelector(state => state.theme.cardBackground);
    const theme = useTheme();
   

    return (
        <Dialog open={open} onClose={onClose}
        PaperProps={{
            style: {
                background: cardBackground
            }
            
        }}
        fullWidth={true}
        >
            <DialogTitle>Message</DialogTitle>
            <DialogContent>
                <DialogContentText style={{display: 'flex', alignItems: 'center'}}>
                    {
                        messageType===0 &&
                        <CheckCircleTwoToneIcon style={{color: '#15d44b', margin: theme.spacing(1)}}/>
                    }
                    {
                        messageType===1 &&
                        <ErrorTwoToneIcon style={{color: '#f54b42', margin: theme.spacing(1)}}/>
                    }
                    {
                        messageType===2 &&
                        <InfoTwoToneIcon style={{margin: theme.spacing(1)}}/>
                    }                    
                    {message}
                </DialogContentText>   
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color='primary' onClick={onClose}>
                    <Typography color='textPrimary'>Close</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withUAL(MessageDialog);
