import React,  {useState} from 'react';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';
import { Dialog, DialogTitle, DialogActions, Button, TextField, Box, Typography } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { useSelector } from 'react-redux';

const RegisterDialog = (props) => {
    const {open, onClose, ual: {activeUser}} = props;
    const cardBackground = useSelector(state => state.theme.cardBackground);
    const auctionContract = useSelector(state => state.chain.auctionContract);
    const [referredId, setReferredId] = useState(0);
    const [userExists, setuserExists] = useState(false);

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const contractAccount = auctionContract;
        const name = 'registeruser';

        let { requestPermission, accountName } = activeUser
        if (!requestPermission && activeUser.scatter) {
          requestPermission = activeUser.scatter.identity.accounts[0].authority
        }

        const data = {
            username: accountName,
            referred_id: referredId
        }

        const actions = [
            {
                account: contractAccount,
                name,
                authorization: [{actor: accountName, permission: requestPermission}],
                data
            }
        ];
        console.log(actions);
        try{
            const result = await activeUser.signTransaction({actions}, {expiresSeconds: 120, blocksBehind: 3})
            console.log(result);
            onClose();
        } catch(e) {
            console.log("Error Occured", e)
            if(e.message === 'assertion failure with message: username is already registered!') setuserExists(true);
        }
    }

    return (
        <Dialog open={open} onClose={onClose}
        PaperProps={{
            style: {
                background: cardBackground
            }

        }}>
            <DialogTitle>Register</DialogTitle>
            {
                activeUser &&
                <form onSubmit={handleFormSubmit}>
                    <DialogContent>
                            <Typography>You need to register before participating in any Auction or Mine</Typography>

                            <Box p={2} align='center'>
                                <TextField label='Username' variant='outlined' type='text' required value={activeUser.accountName} disabled />
                            </Box>
                            <Box p={2} align='center'>
                                <TextField label='Referred ID' variant='outlined' type='number' onChange={e => setReferredId(e.target.value)} />
                            </Box>

                    </DialogContent>
                    <DialogActions>
                        {
                            userExists &&
                            <Typography>User Already Registered!</Typography>
                        }
                        <Button type='submit'>
                            Register
                        </Button>
                    </DialogActions>
                </form>
            }
            {
                !activeUser &&
                <DialogContent>
                    <DialogContentText>
                        You need to login before you register!
                    </DialogContentText>
                </DialogContent>

            }

        </Dialog>
    )
}

export default withUAL(RegisterDialog);
