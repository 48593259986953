import React from 'react';
import { useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
    Button,
    useTheme
} from '@material-ui/core';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';


const RiskDisclosureDialog = (props) => {
    const {open, onClose} = props;
    const cardBackground = useSelector(state => state.theme.cardBackground);
    const theme = useTheme();
   

    return (
        <Dialog open={open} 
        PaperProps={{
            style: {
                background: cardBackground
            }
            
        }}
        fullWidth={true}
        >
            <DialogTitle align='center'>Risk Disclosure</DialogTitle>
            <DialogContent>
                <ol>
                    <li>
                        <DialogContentText style={{display: 'flex', alignItems: 'center'}}>
                            Auctions are not without risk. Bidders bid on assets or digital tokens and will receive a share of those tokens based upon multiple variables in the smart contract. There is no guarantee that the monetary value of the assets or digital tokens being auctioned is the same as the monetary value of the assets or digital tokens used for bidding. Variations of market prices, amount of assets or digital tokens used for bidding, amount of bidders and combinations of these will decide the outcome. Assets or digital tokens being auctioned may also be regulated based upon the total monetary value of all assets or digital tokens being used for bidding within each cycle.
                        </DialogContentText>
                    </li>
                    <li>
                        <DialogContentText style={{display: 'flex', alignItems: 'center'}}>
                            Staking has the same risks as Auctions with other parameters that will decide the outcome and returns. There is no guarantee that you will get the same amount of assets or digital tokens back as you used for bidding. Different staking periods, different amounts of staked assets or digital tokens and different amounts of bidders and assets or digital tokens used for bidding will have an impact on returns or outcome each cycle. Staking may also have penalties for early withdrawal and variations in rewards for referrals.
                        </DialogContentText>
                    </li>
                    <li>
                        <DialogContentText style={{display: 'flex', alignItems: 'center'}}>
                            Mining has the same risks as Auctions and Staking. Multiple attributes may affect the outcome such as fees, variations in market prices, level of boosts and impermanent loss of assets or digital tokens held into the ecosystem. There is no guarantee that the mined assets or digital tokens equals the monetary value of the assets or digital tokens being staked. There is no guarantee that bonuses are effective as these rely on other Smart Contracts in the EcoSystem and the activities and multiple attributes being set externally.
                        </DialogContentText>
                    </li>
                    <li>
                        <DialogContentText style={{display: 'flex', alignItems: 'center'}}>
                            Destiny World Smart Contracts and its EcoSystem is built to develop UseCases for users, dApps, assets and digital tokens. It is also built to be self sustainable and not develop any income to a single person, owner, maintainer, developer or controller. The EcoSystem is built to balance out monetary values of its utility assets or digital tokens by using a diversity of human decisions inside and outside the EcoSystem to develop a healthy economy of assets or digital tokens connected.
                        </DialogContentText>
                    </li>
                </ol>
                

                <b>PLEASE NOTE: </b>
                <DialogContentText style={{display: 'flex', alignItems: 'center'}}>
                The Destiny World Smart Contracts are fully decentralized, have no single owner, maintainer, developer or controller. Your use of the EcoSystem is therefore entirely at your own risk - YOU MIGHT LOSE EVERYTHING USING THE ECOSYSTEM. There is no entity or person to recourse to in case of partial or total loss of any assets or digital tokens. PLEASE CONSIDER CAREFULLY BEFORE USING THE ECOSYSTEM. In extremely rare circumstances it is possible that your assets or digital tokens might remain unaccessible for a period of time. NEVER LOCK IN THE DESTINY WORLD SMART CONTRACTS AND ECOSYSTEM MORE ASSETS OR DIGITAL TOKENS THAN YOU CAN AFFORD TO LOSE AND/OR BEING UNABLE TO ACCESS FOR A PROLONGED PERIOD OF TIME.
                </DialogContentText>

            </DialogContent>
            <DialogActions style={{justifyContent: 'center'}}>
                <Button variant='contained' color='primary' onClick={onClose} style={{padding: theme.spacing(1)}}>
                    <Typography color='textPrimary'>I Understand</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withUAL(RiskDisclosureDialog);
