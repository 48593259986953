import { createSlice } from '@reduxjs/toolkit';

export const themeSlice = createSlice({
    name: 'theme',
    initialState: {
        type: 'dark',
        defaultBackground: '#080c27',
        footerBackground: '#081339',
        cardBackground: '#162044',
        loginButton: '#162044',
        drawerColor: '#162044',
        cardBackgroundDark: '#001539'

    },
    reducers: {
        toggleTheme: (state) => {
            state.type = state.type === 'dark' ? 'light' : 'dark';
            state.defaultBackground = state.defaultBackground === '#080c27' ? '#E5E5E5' : '#080c27';
            state.footerBackground = state.footerBackground === '#081339' ? '#D5E0FA' : '#081339';
            state.cardBackground = state.cardBackground === '#162044' ? '#ffffff' : '#162044';
            state.cardBackgroundDark = state.cardBackgroundDark === '#001539' ? '#ffffff' : '#001539';
            state.loginButton = state.loginButton === '#162044' ? '#98A6C7' : '#162044';
            state.drawerColor = state.drawerColor === '#162044' ? '#D5E0FA' : '#162044';
        },
    }
})

export const {toggleTheme} = themeSlice.actions;

export default themeSlice.reducer;