import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';
import {withRouter} from 'react-router-dom';

import { createMuiTheme, makeStyles, useTheme, withStyles} from '@material-ui/core/styles';
import {
    Button,
    Card,
    CardContent,
    Grid,
    Avatar,
    ThemeProvider,
    Typography,
    ButtonBase,
    Box,
    IconButton,
    CircularProgress ,
    Container
} from '@material-ui/core';

import { blue } from '@material-ui/core/colors';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import { faqs } from './faqs';
import {FAQPanel} from '../Home/FAQView';

// const fetch = require("node-fetch");
const { JsonRpc } = require("eosjs");

const FREEZE_LEVEL = {
    0: "Open",
    1: "Auction Closed",
    2: "Staking Closed",
    3: "Auction and Staking Closed"
}

const useStyles = makeStyles((theme) => ({
    auction: {
        // overflowY: 'hidden',
        // display:'flex',
        // flexGrow: 1,
        minHeight: '100vh',
        justifyContent:'center',
        alignItems: 'center',
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
        textAlign: 'center'
    },
    card:{
        width: '270px',
        height: '360px',
    },
    cardContent:{
        width: '270px',
        height: '360px'
    },
}));



const Auction = (props) => {
    // console.log('Props in Auctions', props)
    const [auctionTable, setAuctionTable] = useState([]);
    const [configTable, setConfigTable] = useState([]);

    const auctionContract = useSelector(state => state.chain.auctionContract);
    const {host, port, protocol} = useSelector(state => state.chain);
    const rpc = new JsonRpc(
        `${protocol}://${host}:${port}`
    );

    useEffect(() => {
        async function fetchData(){
            var auctions = await rpc.get_table_rows({
                json: "true",
                code: auctionContract,
                scope: auctionContract,
                table: "allauctions",
                limit: 100,
                reverse: false
            });
            auctions = auctions.rows;

            const tokens = await rpc.get_table_rows({
                json: "true",
                code: auctionContract,
                scope: auctionContract,
                table: "tokens",
                limit: 100,
                reverse: false
            });

            const configs = await rpc.get_table_rows({
                json: "true",
                code: auctionContract,
                scope: auctionContract,
                table: "configs",
                limit: 100,
                reverse: false
            });

            auctions.forEach(auction => {
                const {token_symbol, token_icon_url} = tokens.rows.filter( token => token.token_symbol.split(",")[1] === auction.pool_asset.split(" ")[1])[0];
                auction.token_symbol = token_symbol;
                auction.token_icon_url = token_icon_url;
            })

            setAuctionTable(auctions);
            setConfigTable(configs.rows);
        }
        fetchData()

    }, [rpc.endpoint, auctionContract]);

    const theme = useTheme();
    const classes = useStyles(theme);
    const cardBackground = useSelector(state => state.theme.cardBackground);
    const type = useSelector(state => state.theme.type);

    const {ual, history} = props;

    if(!ual || (ual && !ual.activeUser)){
        history.push({pathname: '/'})
    }

    const cardButtonTheme = createMuiTheme({
        palette: {
            type: type,
            primary: blue,
        },
    });

    const GreenGreyTextTypography = withStyles({
        root: {
            color: "#75937e"
        }
    })(Typography);

    const RedTextTypography = withStyles({
        root: {
            color: "#eb4034"
        }
    })(Typography);


    const handleBidButton = (auctionData, configData) => {
        history.push({
            pathname: '/auction/bid',
            state: {auctionData, configData}
        })
    };

    const handleStakeButton = (auctionData, configData) => {
        history.push({
            pathname: '/auction/stake',
            state: {auctionData, configData}
        })
    }

    const handleSettingsClick = (auctionConfig, auctionData) => {
        history.push({
            pathname: '/auction/settings',
            state: {
                auctionConfig,
                auctionData
            }
        })
    }


    return (
        <div className={classes.auction}>

            <Grid container spacing={1} justify='space-evenly'>
                <Grid item xs={12}>
                    <Typography variant='h2'>Auctions</Typography>
                </Grid>


                {
                    (!auctionTable || !configTable || auctionTable.length === 0 || configTable.length === 0) &&
                    <Grid item xs={12} align='center'>
                        <CircularProgress/>
                    </Grid>
                }
                {
                    auctionTable && configTable && auctionTable.length>0 && configTable.length>0 &&
                    auctionTable.map((auction, index)=>
                    <Grid item xs={12} sm={6} md={4} lg={3} key={auction.auction_id} align='center'>
                        <Card className={classes.card} style={{background: cardBackground}}>
                            <CardContent className={classes.cardContent}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <ButtonBase>
                                            <Avatar src={auction.token_icon_url} style={{height: theme.spacing(8), width: theme.spacing(8)}} />
                                        </ButtonBase>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant='h4'>{auction.token_symbol.split(",")[1]}</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box m={1} >
                                            <Typography variant='caption'>Owner: {auction.auctionowner}</Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <ThemeProvider theme={cardButtonTheme}>

                                            <Button fullWidth variant='contained' color='primary' onClick={(!ual.activeUser) ? ual.showModal : () => handleBidButton(auction, configTable.filter( config => config.config_id === auction.config_id)[0])}><Typography>Bid</Typography></Button>
                                        </ThemeProvider>

                                    </Grid>

                                    <Grid item xs={12}>
                                        <ThemeProvider theme={cardButtonTheme}>
                                            <Button fullWidth variant='outlined' color='primary' onClick={(!ual.activeUser) ? ual.showModal : () => handleStakeButton(auction, configTable.filter( config => config.config_id === auction.config_id)[0])}><Typography color='textPrimary'>Stake</Typography></Button>
                                        </ThemeProvider>
                                    </Grid>
                                    {/* <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography color='textSecondary' variant='caption'>Owner</Typography>
                                        <Typography color='textSecondary' variant='caption'>{auction.auctionowner}</Typography>

                                    </Grid> */}
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <Typography color='textSecondary' variant='caption'>APR</Typography>
                                        <Typography color='textSecondary' variant='caption'>{configTable.filter( config => config.config_id === auction.config_id)[0].apy}</Typography>

                                    </Grid>

                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <Typography color='textSecondary' variant='caption'>Freeze Level</Typography>
                                        {
                                            (configTable.filter( config => config.config_id === auction.config_id)[0].freeze_level > 0)? <RedTextTypography variant='caption'>{FREEZE_LEVEL[configTable.filter( config => config.config_id === auction.config_id)[0].freeze_level]}</RedTextTypography> :<GreenGreyTextTypography variant='caption'>{FREEZE_LEVEL[configTable.filter( config => config.config_id === auction.config_id)[0].freeze_level]}</GreenGreyTextTypography>
                                        }
                                    </Grid>

                                    <Grid item xs={12}>
                                        {
                                            ual.activeUser.accountName === auction.auctionowner &&
                                            <IconButton size="small" onClick={()=> handleSettingsClick(configTable.filter(config => config.config_id === auction.auction_id)[0], auction)}>
                                                <SettingsRoundedIcon/>
                                            </IconButton>
                                        }
                                    </Grid>


                                </Grid>
                            </CardContent>

                        </Card>

                    </Grid>
                    )
                }

                <Grid item xs={12} align='center'>
                    <Container style={{
                        borderStyle: 'solid',
                        borderColor: [type === 'dark' ? 'rgba(255, 255, 255, 0.12)' : '#000000'],
                        borderRadius: theme.shape.borderRadius,
                        marginDown: theme.spacing(2),
                        padding: theme.spacing(1)
                    }}>
                        <Typography variant='h3'>FAQs</Typography>
                        <Container align='left' style={{padding: theme.spacing(1), background: cardBackground, borderRadius: '10px 60px'}}>
                            {/* {
                                faqs.map((faq, index) =>
                                <Box key={index}  m={1} mb={2}>
                                    <Typography><b>Q{index+1}. {faq.question}</b></Typography>
                                    <Typography color='textSecondary'><b>A:</b> {faq.answer}</Typography>
                                </Box>
                                )
                            } */}
                            <FAQPanel faqs={faqs} prefix="-A" background={false}/>
                        </Container>

                    </Container>

                </Grid>

            </Grid>
        </div>
    )
}

export default withRouter(withUAL(Auction));
