import React from 'react';
import { useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
    Button,
    useTheme
} from '@material-ui/core';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';


const ContactDialog = (props) => {
    const {open, onClose} = props;
    const cardBackground = useSelector(state => state.theme.cardBackground);
    const theme = useTheme();
   

    return (
        <Dialog open={open} 
        PaperProps={{
            style: {
                background: cardBackground
            }
            
        }}
        fullWidth={true}
        >
            <DialogTitle align='center'>Contact</DialogTitle>
            <DialogContent>
                <ol>
                    <li>
                        <DialogContentText style={{display: 'flex', alignItems: 'center'}}>
                          CIO: Filip Poverud
                        </DialogContentText>
                    </li>
                    <li>
                        <DialogContentText style={{display: 'flex', alignItems: 'center'}}>
                          Address: Kleiverudveien 193A NO-3086 Holmestrand Norway
                        </DialogContentText>
                    </li>
                    <li>
                        <DialogContentText style={{display: 'flex', alignItems: 'center'}}>
                          Phone: +47 950 27 908
                        </DialogContentText>
                    </li>
                    <li>
                        <DialogContentText style={{display: 'flex', alignItems: 'center'}}>
                          E-Mail: filip.poverud@destinyworld.com
                        </DialogContentText>
                    </li>
                </ol>
            </DialogContent>
            <DialogActions style={{justifyContent: 'center'}}>
                <Button variant='contained' color='primary' onClick={onClose} style={{padding: theme.spacing(1)}}>
                    <Typography color='textPrimary'>Back...</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withUAL(ContactDialog);
