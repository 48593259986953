import React, {useState} from 'react';
import { 
    Dialog, 
    DialogTitle,
    DialogContent, 
    DialogActions, 
    Button, 
    TextField, 
    Box, 
    makeStyles, 
    createMuiTheme, 
    ThemeProvider, 
    useTheme, 
    Typography, 
    IconButton, 
    Paper
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { blue } from '@material-ui/core/colors';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';

const useStyles = makeStyles((theme)=>({
    box:{
        margin: theme.spacing(2)
    }
}))


const ChangeConfigDialog = (props) => {
    const {open, onClose, mineData, activeUser, setMessage, setMessageType, toggleMessageDialog} = props;

    console.log("Mine Config", mineData);
    const {base_burn_rate, base_token_symbol, freeze_level, mined_token_symbol, proceeds} = mineData;
    const [mts, setmts] = useState(mined_token_symbol);
    const [p, setp] = useState(proceeds);
    const [fl, setfl] = useState(freeze_level);
    const [bts, setbts] = useState(base_token_symbol);
    const [bbr, setbbr] = useState(base_burn_rate);

    const classes = useStyles();
    const cardBackground = useSelector(state => state.theme.cardBackground);
    const type = useSelector(state => state.theme.type);
    const miningContract = useSelector(state => state.chain.miningContract);

    const theme = useTheme()
    
    const cardButtonTheme = createMuiTheme({
        palette: {
            type: type,
            primary: blue,
        },
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const contractAccount = miningContract;
        const name = 'changeconfig';

        let { requestPermission, accountName } = activeUser
        if (!requestPermission && activeUser.scatter) {
          requestPermission = activeUser.scatter.identity.accounts[0].authority
        }
        
        const data = {
            mined_token_symbol: mts,
            new_proceeds_account: p,
            new_freeze_level: fl,
            new_base_token_symbol: bts,
            new_burn: bbr
        }

        const actions = [
            {
                account: contractAccount,
                name,
                authorization: [{actor: accountName, permission: requestPermission}],
                data
            }
        ];
        console.log(actions);
        try{
            const result = await activeUser.signTransaction({actions}, {expiresSeconds: 120, blocksBehind: 3})
            // console.log(result);
            if(result.status === 'executed') {
                setMessage('Mining Config Changed')
                setMessageType(0);
                toggleMessageDialog();
            } else {
                setMessage('Something unexpected happened!');
                setMessageType(2);
                toggleMessageDialog();
            }
            onClose();
        } catch(e) {
            console.log("Error Occured", e)
            setMessage(e.message)
            setMessageType(1);
            toggleMessageDialog();
            onClose();
        }
    }

    return (
        <Dialog open={open} onClose={onClose} 
        maxWidth="sm"
        fullWidth={true}
        PaperProps={{
            style: {
                background: cardBackground
            }
            
        }}
        
        >
            <DialogTitle style={{padding: theme.spacing(0)}}>
                <Paper style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: 'none'}}>
                <Typography variant='h5' style={{padding: theme.spacing(2)}}>
                    <b>Change Config</b>
                </Typography>
                <IconButton onClick={onClose}>
                    <CloseTwoToneIcon/>
                </IconButton>
                </Paper>
                
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Box className={classes.box}>
                        <TextField label='mined_token_symbol (symbol)' type='text' variant='outlined' fullWidth defaultValue={mts} onChange={e => setmts(e.target.value)}/>
                    </Box>

                    <Box className={classes.box}>
                        <TextField label='proceeds_account (name)' type='text' variant='outlined' fullWidth defaultValue={p} onChange={e => setp(e.target.value)}/>
                    </Box>

                    <Box className={classes.box}>
                        <TextField label='freeze_level (int64)' type='number' variant='outlined' fullWidth defaultValue={fl} onChange={e => setfl(e.target.value)}/>
                    </Box> 

                    <Box className={classes.box}>
                        <TextField label='base_token_symbol (symbol)' type='text' variant='outlined' fullWidth defaultValue={bts} onChange={e => setbts(e.target.value)}/>
                    </Box>  

                    <Box className={classes.box}>
                        <TextField label='base_burn_rate (int64)' type='number' variant='outlined' fullWidth defaultValue={bbr} onChange={e => setbbr(e.target.value)}/>
                    </Box>                 
                </DialogContent>

                <DialogActions style={{padding: theme.spacing(4)}}>
                    <Button onClick={onClose}>Cancel</Button>
                    <ThemeProvider theme={cardButtonTheme}>
                        <Button variant='contained' color='primary' type='submit'>Submit Transaction</Button>
                    </ThemeProvider>
                </DialogActions>
            </form>

        </Dialog>
    )
}

export default ChangeConfigDialog
