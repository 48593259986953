import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Button, Avatar, Box } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';
// import { useSelector } from 'react-redux';

// const { JsonRpc } = require("eosjs");
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

const MiningWallet = (props) => {
    const {miningWallet, withdrawDialogOpen} = props;
    // console.log("Mining Wallet", miningWallet);

    const theme = useTheme();
    const classes = useStyles2();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // const {host, port, protocol} = useSelector(state => state.chain);
    // const rpc = new JsonRpc(
    //     `${protocol}://${host}:${port}`
    // );



    const emptyRows = rowsPerPage - Math.min(rowsPerPage, miningWallet.length - page * rowsPerPage);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
        {
          miningWallet.length > 0 &&

          <TableContainer  style={{background: 'none', padding: theme.spacing(1)}} elevation={0}>
              <Table className={classes.table} aria-label="custom pagination table">
                  <TableHead style={{background: 'rgba(47, 122, 255, 0.1)'}}>
                      <TableRow>
                          <TableCell align='left'><Typography variant='body1'><b>Balance</b></Typography></TableCell>
                          <TableCell align='left'><Typography variant='body1'><b>Token</b></Typography></TableCell>
                          <TableCell align='left'><Typography variant='body1'><b>Action</b></Typography></TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {(rowsPerPage > 0 ?
                      miningWallet.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : miningWallet
                      ).map((row, index) => (
                      <TableRow key={index}>
                          <TableCell align='left'>
                              <Box style={{display: 'flex', alignItems: 'left', justifyContent: 'flex-start'}}>
                                <Typography variant='body2'>{parseFloat(row.balance).toFixed(row.token.split(",")[0])}</Typography>
                              </Box>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Box style={{display: 'flex', alignItems: 'left', justifyContent: 'flex-start'}}>
                              <Avatar src={row.token_icon_url} alt={row.token} style={{height: theme.spacing(3), width: theme.spacing(3), marginRight: theme.spacing(1)}}/>
                              <Typography variant='body2' color='textPrimary'>{row.token.split(",")[1]}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell align='left'>
                            <Button variant='contained' color='primary' onClick={()=> withdrawDialogOpen(1, row)}><Typography variant='body2'>Withdraw</Typography></Button>
                          </TableCell>
                      </TableRow>
                      ))}

                      {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                      </TableRow>
                      )}
                  </TableBody>

                  <TableFooter>
                      <TableRow>
                          <TablePagination
                              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                              colSpan={6}
                              count={miningWallet.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                              inputProps: { 'aria-label': 'rows per page' },
                              native: true,
                              }}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                          />
                      </TableRow>
                  </TableFooter>
              </Table>
          </TableContainer>
        }
        </>
    );
}
export default withUAL(MiningWallet);
