import React from 'react';
import {useSelector} from 'react-redux';
import { createMuiTheme, makeStyles} from '@material-ui/core/styles';
// import {useMediaQuery} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

import { Route, Switch } from 'react-router';

import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';
import { UALProvider } from 'ual-reactjs-renderer';
// import { Sqrl } from '@smontero/ual-sqrl';
import { Anchor } from 'ual-anchor';
// import { Scatter } from 'ual-scatter';
import { Wombat } from 'ual-wombat';
import { UalHamian } from 'ual-hamian';


import AuctionSettings from './component/pages/AuctionSettings/AuctionSettings';
import UserDashboard from './component/pages/User Dashboard/UserDashboard';
import AuctionStake from './component/pages/AuctionStake/AuctionStake';
import MineStake from './component/pages/MineStake/MineStake';
import Home from './component/pages/Home/Home';
import Bid from './component/pages/Bid/Bid';
import Auction from './component/pages/Auction/Auction';
import Mine from './component/pages/Mine/Mine';
import MiningSettings from './component/pages/MiningSettings/MiningSettings';
// import Farm from './component/pages/Farm/Farm';
import Navbar from './component/ui/navbar/Navbar';
import Footer from './component/ui/footer/Footer';

const useStyles = makeStyles((theme) => ({
  app:{
    minHeight: '100vh',
    minWidth: '100vw',
  }
}))



function App(props) {

  // const theme = useTheme();
  const type = useSelector(state => state.theme.type);
  const background = useSelector(state => state.theme.defaultBackground);
  const {chainId, protocol, host, port } = useSelector(state => state.chain);

  const customTheme = createMuiTheme({
    palette: {
      type: type,
      background:{
        default: background
      }
    },
    shape:{
      borderRadius: 8
    }
  });
  const classes = useStyles(customTheme);
  console.log("App Theme", customTheme);
  console.log("App Props", props)

  const CHAIN = {
    chainId: chainId,
    rpcEndpoints: [{
        protocol: protocol,
        host: host,
        port: port,
    }]
  }

  console.log("CHAIN:", CHAIN);
//   const sqrl_auth = new Sqrl([CHAIN], { appName: 'Destinyworld Telos' });
  const anchor_auth = new Anchor([CHAIN], { appName: 'Destinyworld Telos' });
//   const scatter_auth = new Scatter([CHAIN], { appName: 'Destinyworld Telos' });
  const wombat_auth = new Wombat([CHAIN], { appName: 'Destinyworld Telos' });
  const hamian_config = {
    useChainId: false,
    appId: '',
    appTitle: 'Destinyworld Telos',
    serverUrl: 'https://api.hamian-wallet.com/',
    botName: 'hamianwalletbot'
  }
  const hamian_auth = new UalHamian([CHAIN], hamian_config);




  // var xs = useMediaQuery(theme.breakpoints.down('xs'));
  // var sm = useMediaQuery(theme.breakpoints.down('sm'));
  // var md = useMediaQuery(theme.breakpoints.down('md'));
  // var lg = useMediaQuery(theme.breakpoints.down('lg'));
  // var xl = useMediaQuery(theme.breakpoints.down('xl'));



  // console.log(xs, sm, md, lg, xl);
  // console.log("APP Props", props)

  return (
    <UALProvider chains={[CHAIN]} authenticators={[
//       sqrl_auth,
      anchor_auth,
//       scatter_auth,
      wombat_auth,
      hamian_auth
      ]} appName={'Destinyworld Telos'}>
      <ThemeProvider theme={customTheme}>
        <div className={classes.app}>
          <Navbar/>
          <Switch>

            <Route path='/auction' exact>
              <Auction/>
            </Route>

            <Route path='/mine' exact>
              <Mine/>
            </Route>
            <Route path='/mine/stake' exact>
              <MineStake/>
            </Route>
            <Route path='/mine/settings' exact>
              <MiningSettings/>
            </Route>

            <Route path='/faq' exact>
              <Home/>
            </Route>

            <Route path='/farm' exact>
              {/* <Farm/> */}
              <Home/>
            </Route>

            <Route path='/auction/stake' exact>
              <AuctionStake/>
            </Route>

            <Route path='/auction/bid' exact>
              <Bid/>
            </Route>

            <Route path='/dashboard' exact>
              <UserDashboard/>
            </Route>
            <Route path='/' exact>
              <Home/>
            </Route>
            <Route path='/auction/settings' exact>
              <AuctionSettings/>
            </Route>

          </Switch>
          <Footer/>
        </div>
      </ThemeProvider>
    </UALProvider>

  );
}

export default withUAL(App);
