import React from 'react';
// import { useSelector } from 'react-redux';
// import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';
// import {withRouter} from 'react-router-dom';

import { makeStyles, useTheme} from '@material-ui/core/styles';

import DefaultView from './DefaultView';




// const WhiteTextTypography = withStyles({
//     root: {
//       color: "#FFFFFF"
//     }
//   })(Typography);

// const GreyTextTypography = withStyles({
//     root: {
//       color: "#5e6a94"
//     }
//   })(Typography);



 
const useStyles = makeStyles((theme) => ({
    home: {
        // overflowY: 'hidden',
        display:'flex',
        // flexGrow: 1,
        minHeight: '100%',
        justifyContent:'center',
        alignItems: 'center',
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
        textAlign: 'center'
    },
    card:{ 
        width: '370px', 
        height: '417px',
    },
    cardContent:{
        width: '290px',
        height: '350px'
    },
}));

const Home = (props) => {

    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <div className={classes.home}>
            <DefaultView/>
        </div>
    )
}

export default Home;
