import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import DoubleArrowTwoToneIcon from '@material-ui/icons/DoubleArrowTwoTone';
import { IconButton, Typography, Paper} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root:{
      background: 'none',
      display: 'inline-block',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderStyle: 'solid',
      border: '1px',
      justifyContent: 'center'
    },
}));


function findValue(array=['Telos', 'EOS', 'BSC'], index) {
    var remainder = index % array.length;
    return array[remainder === 0 ? array.length - 1 : remainder - 1];
}

const ChangeChain = () => {
  const loginButton = useSelector(state => state.theme.loginButton);
  const classes = useStyles();
  const [chain, setChain] = React.useState('Telos');
  const [chainIdx, setChainIdx] = React.useState(0);

  const handleNextChain = (index) => {
    setChain(findValue(['Telos', 'EOS', 'BSC'], index));
    setChainIdx(chainIdx + 1);
  }

  return (
    <Paper className={classes.root} style={{borderColor: loginButton}}>
        <Typography>Current Chain: {chain} <IconButton onClick={()=> handleNextChain(chainIdx)} ><DoubleArrowTwoToneIcon /></IconButton> </Typography>
    </Paper>
  );
}

export default ChangeChain;
