import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import {
    Container,
    Card,
    Avatar,
    makeStyles,
    useTheme,
    CardContent,
    Typography,
    Box,
    IconButton,
    Paper,
    Button,
    CardActionArea,
    // createMuiTheme,
    // ThemeProvider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Grid,
    Divider,
    CircularProgress,
    ButtonBase,
    Hidden
 } from '@material-ui/core';

import mine_card_img from '../../../static/images/mine_card_img.png';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// import { blue } from '@material-ui/core/colors';
// import tether from '../../../static/images/tether.svg';

import RecentActivity from './RecentActivity';
import { withUAL } from 'ual-reactjs-renderer/dist/components/provider/withUAL';
import { Redirect, withRouter } from 'react-router-dom';
import ChangeConfigDialog from './ChangeConfigDialog';
import MessageDialog from '../../ui/dialog/MessageDialog';
import StakeList from './StakeList';

const useStyles = makeStyles((theme) => ({
    aucSettings:{
        padding: theme.spacing(10),
        paddingLeft: theme.spacing(14),
        paddingRight: theme.spacing(14),
        minHeight: '100vh',
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    card:{
        height: '416px',
        width: '350px'
    },
    avatar:{
        height: theme.spacing(5),
        width: theme.spacing(5)
    },
    customHoverFocus: {
        "&:hover, &.Mui-focusVisible": { backgroundColor: "rgba(0, 89, 247, 0.2)" }
    }

}))

const { JsonRpc } = require("eosjs");

const MiningSettings = (props) => {

    console.log('props in Mine Settings', props)
    const {location:{state}, ual:{activeUser}}  = props;
    const theme = useTheme();
    const classes = useStyles(theme);
    const cardBackground = useSelector(state => state.theme.cardBackground);
    // const type = useSelector(state => state.theme.type);
    const [open, setOpen] = useState(false);

    const [tokenTable, settokenTable] = useState([]);
    const [acceptedTokens, setacceptedTokens] = useState([]);
    const [recentActivity, setrecentActivity] = useState([]);
    const [stakeList, setstakeList] = useState([]);
    const [mineData, setmineData] = useState(undefined);


    const [messageDialog, setMessageDialog] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(0); //0 -> sucess, 1 -> failure, 2 -> Information
    const [loading, setLoading] = useState(true);

    const miningContract = useSelector(state => state.chain.miningContract);
    const {host, port, protocol} = useSelector(state => state.chain);
    const rpc = new JsonRpc(
        `${protocol}://${host}:${port}`
    );

    const handleDialogClose = () => {
        setOpen(false);
    }
    const handleDialogOpen = () => {
        setOpen(true);
    }

    // const cardButtonTheme = createMuiTheme({
    //     palette: {
    //         type: type,
    //         primary: blue,
    //     },
    // });

    useEffect(() => {
        async function fetchData(){
            if(activeUser && state.mined_token_symbol){
                const {mined_token_symbol} = state;
                var {actions} = await rpc.history_get_actions(miningContract, 0, 100);
                actions = actions.filter( action => action.action_trace.act.data.mined_token_symbol === mined_token_symbol)
                .map(({action_trace, block_num, block_time})=>({block_num, block_time, act_name: action_trace.act.name, account: action_trace.act.authorization[0].actor, trx_id: action_trace.trx_id}));

                var minesTable = await rpc.get_table_rows({
                    json: "true",
                    code: miningContract,
                    scope: miningContract,
                    table: "mines",
                    reverse: false
                });
                var mine = minesTable.rows.filter(mine => mine.mined_token_symbol === mined_token_symbol)[0]


                var tokenTable = await rpc.get_table_rows({
                    json: "true",
                    code: miningContract,
                    scope: miningContract,
                    table: "tokens",
                    reverse: false
                });
                tokenTable = tokenTable.rows;

                var acceptedTokens = await rpc.get_table_rows({
                    json: "true",
                    code: miningContract,
                    scope: miningContract,
                    table: "acceptedtkns",
                    reverse: false
                });
                acceptedTokens = acceptedTokens.rows.filter(token => token.mined_token_symbol === mined_token_symbol);
                acceptedTokens.forEach( each => {
                    const {token_icon_url} = tokenTable.filter(token => token.token === each.token_symbol)[0];
                    each.token_icon_url = token_icon_url;
                });

                var stakeTable = await rpc.get_table_rows({
                    json: "true",
                    code: miningContract,
                    scope: miningContract,
                    table: "stakes",
                    reverse: false
                });
                stakeTable = stakeTable.rows.filter(row => row.mined_token_symbol === mined_token_symbol);

                mine.mined_token_icon_url = tokenTable.filter(token => token.token === mine.mine_tkn)[0].token_icon_url;
                mine.mined_token_icon_url = tokenTable.filter(token => token.token === mine.mined_token_symbol)[0].token_icon_url;


                console.log("STAKE LIST", stakeTable);
                console.log("Action List", actions);
                setstakeList(stakeTable);
                settokenTable(tokenTable);
                setacceptedTokens(acceptedTokens);
                setrecentActivity(actions);
                setmineData(mine);
                setLoading(false);
            }

        };
        fetchData()
    }, [activeUser, state, messageDialog])

    if(!state || !state.mineData || !activeUser || (state.mineData.mine_owner !== activeUser.accountName)){
        return <Redirect to='/'/>
    } else {
        const {location:{state:{mineData}}} = props;
        if(mineData){
            var {burn_rte, freeze_lvl, mine_cycle, mine_end, mine_id, mine_tkn, mined_token_icon_url, mine_rte, stake_tkn, stake_token_icon_url, prcd_cntrct, mine_owner} = mineData;
        }

        return (
            <div className={classes.aucSettings}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Button startIcon={<ArrowBackIosIcon/>} onClick={()=> props.history.push({pathname: '/mine'})}>Back to Mines</Button>
                    </Grid>
                    <Grid container item xs={12} align='center' style={{background: cardBackground, backgroundColor: 'rgba(0,0,0,0.1)', justifyContent: 'center'}}>
                        <Grid item xs={12} >
                            <Box style={{display: 'flex', justifyContent: 'left', marginBottom: theme.spacing(1)}}>
                                <Typography variant='h5'><b>Mine Configurations</b></Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Card className={classes.card} style={{background: cardBackground}}>
                                <CardContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <CardActionArea >
                                        <Box style={{margin: theme.spacing(2), display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                            <Typography variant='h6'><b>Mine Token:</b></Typography>
                                        </Box>
                                        <Box style={{margin: theme.spacing(2), display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                                            {
                                                !mineData &&
                                                <CircularProgress/>
                                            }
                                            {
                                                mineData &&
                                                <>
                                                <Avatar src={mined_token_icon_url} className={classes.avatar}/>
                                                <Typography variant='h6'>{mine_tkn.split(",")[1]}</Typography>
                                                </>
                                            }

                                        </Box>

                                        <Divider/>

                                        <Box style={{margin: theme.spacing(2), display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                            <Typography variant='h6'><b>Stake Token:</b></Typography>
                                        </Box>
                                        <Box style={{margin: theme.spacing(2), display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                                            {
                                                !mineData &&
                                                <CircularProgress/>
                                            }
                                            {
                                                mineData &&
                                                <>
                                                <Avatar src={stake_token_icon_url} className={classes.avatar}/>
                                                <Typography variant='h6'>{stake_tkn.split(",")[1]}</Typography>
                                                </>
                                            }

                                        </Box>
                                    </CardActionArea>
                                    <Paper style={{display: 'flex', alignItems: 'center' ,background: cardBackground, border: '1px solid #000000', borderRadius: '10px', padding: theme.spacing(2), marginTop: theme.spacing(1)}}>
                                        <Box pr={3} >
                                            <Typography variant='h6'>Change Config</Typography>
                                        </Box>
                                        <IconButton className={classes.customHoverFocus} onClick={handleDialogOpen}><ArrowRightAltIcon style={{color: '#0059F7'}}/></IconButton>
                                    </Paper>
                                </CardContent>
                            </Card>

                        </Grid>

                        <Grid item xs={12} lg={4} align='center'>
                            <Box style={{marginTop: theme.spacing(4), marginLeft: theme.spacing(5)}}>
                                <ButtonBase>
                                    <img src={mine_card_img} alt='Mine!'/>
                                </ButtonBase>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <Card className={classes.card} style={{background: cardBackground, alignItems: 'center'}}>
                                <CardContent>
                                    {
                                        !mineData &&
                                        <CircularProgress/>
                                    }
                                    {
                                        mineData &&
                                        <>
                                        <Box p={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <Typography variant='body1'> Mine Owner:</Typography>
                                            <Typography variant='body1'> {mine_owner}</Typography>
                                        </Box>
                                        <Divider/>

                                        <Box p={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <Typography variant='body1'> Proceeds:</Typography>
                                            <Typography variant='body1'> {prcd_cntrct}</Typography>
                                        </Box>
                                        <Divider/>

                                        <Box p={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <Typography variant='body1'> Base Burn Rate:</Typography>
                                            <Typography variant='body1'> {burn_rte}</Typography>
                                        </Box>
                                        <Divider/>

                                        <Box p={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <Typography variant='body1'> Freeze Level:</Typography>
                                            <Typography variant='body1'> {freeze_lvl}</Typography>
                                        </Box>
                                        <Divider/>

                                        <Box p={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <Typography variant='body1'> Mine Cycle:</Typography>
                                            <Typography variant='body1'> {mine_cycle}</Typography>
                                        </Box>
                                        <Divider/>


                                        <Box p={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <Typography variant='body1'> Mine End:</Typography>
                                            <Typography variant='body1'> {mine_end}</Typography>
                                        </Box>
                                        <Divider/>
                                        </>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>



                        {/* <Grid item xs={12} md={4} align='center'>
                            <Card className={classes.card} style={{background: cardBackground}}>
                                <CardContent>
                                    <Box m={2} style={{display: 'flex', justifyContent: 'flex-start'}}>
                                        <Typography variant='h5'><b>Crypto Allow</b></Typography>
                                        <ThemeProvider theme={cardButtonTheme}>
                                            <Button variant='contained' color='primary'>Add</Button>
                                        </ThemeProvider>
                                    </Box>
                                    <Divider/>
                                    <Box style={{display: 'flex', justifyContent: 'space-between', padding: theme.spacing(1), paddingLeft: theme.spacing(5)}}>
                                        <Typography variant='h6'><b>Token</b></Typography>
                                        <Typography variant='h6'><b>Burn Rate</b></Typography>
                                        <ThemeProvider theme={cardButtonTheme}>
                                            <Button variant='contained' color='primary'>Add</Button>
                                        </ThemeProvider>
                                    </Box>
                                    {
                                        acceptedTokens.length === 0 &&
                                        <CircularProgress/>
                                    }

                                    <List style={{maxHeight: '340px', overflowY: 'auto', margin: theme.spacing(1)}}>
                                        {
                                            acceptedTokens.length > 0 &&
                                            // [...new Array(5)]
                                            acceptedTokens
                                            .map((token, index) =>
                                            <>
                                            <ListItem key={index} >
                                                <ListItemIcon>
                                                    <Avatar src={token.token_icon_url}/>
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                                                        <Typography>{token.token_symbol.slice(2)}</Typography>
                                                        <Typography color='textSecondary'>{token.burn_rate}</Typography>
                                                    </Box>
                                                </ListItemText>
                                            </ListItem>
                                            <Divider/>
                                            </>,
                                            )
                                        }
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid> */}


                    </Grid>

                    <Grid item xs={12} align='center'>
                        <Container style={{background: cardBackground, minWidth: '100%', padding: theme.spacing(4), borderRadius: theme.shape.borderRadius}}>
                            <Box style={{display: 'flex', justifyContent: 'left', marginBottom: theme.spacing(1)}}>
                                <Typography variant='h5'><b>Stake List</b></Typography>
                            </Box>
                            <Hidden mdUp>
                                <Box style={{display: 'flex', justifyContent: 'left', marginBottom: theme.spacing(1)}}>
                                    <Typography variant='caption' color='textSecondary'>Table is horizontally scrollable</Typography>
                                </Box>
                            </Hidden>
                            <StakeList stakeList={stakeList} loading={loading}/>
                        </Container>

                    </Grid>

                    <Grid item xs={12} align='center'>
                        <Container style={{background: cardBackground, minWidth: '100%', padding: theme.spacing(4), borderRadius: theme.shape.borderRadius}}>
                            <Box style={{display: 'flex', justifyContent: 'left', marginBottom: theme.spacing(1)}}>
                                <Typography variant='h5'><b>Recent Activity</b></Typography>
                            </Box>
                            <Hidden mdUp>
                                <Box style={{display: 'flex', justifyContent: 'left', marginBottom: theme.spacing(1)}}>
                                    <Typography variant='caption' color='textSecondary'>Table is horizontally scrollable</Typography>
                                </Box>
                            </Hidden>
                            <RecentActivity recentActivity={recentActivity} loading={loading}/>
                        </Container>

                    </Grid>


                </Grid>
                {
                    mineData &&
                    <ChangeConfigDialog open={open} onClose={handleDialogClose} mineData={mineData} activeUser={activeUser} rpc={rpc} setMessage={(message) => setMessage(message)} toggleMessageDialog={() => setMessageDialog(true)} setMessageType={(t) => setMessageType(t)}/>
                }

                <MessageDialog open={messageDialog} onClose={() => setMessageDialog(false)} message={message} messageType={messageType}/>
            </div>
        )
    }
}

export default withRouter(withUAL(MiningSettings));
